import React, { useState, useEffect } from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from '../libs/colors';
import Button from './Button';

interface Props {
    message: string | null;
    type?: "error" | "done" | 'primary';
    buttonText?: string;
    buttonClick?: () => void;
    disabled?: boolean;
}

const InfoBar = ({ message, type, buttonText,buttonClick, disabled }: Props) => {

    const [isAnimating, runAnimate] = useState(false);
    useEffect(() => {
        if (message) {
            runAnimate(true);
        }
        return (() => {
            runAnimate(false)
        })
    }, [message])

    if (isAnimating) {
        return (
            <Container style={{ background: type === "error" ? colors.danger : type === 'primary' ? colors.primary : colors.success }}>
                <Message>
                    {message}
                </Message>
        {buttonText && <Button onClick={buttonClick} disabled={disabled} style={{marginLeft: '1em'}} small color='danger'>{buttonText}</Button>}
            </Container>
        )
    }
    return null;
}

export default InfoBar;

const translate = keyframes`
  0% {
    transform: translate(0,100%);
  }
  100% {
    transform: translate(0,0);
  }
`;

const Container = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    z-index: 32;
    animation: ${translate} 0.5s ease-in;
    
`;

const Message = styled.span`
    color: white;
`;