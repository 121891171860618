import React, { useState } from 'react';
import { Title } from '../../components/Title';
import StudyBudgetItem from './StudyBudgetItem';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import AddStudyBudgetModal from './AddStudyBudgetModal';
import { StudyDetail_study_incomingBudget, UserRole } from '../../graphql/types';
import { colors } from '../../libs/colors';
import { ReactComponent as GreenOval } from '../../icons/green-oval.svg';
import { useMe } from '../../hooks';

interface Props {
    studyId?: string;
    data?: StudyDetail_study_incomingBudget[] | null;
}

const StudyBudget = ({ studyId, data }: Props) => {
    const { me } = useMe();
    const [openModal, setOpenModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const count = data?.length;
    const showMore = !!count ? count > 4 : false;
    return (
        <div>
            <div css={css`display: flex; align-items: center;`}>
                <GreenOval />
                <Title style={{marginBottom: 0, marginLeft: '1em'}}>Budget Incomes</Title>
            </div>

            {data?.slice(0, isOpen ? count : 4).map((ib, index) => <StudyBudgetItem key={`${ib.date}_${index}`} date={ib.date} name={`${ib.contact?.firstName} ${ib.contact?.lastName}`} price={ib.price} />)}
            {showMore && !!count
                ? <ShowMoreButton style={{ paddingLeft: isOpen ? 14 : 0 }} onClick={() => setIsOpen(!isOpen)} role="button">
                    {isOpen ? 'hide' : `+ ${count - 4} more`}
                </ShowMoreButton>
                : <div />
            }
            {me?.role !== UserRole.CLIENT_USER && <div css={css`display: flex; justify-content: flex-end; margin-top: 1em;`}>
                <AddItemButton onClick={() => setOpenModal(true)}>+ Add Income</AddItemButton>
            </div>}
            {openModal && <AddStudyBudgetModal studyId={studyId} openModal={openModal} setOpenModal={setOpenModal} />}
        </div>
    )
}

export default StudyBudget;

const AddItemButton = styled.button`
    border: 1px dotted #979797;
    border-radius: 14px;
    padding: 8px 16px;
    color: #0173E3;
    background: none;
`;

const ShowMoreButton = styled.div`
    color: ${colors.link};
    font-size: 14;
    border: none;
    padding: 0;
    background: none;
`;
