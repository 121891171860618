import React from 'react';
import { useParams } from '@reach/router';
import styled from '@emotion/styled'
import { useQuery } from '@apollo/react-hooks';
import { studiesQuery, centrumDataQuery } from '../../graphql/queries';
import { Studies as StudiesType, CentrumDataQuery } from '../../graphql/types';
import StudyItem from './StudyItem';
import TopBar from '../../components/TopBar';
import Header from '../../components/Header';

const Studies = () => {
    const { centrumId } = useParams();
    const { data } = useQuery<StudiesType>(studiesQuery, { fetchPolicy: 'cache-and-network', variables: { centrumId: parseInt(centrumId, 10) } });
    const { data: centrumData } = useQuery<CentrumDataQuery>(centrumDataQuery, {
        variables: {
            centrumId: parseInt(centrumId, 10),
        }
        , skip: !!!centrumId,
    });

    const navigationData = [];
    if(centrumId){
        navigationData.push({ text: `Site ${centrumData?.centrums[0].name}`, path: `/site/${centrumId}` });
    }

    return (
        <>
            <TopBar studiesList />
            <Header addStudy data={navigationData} hidePlusButton />
            <StudiesContainer>
                {data?.studies.map(study => <StudyItem id={study.id} key={`study-${study.id}`} code={study.code} />)}
            </StudiesContainer>
        </>
    )
}

export default Studies;

const StudiesContainer = styled.div`
max-width: 990px;
margin: 1em auto;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
margin-bottom: 2em;
background-color: white;
padding: 5em 0;
`;