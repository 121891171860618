import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps, useNavigate, useLocation, Link } from '@reach/router';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import SnackBar from '../../components/SnackBar';
import { login, logout } from '../../libs/auth';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import logo from '../../icons/logo.png';
import { colors } from '../../libs/colors';
import { css } from '@emotion/core';

interface Props extends RouteComponentProps {
    client: ApolloClient<NormalizedCacheObject>;
}

const Login = ({ client }: Props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [snackBarInfo, setSnackBarInfo] = useState<null | { type: "error" | "done", message: string }>(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        client.clearStore();
        logout()
    }, [client]);

    useEffect(() => {
        if ((location.state as any) && (location.state as any).isAfterVefiry) {
            setSnackBarInfo({
                message: 'Password was succesfully set. Now you can log in',
                type: 'done'
            })
        }
    }, [location.state])

    useEffect(() => {
        if ((location.state as any) && (location.state as any).isLoggedOut) {
            setSnackBarInfo({
                message: 'You have been logged out.',
                type: 'error'
            })
        }
    }, [location.state])

    const handleClick = async () => {
        if (username.length && password.length) {
            try {
                const res = await login({ username, password });
                if (res) {
                    // actualy state can be undefined but it's a bug - https://github.com/gatsbyjs/gatsby/issues/14815
                    if(location.state){
                        navigate((location.state as any).redirectUrl || "/")
                    } else {
                        navigate("/");
                    }
                } else {
                    setSnackBarInfo({
                        message: 'Invalid credentials',
                        type: 'error'
                    })
                }
            } catch (e) {
                setSnackBarInfo({
                    message: (e as Error).message,
                    type: 'error'
                })
            }
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleClick();
        }
    }
    const isDisabled = !(username.length && password.length)
    return (
        <LoginContainer>
            <Center>
                <img width={120} alt='clinpas logo' src={logo} />
                <div style={{ width: 300 }}>
                    <TextInput onChange={setUsername} value={username} label="User Name" style={{ marginTop: 32 }} />
                    <TextInput onChange={setPassword} value={password} label="Password" style={{ marginTop: 16 }} type="password" onKeyDown={handleKeyDown} />
                    <div css={css`margin-top: 8px; display: flex; justify-content: center;`}>
                        <CustomLink to='/reset_password'>Forgot Your Password</CustomLink>
                    </div>
                    <Button style={{ marginTop: 16, width: '100%' }} disabled={isDisabled} onClick={handleClick}>Login</Button>
                </div>
                {snackBarInfo && <SnackBar message={snackBarInfo.message} type={snackBarInfo.type} resetError={setSnackBarInfo} />}
            </Center>
        </LoginContainer>
    )
};

export default Login;

const Center = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;


const CustomLink = styled(Link)`
color: ${colors.primary};
text-decoration: none;
`;

const LoginContainer = styled.div`
width: 100%;
height: 100vh;
background-color: #fafafa;
`;