import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../libs/colors';
import { StudySubject_study_subjects_subjectVisits, CompensationStatus, SubjectStatus, EnterVisitDateVariables, EnterVisitDate } from '../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
import { enterVisitDate } from '../../graphql/mutations';
import { useParams } from '@reach/router';
import { ReactComponent as DoneSVG } from '../../icons/done.svg';
import { ReactComponent as PlannedSVG } from '../../icons/delayed.svg';
import { ReactComponent as DelaySVG } from '../../icons/planned.svg';
import CompensationItem from '../../components/CompensationItem';
import AddCompensation from '../../components/AddCompensation';
import TextInput from '../../components/TextInput';
import DownloadUpload from './DownloadUpload';
import moment from 'moment';
import SnackBar from '../../components/SnackBar';
import { css } from '@emotion/core';
import { dateTemplate, getFormatedDate } from '../../helpers';
import AuditLog from './AuditLog';
import { unresolvedSubjectVisitQuery } from '../../graphql/queries';

interface Props {
    data: StudySubject_study_subjects_subjectVisits;
    number: number;
    isLocked: boolean;
    isPostal: boolean;
    allowedExtenses?: boolean | null;
    lastVisit?: number;
    status?: SubjectStatus;
}

const inputRegex = /^\d{2}-\d{2}-\d{4}$/gm;

const SubjectVisit = ({ data, number, isLocked, allowedExtenses, lastVisit }: Props) => {
    const { name } = data.visit;
    const datePlaceholder = '+ Add Date';
    const inputRef = useRef<HTMLInputElement>(null);
    const { studyId, subjectId } = useParams()
    const [error, setError] = useState<{ message: string, type: "done" | "error" } | null>(null);
    const [input, setInputValue] = useState({
        value: data?.date != null ? getFormatedDate(data.date) : datePlaceholder,
        err: false,
        focused: false
    });

    useEffect(() => {
        if ((/^\d{2}$/.test(input.value) || /^\d{2}-\d{2}$/.test(input.value))) {
            setInputValue({
                value: input.value + '-',
                err: false,
                focused: true,
            });
        }
    }, [input.value])


    const handleChange = (value: string) => {
        if ((value.length <= 10 && (value.match(/^[0-9-]*$/) || value === ''))) {
            setInputValue({
                value: value,
                err: false,
                focused: true
            });
        }
        if (value.length === 10 && !moment(value, dateTemplate, true).isValid()) {
            setInputValue({
                value: value,
                err: true,
                focused: true
            });
        }
        if ((/^\d{2}-\d{2}$/.test(value) && /^\d{2}-\d{2}-$/.test(input.value)) || (/^\d{2}$/.test(value) && /^\d{2}-$/.test(input.value))) {
            setInputValue({
                value: value.slice(0, -1),
                err: false,
                focused: true
            });
        }
    }

    const [enterVisit] = useMutation<EnterVisitDate, EnterVisitDateVariables>(enterVisitDate);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setInputValue(prevState => ({
            ...prevState,
            value: input.value.match(inputRegex) ? input.value : "",
            focused: true
        }))
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setOnBlur();
        if (!!!input.value && data.date != null) {
            setInputValue({
                value: getFormatedDate(data.date),
                err: false,
                focused: false
            });
        }
         else if (!!!input.value && data.date == null) {
            setInputValue({
                value: "+ Add Date",
                err: false,
                focused: false
            });
        } else if (moment(input.value, dateTemplate, true).isValid()) {
            e.preventDefault();
            e.stopPropagation();
            enterVisit({
                variables: {
                    date: moment.utc(input.value, dateTemplate).toISOString(),
                    subjectVisitId: data?.id
                },
                refetchQueries: [{
                    query: unresolvedSubjectVisitQuery,
                    variables: {
                        subjectId: Number(subjectId)
                    }
                }]
            });
        } else {
            setInputValue(prevState => ({
                ...prevState,
                err: true,
                focused: false
            }))
        }
    }

    const isPayed = data?.payment?.status === "paid";
    const isApproved = data?.payment?.status === "approved";
    const locked = isPayed || isLocked || isApproved;
    const disabled = data?.date == null || locked;
    const delayedCompensation = data.subjectVisitCompensations.find(c => c.status === "DELAYED");
    const ableToAddPostalOrderFormCompensations = data.subjectVisitCompensations.filter(c => c.status !== CompensationStatus.NOT_DONE);
    const handleClick = () => {
        if (disabled && !input.value.match(inputRegex)) {
            setInputValue({
                value: "",
                err: true,
                focused: true
            });
            inputRef && inputRef.current && inputRef.current.focus();
        }
    }
    const setOnBlur = () => {
        inputRef && inputRef.current && inputRef.current.blur();
        setInputValue({ err: input.err, focused: false, value: input.value });
    };
    return (
        <>
            <SubjectVisitBox onClick={(locked && lastVisit && number > lastVisit ? () => setError({ message: `You have to finish  visit number ${lastVisit} before editing next visit (there is a compensation without set STATUS)`, type: 'error' }) : (input.focused) ? undefined : handleClick)}>
                {isPayed && <DoneIcon />}
                {delayedCompensation != null && <DelayIcon />}
                {data.date == null && <PlannedIcon />}
                <InfoBox>
                    <VisitOrderNumberBox>
                        <Label>Visit</Label>
                        <VisitNumber>
                            {number}
                        </VisitNumber>
                    </VisitOrderNumberBox>
                    <Column>
                        <Name>
                            {name == null ? `Visit ${data.visit.orderNumber || ''}` : name}
                        </Name>
                        <TextInput
                            err={input.err}
                            disabled={locked}
                            placeholder={dateTemplate}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            onChange={handleChange}
                            inputStyle={{ border: 'none' }}
                            value={input.value}
                            ref={inputRef}
                            label="Date"
                            style={{width: '125px'}}
                        />
                        {input.err && !!input.value && <ErrorText>Invalid date</ErrorText>}
                    </Column>
                </InfoBox>
                <BottomBox>
                    <Label>Items</Label>
                    <ScrollBox>
                        {data.subjectVisitCompensations.map(c => c.compensation && <CompensationItem name={c.compensation.name} compensationId={c.compensation.id} price={c.price} status={c.status} subjectVisitCompensationId={c.id} visitCompensationId={c.id} key={`compensationItem-${c.id}`} disabled={disabled} isExpense={!!c.compensation.isExpense} subjectVisitId={data.id}/>)}
                    </ScrollBox>
                    <div onClick={!!!input.value || input.value === datePlaceholder ? () => setError({ message: 'To be able to edit this visit you have to enter VISIT date and click on clinpas button', type: 'error' }) : undefined}>
                        <AddCompensation visitId={data.visit.id} disabled={disabled} subjectVisitId={data.id} studyId={studyId} allowExpenses={data?.visit?.flowChart?.allowExpenses || allowedExtenses} activeCompensationsCount={data.subjectVisitCompensations.length} />
                    </div>
                    {data.payment && data.payment.isPostalOrder &&
                        <DownloadUpload
                            isAbleToAddPostalOrderForm={ableToAddPostalOrderFormCompensations.length > 0}
                            paymentId={data.payment.id}
                            subjectVisitId={data.id}
                            hasPostalForm={data.payment.hasPostalForm}
                            hasCompensations={data.subjectVisitCompensations.length > 0}
                            hasRemovedForm={isPayed}
                            disabled={disabled}
                        />
                    }
                </BottomBox>
                <Version>
                    <div style={{display: 'relative'}}>
                    <AuditLog subjectVisitId={data.id} />
                    {data?.visit?.flowChart?.version && ` version ${data?.visit?.flowChart?.version}(${getFormatedDate(data?.visit?.flowChart?.versionDate)})`}
                    </div>
                </Version>
                {input.focused && <div onClick={setOnBlur} css={css`position: absolute; top: 0; left: 0; width: 100%; height: 100%;`} />}
            </SubjectVisitBox>
            {error && <SnackBar message={error.message} type={error.type} resetError={setError} />}
        </>
    );
}

export default SubjectVisit;

const ScrollBox = styled.div`
    max-height: 160px;
    overflow: auto;
`;

const Version = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 11px;
    color: ${colors.darkGray};
`;

const SubjectVisitBox = styled.div`
border-radius: 2px;
min-width: 310px;
max-width: 310px;
min-height: 364px;
padding: 1em 0;
background-color: ${colors.subjectVisit};
position: relative;
display: inline-block;
margin-left: 8px; 
margin-right: 8px;
`;

const Column = styled.div`
    margin-left: 16px;
    position: relative;
`

const VisitOrderNumberBox = styled.div`
background: #FAFAFA;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 1em 1.5em;
border-radius: 2px;
`;


const BottomBox = styled.div`
margin-top: 1em;
padding: 0 1.5em;
`;

const VisitNumber = styled.span`
font-weight: 500;
font-size: 26px;
color: black;
margin-top: 4px;
`;

const Label = styled.span`
color: ${colors.gray};
font-size: 14px;
line-height: 16px;
`;

const InfoBox = styled.div`
display: flex;
`;

const DoneIcon = styled(DoneSVG)`
    position: absolute;
    right: -10px;
    top: -10px;
`;
const DelayIcon = styled(DelaySVG)`
    position: absolute;
    right: -10px;
    top: -10px;
`;
const PlannedIcon = styled(PlannedSVG)`
    position: absolute;
    right: -10px;
    top: -10px;
`;

const Name = styled.div`
    margin-bottom: 8px;
`;

const ErrorText = styled.p`
    color: ${colors.danger};
    position: absolute;
    top: 70px;
    left: 10px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
`;

