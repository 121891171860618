import {
    flexWrap,
    flexDirection,
    alignItems,
    display,
    justifyContent,
    DisplayProps,
    FlexWrapProps,
    FlexDirectionProps,
    AlignItemsProps,
    JustifyContentProps,
    typography,
    TypographyProps,
    space,
    width,
    flex,
    FlexProps,
    maxHeight,
    MaxHeightProps,
    WidthProps,
    SpaceProps
  } from 'styled-system';
  import styled from '@emotion/styled';
  
  export type CustomFlexProps =
    FlexWrapProps &
    FlexDirectionProps &
    AlignItemsProps &
    DisplayProps &
    MaxHeightProps &
    SpaceProps &
    TypographyProps &
    FlexProps &
    WidthProps &
    JustifyContentProps;
  
  /**
   * Fully customizable flex container
   */
  const Flex = styled('div')<CustomFlexProps>`
    display: flex;
    ${display}
    ${flexWrap}
    ${maxHeight}
    ${flexDirection}
    ${width}
    ${alignItems}
    ${flex}
    ${justifyContent}
    ${space}
    ${typography}
  `;
  
  Flex.displayName = 'Flex';
  
  export default Flex;
  