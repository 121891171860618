import React, { useState } from 'react';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import styled from '@emotion/styled';
import TextInput from '../../components/TextInput';
import { useMutation } from '@apollo/react-hooks';
import { updateExpensePriceMutation } from '../../graphql/mutations';
import { updateExpensePriceVariables, updateExpensePrice } from '../../graphql/types';

interface Props {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    price: number;
    name: string;
    subjectVisitCompensationId: number;
    subjectVisitId: number;
    compensationId: number;
}

const UpdatePriceModal = ({ isOpen, setIsOpen, name, price, subjectVisitCompensationId, subjectVisitId, compensationId }: Props) => {
    const [inputValue, setInputValue] = useState(price.toString());
    const [updatePrice] = useMutation<updateExpensePrice, updateExpensePriceVariables>(updateExpensePriceMutation)

    const handleUpdatePrice = () => {
        updatePrice({
            variables: {
                input: {
                    price: Number(inputValue),
                    subjectVisitCompensationId,
                    compensationId,
                    subjectVisitId
                }
            }
        })
        setIsOpen(false);
    }

    return(
        <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Update expension price"
    >
        <Row style={{ marginBottom: 32}}>
            <Name>{name}</Name>
            <TextInput label="Price" value={inputValue} onChange={setInputValue} style={{ marginLeft: 32 }} />
        </Row>
        <Row>
            <Button onClick={() => setIsOpen(false)} color='none'>
                Cancel
            </Button>
             <Button onClick={handleUpdatePrice}>
                Save
            </Button>
        </Row>
    </Modal>
    )
};

export default UpdatePriceModal;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Name = styled.span`
    font-size: 14px;
`;