import React, { useState } from 'react';
import { StudyDetail_study_flowCharts, StudyDetail_study_centrums } from '../../graphql/types';
import FlowChartItem from './FlowChartItem';
import styled from '@emotion/styled';
import AddFlowchartModal from './AddFlowchartModal';

interface Props {
    flowCharts: StudyDetail_study_flowCharts[];
    centrums: StudyDetail_study_centrums[];
    protocolNumber: string;
}

const FlowCharts = ({ flowCharts, centrums, protocolNumber }: Props) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const lastVersion = flowCharts[0] ? flowCharts[0].version : 0;
    return (
        <div>
                {flowCharts.map(flowChart => (
                    <FlowChartItem flowChart={flowChart} centrums={centrums} protocolNumber={protocolNumber} key={flowChart.id}/>
                ))}
            <Row>
                <AddFlowchartButton onClick={() => setModalOpen(true)}>
                    + Add flowchart
               </AddFlowchartButton>
            </Row>
            <AddFlowchartModal isOpen={isModalOpen} onIsOpenChange={setModalOpen} lastVersion={lastVersion} />
        </div>
    );
};

export default FlowCharts;

const Row = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
`;

const AddFlowchartButton = styled.button`
    border: 1px dotted #979797;
    border-radius: 14px;
    padding: 8px 16px;
    color: #0173E3;
    background: none;
`;
