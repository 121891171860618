import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../libs/colors';
import { StudyDetail_study_flowCharts_visits, addVisitVariables, removeVisitVariables, changeVisitNameVariables, changeVisitTypeVariables, addVisit, EditStudyFlowChart } from '../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
import { addVisitMutation, removeVisitMutation, changeVisitNameMutation, changeVisitTypeMutation } from '../../graphql/mutations';
import { useParams } from '@reach/router';
import CompensationItem from '../../components/CompensationItem';
import AddCompensation from '../../components/AddCompensation';
import SwitchButton from '../../components/SwitchButton';
import { studyDetailQuery, editStudyFlowChartFragment } from '../../graphql/queries';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import SnackBar from '../../components/SnackBar';

interface Props {
    visit?: StudyDetail_study_flowCharts_visits;
    isAddVisit?: boolean;
    flowChartId?: number;
    isLocked?: boolean;
}


const Visit = ({ visit, isAddVisit, flowChartId, isLocked }: Props) => {
    const { studyId } = useParams()
    const [inputValue, setInputValue] = useState((visit && visit.name) || `Visit ${visit?.orderNumber}`);

    const [addFlowChartVisit] = useMutation<addVisit, addVisitVariables>(addVisitMutation);
    const [changeType, { error }] = useMutation<any, changeVisitTypeVariables>(changeVisitTypeMutation);
    const [removeVisit] = useMutation<any, removeVisitVariables>(removeVisitMutation);
    const [changeName] = useMutation<any, changeVisitNameVariables>(changeVisitNameMutation);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.currentTarget.value);
    }

    useEffect(() => {
        if (visit && visit.name) {
            setInputValue(visit.name);
        }
    }, [visit])

    const handleAddVisit = () => {
        if (flowChartId) {
            addFlowChartVisit({
                variables: {
                    input: {
                        studyId: parseInt(studyId, 10),
                        flowChartId
                    }
                },
                update(cache, result) {
                    const newItem = result.data?.addVisit;
                    const editStudyFlowchartCache = cache.readFragment<EditStudyFlowChart>({ 
                        fragment: editStudyFlowChartFragment, 
                        id: `FlowChart:${flowChartId.toString()}`,
                        fragmentName: 'EditStudyFlowChart',
                    });   
                  
                    if(editStudyFlowchartCache && newItem){
                        cache.writeFragment<EditStudyFlowChart>({
                            fragment: editStudyFlowChartFragment,
                            fragmentName: 'EditStudyFlowChart',
                            id: `FlowChart:${flowChartId.toString()}`,
                            data: {
                                ...editStudyFlowchartCache,
                                visits: [
                                    ...editStudyFlowchartCache.visits,
                                    {
                                        ...newItem
                                    }
                                ]
                            }
                        })
                    }
                }
            })
        }
    }

    const handleRemove = () => {
        if (visit) {
            removeVisit({
                variables: {
                    visitId: visit.id
                },
                refetchQueries: [{ query: studyDetailQuery, variables: { studyId: parseInt(studyId, 10) } }],
            })
        }
    }

    const handleBlur = () => {
        if (visit) {
            changeName({
                variables: {
                    name: inputValue,
                    visitId: visit.id
                },
                refetchQueries: [{ query: studyDetailQuery, variables: { studyId: parseInt(studyId, 10) } }],
            })
        }
    }

    const handleChangeType = async (value: any, active: boolean) => {
        if (visit && active) {
            try {
                await changeType({
                    variables: {
                        visitId: visit.id,
                        type: value
                    },
                    refetchQueries: [{ query: studyDetailQuery, variables: { studyId: parseInt(studyId, 10) } }],
                })
            } catch (e) {

            }
        }
    }

    const availableCompensations = visit?.visitCompensations.filter(c => c.compensation?.deletedAt == null) || []

    return (
        <Root>
            <Container>
                <SubjectVisitBox>
                    {!isAddVisit && !isLocked && <RemoveButton onClick={handleRemove}>
                        <DeleteIcon />
                    </RemoveButton>}
                    {visit &&
                        <>
                            <InfoBox>
                                <VisitOrderNumberBox>
                                    <Label>Visit</Label>
                                    <VisitNumber>
                                        {visit.orderNumber}
                                    </VisitNumber>
                                </VisitOrderNumberBox>
                                <Column>
                                    <NameInput value={inputValue} onChange={handleChange} onBlur={handleBlur} />
                                </Column>
                            </InfoBox>
                            <SpaceBetween>
                                <CompensationsBox>
                                    <Label>Compensations</Label>
                                    <ScrollView>
                                        {availableCompensations.map(c => c.compensation && <CompensationItem editable compensationId={c.compensation.id} name={c.compensation?.name} visitId={visit.id} visitCompensationId={c.id} key={`compensationItem-${visit.id}-${c.id}`} />)}
                                    </ScrollView>
                                    <AddCompensation visitId={visit.id} studyId={studyId} editable={true} />
                                </CompensationsBox>
                                <Row>
                                    <SwitchButton label="Screening" type='gray' value="screening" size="small" isActive={visit.type === "screening"} onChange={handleChangeType} />
                                    <SwitchButton label="Normal" value="normal" type='gray' size="small" isActive={visit.type === "normal"} onChange={handleChangeType} />
                                    <SwitchButton label="End of study" type='gray' value="endOfStudy" size="small" isActive={visit.type === "endOfStudy"} onChange={handleChangeType} />
                                </Row>
                            </SpaceBetween>
                        </>
                    }
                    {isAddVisit &&
                        <Center role="button" onClick={handleAddVisit}>
                            +
                    </Center>
                    }
                </SubjectVisitBox>
            </Container>
            {error != null && <SnackBar message={error?.message.split(":")[1]} />}
        </Root>
    );
}

export default Visit;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const ScrollView = styled.div`
    height: 92px;
    overflow: auto;
`;

const SpaceBetween = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
`;

const RemoveButton = styled.button`
    padding: 4px;
    border: none;
    background: none;
    outline: none;
    position: absolute;
    right: 8px;
    top: 8px;
`;

const Center = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 100px;
    color: ${colors.compensationItem};
`

const Container = styled.div`
    padding: 8px;
`;

const Root = styled.div`
width: 25%;
`;

const SubjectVisitBox = styled.div`  
border-radius: 2px;
min-height: 300px;
width: 100%;
padding: 16px 0;
background-color: ${colors.subjectVisit};
display: flex;
flex-direction: column;
position: relative;
`;

const Column = styled.div`
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const VisitOrderNumberBox = styled.div`
background: #FAFAFA;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 1em 1.5em;
border-radius: 2px;
`;

const CompensationsBox = styled.div`
margin-top: 1em;
padding: 0 8px 0 16px;
`;

const VisitNumber = styled.span`
font-weight: 500;
font-size: 26px;
color: black;
margin-top: 4px;
`;

const Label = styled.span`
color: ${colors.gray};
font-size: 14px;
line-height: 16px;
`;

const InfoBox = styled.div`
display: flex;
`;


const NameInput = styled.input`
    margin-bottom: 8px;
    border: none;
    background: white;
    padding: 8px;
`;
