import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../libs/colors';

interface Props {
    clientName?: string;
    code?: string;
    eudraCtNumber?: string;
    sponsor?: string | null;
    protocolNumber?: string;
}

const StudyHeader = ({ clientName, code, eudraCtNumber, sponsor,protocolNumber }: Props) => {
    return (
        <StudyHeaderContainer>
            <HeaderBox>
                <Label>
                    Protocol Number
                </Label>
                <Text>
                    {protocolNumber}
                </Text>
            </HeaderBox>

            <HeaderBox>
                <Label>
                    Short Name/Study Code
                </Label>
                <Text>
                    {code}
                </Text>
            </HeaderBox>

            <HeaderBox>
                <Label>
                    Sponsor
                </Label>
                <Text>
                    {sponsor}
                </Text>
            </HeaderBox>

            <HeaderBox>
                <Label>
                    Client
                </Label>
                <Text>
                    {clientName}
                </Text>
            </HeaderBox>

            <HeaderBox>
                <Label>
                    Eudra CT Number
                </Label>
                <Text>
                    {eudraCtNumber}
                </Text>
            </HeaderBox>
        </StudyHeaderContainer>
    )
}

export default StudyHeader;

const StudyHeaderContainer = styled.div`
width: 100%;
display: flex;
background-color: #fff;
border-radius: 2px;
margin-bottom: 16px;
margin-top: 16px;
`;

const HeaderBox = styled.div`
padding: 2em 1.6em;
display: flex;
    flex-direction: column;
`;

const Label = styled.span`
color: ${colors.gray};
font-size: 14px;
`;

const Text = styled.span`
font-weight: 500;
font-size: 18px;
`;
