import gql from "graphql-tag";
import { studySubjectVisitFragment, editStudyVisitFragment } from "./queries";


export const changeCompensationStatus = gql`
    mutation ChangeCompensationStatus($input: ChangeCompensationStatusInput!) {
        changeCompensationStatus(input: $input)  {
            id
            status
        }
    }
`;

export const changeSubjectStatus = gql`
    mutation ChangeSubjectStatus($subjectId: Int!, $studyId: Int!, $status: SubjectStatus!) {
        changeSubjectStatus(subjectId: $subjectId, studyId: $studyId, status: $status){
            id
            status
        }
    }
`;


export const enterVisitDate = gql`
    mutation EnterVisitDate($subjectVisitId: Int!, $date: String!) {
        enterVisitDate(subjectVisitId: $subjectVisitId, date: $date)  {
            subjectVisit {
                id
                date
            }
            subject {
                id
                status
            }
        }
    }
`;

export const addSubjectVisitCompensationMutation = gql`
    mutation AddSubjectvisitCompensation($visitId: Int!, $compensationId: Int!, $studyId: Int!, $subjectVisitId: Int!, $price: Int) {
        addSubjectVisitCompensation(visitId: $visitId, compensationId: $compensationId, studyId: $studyId,subjectVisitId: $subjectVisitId, price: $price) {
            id
            price
            status
        }
    }
`;

export const finishCycle = gql`
    mutation FinishCycle($subjectId: Int!, $studyId: Int!) {
        finishCycle(subjectId: $subjectId, studyId: $studyId)
    }
`;

export const repeatCycle = gql`
    mutation RepeatCycle($subjectId: Int!, $studyId: Int!) {
        repeatCycle(subjectId: $subjectId, studyId: $studyId)
    }
`;

export const assignFlowChartToCentrum = gql`
    mutation AssignFlowChartToCentrum($flowChartId: Int!, $studyId: Int!, $centrumId: Int!) {
        assignFlowChartToCentrum(flowChartId: $flowChartId, studyId: $studyId, centrumId: $centrumId)
    }
`;

export const addVisitCompensationMutation = gql`
    mutation AddVisitCompensation($visitId: Int!, $studyId: Int!, $compensationId: Int!) {
        addVisitCompensation(visitId: $visitId, studyId: $studyId, compensationId: $compensationId) {
            id
        }
    }
`;

export const removeVisitCompensationMutation = gql`
    mutation RemoveVisitCompensation($visitId: Int!, $studyId: Int!, $visitCompensationId: Int!) {
        removeVisitCompensation(visitId: $visitId, studyId: $studyId, visitCompensationId: $visitCompensationId)
    }
`;

export const addVisitMutation = gql`
    mutation addVisit($input: AddVisitInput!) {
        addVisit(input: $input) {
            ...EditStudyVisit
        }
    }
    ${editStudyVisitFragment}
`;

export const addSubjectVisitMutation = gql`
    mutation AddSubjectVisit($input: AddVisitInput!) {
        addSubjectVisit(input: $input) {
            ...StudySubjectVisit
        }
    }
    ${studySubjectVisitFragment}
`;

export const editSubjectMutation = gql`
mutation EditSubjectMutation($input: EditSubjectInput!) {
    editSubject(input: $input) {
        id
        bankAccount
    }
}
`;

export const removeVisitMutation = gql`
    mutation removeVisit($visitId: Int!) {
        removeVisit(visitId: $visitId)
    }
`;

export const changeVisitNameMutation = gql`
    mutation changeVisitName($visitId: Int!, $name: String!) {
        changeVisitName(visitId: $visitId, name: $name)
    }
`;

export const updateFlowChartCycleMutation = gql`
    mutation updateCycle($startsAt: Int!, $endsAt: Int!, $flowChartId: Int!) {
        updateCycle(startsAt: $startsAt,endsAt: $endsAt,flowChartId: $flowChartId)
    }
`;

export const removeFlowChartCycleMutation = gql`
    mutation removeCycle($flowChartId: Int!) {
        removeCycle(flowChartId: $flowChartId)
    }
`;

export const changeVisitTypeMutation = gql`
    mutation changeVisitType($visitId: Int!, $type: VisitType!) {
        changeVisitType(visitId: $visitId, type: $type)
    }
`;

export const addFlowChartMutation = gql`
    mutation addFlowChart($studyId: Int!, $version: Int!, $versionDate: String!) {
        addFlowChart(studyId: $studyId, version: $version, versionDate: $versionDate)
    }
`;

export const addSubjectMutation = gql`
    mutation addSubject($subjectInput: SubjectInput!) {
        addSubject(subjectInput: $subjectInput)
    }
`;

export const addCentrumToStudyMutation = gql`
    mutation addCentrumToStudy($studyId: Int!, $centrumId: Int!, $centrumCode: String!, $flowChartId: Int!) {
        addCentrumToStudy(studyId: $studyId, centrumId: $centrumId, centrumCode: $centrumCode, flowChartId: $flowChartId)
    }
`;

export const addCompensationMutation = gql`
    mutation addCompensation($studyId: Int!, $name: String!, $price: Int, $isExpense: Boolean!){
        addCompensation(studyId: $studyId, name: $name, price: $price, isExpense: $isExpense){
            id
            name
            price
            currency
            isExpense
            deletedAt
        }
    }
`;

export const setStudyContactMutation = gql`
mutation SetStudyContact($input: SetStudyContactInput!) {
    setStudyContact(input: $input) {
        id
        firstName
        lastName
        email
        phone
        clientId
    }
}`;
export const removeStudyContactMutation = gql`
mutation RemoveStudyContact($contactId: Int!, $studyId: Int!) {
    removeStudyContact(studyId: $studyId, contactId: $contactId)
}`;

export const updateCenterMutation = gql`
    mutation updateCenter($centerInput: CenterInput!) {
        updateCenter(centerInput: $centerInput)
    }
`;

export const updateClientMutation = gql`
    mutation updateClient($clientInput: ClientInput!) {
        updateClient(clientInput: $clientInput)
    }
`;

export const addStudyMutation = gql`
mutation addStudy($input: StudyInput!) {
    addStudy(input: $input) {
        id
    }
}`;

export const uploadPostalFileMutation = gql`
    mutation uploadPostalFile($input: PostalFileInput!) {
        uploadPostalFile(input: $input) {
            id
            hasPostalForm
        }
    }
`;

export const updatePaymentMutation = gql`
    mutation updatePayment($paymentId: Int!, $studyId: Int!) {
        updatePayment(paymentId: $paymentId, studyId: $studyId) {
            id
            status
        }
    }
`;

export const addUserToStudyMutation = gql`
    mutation addUserToStudy($userId: Int!, $centrumId: Int!, $studyId: Int!, $studyRole: StudyRole!) {
        addUserToStudy(userId: $userId, centrumId: $centrumId, studyId: $studyId, studyRole: $studyRole)
    }
`;

export const sendResetPasswordEmailMutation = gql`
mutation SendResetPasswordEmail($email: String!) {
    sendResetPasswordEmail(email: $email)
}
`;

export const addMoneyToBudget = gql`
mutation AddMoneyToBudget($input: AddMoneyToBudgetInput!) {
    addMoneyToBudget(input: $input) {
        id
        actualBudget
        incomingBudget {
            price
            id
            date
            contact {
                id
                firstName
                lastName
            }
        }
    }
}
`;

export const enableExpensesMutation = gql`
    mutation enableExpenses($flowChartId: Int!, $isEnable: Boolean!) {
        enableExpenses(flowChartId: $flowChartId, isEnable: $isEnable) {
            id
            allowExpenses
        }
    }
`;

export const loginMutation = gql`
    mutation login($nickname: String!, $password: String!) {
        login(nickname: $nickname, password: $password)
    }
`;

export const vefiryUserMutation = gql`
    mutation verifyUser($userId: Int!, $password: String!) {
        verifyUser(userId: $userId, password: $password)
    }
`;

export const updateUserMutation = gql`
    mutation updateUser($input: UserInput!) {
        updateUser(input: $input) {
            id
            username
            contact {
                id
                firstName
                lastName
                email
                phone
            }
        }
    }
`;

export const updateExpensePriceMutation = gql`
    mutation updateExpensePrice($input: UpdateExpensePriceInput!) {
        updateExpensePrice(input: $input) {
            id
            price
        }
    }
`;


export const synchronizePaymentsMutation = gql`
    mutation synchronizePayments($studyId: Int!) {
        synchronizePayments(studyId: $studyId) {
            id
        }
    }
`;

export const makePaymentsMutation = gql`
    mutation makePayments($studyId: Int!) {
        makePayments(studyId: $studyId) {
            payments {
                id
            }
            transactionId
        }
    }
`;

export const archiveStudyMutation = gql`
    mutation ArchiveStudyMutation($studyId: Int!){
        archiveStudy(studyId: $studyId)
    }
`;

export const removeUserFromStudyMutation = gql`
    mutation RemoveUserFromStudy($studyId: Int!, $userId: Int!){
        removeUserFromStudy(studyId: $studyId, userId: $userId)
    }
`;

export const updateStudyCompensationStateMutation = gql`
    mutation UpdateStudyCompensationState($compensationId: Int!, $isActive: Boolean!){
        updateStudyCompensationState(compensationId: $compensationId, isActive: $isActive) {
            id
            deletedAt
        }
    }
`;

export const updateCompensationNameMutation = gql`
    mutation UpdateCompensationName($compensationId: Int!, $name: String!){
        updateCompensationName(compensationId: $compensationId, name: $name){
            id
            name
        }
    }
`;

export const updatePaymentStatusMutation = gql`
    mutation UpdatePaymentStatus($paymentId: Int!, $status: PaymentStatus, $studyId: Int!){
        updatePaymentStatus(paymentId: $paymentId, status: $status, studyId: $studyId){
            id
            status
        }
    }
`;

export const removeFlowChartMutation = gql`
    mutation RemoveFlowChart($flowChartId: Int!){
        removeFlowChart(flowChartId: $flowChartId){
            id
        }
    }
`;

export const removeSubjectMutation = gql`
    mutation RemoveSubject($subjectId: Int!){
        removeSubject(subjectId: $subjectId)
    }
`;