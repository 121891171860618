import styled from '@emotion/styled';

const Title = styled.div`
    color: #979797;
    font-size: 14px;
    margin-bottom: 16px;
`;

const SubTitle = styled.div`
    color: #363636;
    font-size: 10px;
    margin-bottom: 16px;
`;


export { Title, SubTitle };