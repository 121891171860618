import React, { useState } from 'react';
import { StudyDetail_study_flowCharts, StudyDetail_study_centrums } from '../../graphql/types';
import EditableList from '../../components/EditableList';
import AddCentrumModal from './AddCenterModal';

interface Props {
    flowCharts: StudyDetail_study_flowCharts[];
    centers: StudyDetail_study_centrums[];
}

const CentrumPick = ({ flowCharts, centers }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const list = centers.map(c => ({ label: c.name, subLabel: c.code, value: c.id }))
    return (
        <>
           {centers && <EditableList title="Sites" onAddClick={() => setIsModalOpen(true)} list={list} itemStyle={{ marginBottom: 8 }} addButtonText="Add Site"/>}
           <AddCentrumModal flowCharts={flowCharts} isOpen={isModalOpen} onIsOpenChange={setIsModalOpen} studyCenters={centers}/>
        </>
    )
}

export default CentrumPick;