import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../libs/colors';
import { css } from '@emotion/core';
import { Link, useNavigate } from '@reach/router';

export interface NavigationTextType {
    text: string | null;
    path?: string;
    nextIsHiden?: boolean;
}

interface Props {
    data: (NavigationTextType | null)[];
    warning?: boolean;
}



const Navigation = ({ data, warning }: Props) => {

    const warningHandler = (link: string) => {
        const result = window.confirm('Leave site? Changes that you made may not be saved.');
        if (!!result) {
            navigate(link, { replace: true })
        }
    }

    const linkHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, path?: string) => {
        if (!!!path) {
            e.stopPropagation();
        }
        else {
            if (warning) {
                warningHandler(path);
            }
        }
    }
    const navigate = useNavigate();
    return (
        <NavigationBox>
            <p css={css`margin: 0.2em 0; font-family: 'Montserrat', sans-serif;`}>
                <React.Fragment>
                    <NavigationText onClick={(e) => linkHandler(e, '/')} to={'/'} color='gold'>Home</NavigationText>
                </React.Fragment>
                {data.map((navigationText, index) =>
                    <React.Fragment key={`${index}_'navigation`}>
                        {' / ' }
                        {!!navigationText && <NavigationText onClick={(e) => linkHandler(e, navigationText.path)} to={navigationText?.path ?? ''} color={index === data.length - 1 || navigationText.nextIsHiden ? 'gray' : 'gold'} disabled={!!!navigationText?.path} >{navigationText.text}</NavigationText>}
                    </React.Fragment>
                )}
            </p >
        </NavigationBox >
    )
}

export default Navigation;

const NavigationBox = styled.div`
display: flex;
font-size: 20px;
font-weight: 600;
color: ${colors.gray};
`;


const NavigationText = styled(Link) <{ disabled?: boolean, color: 'gold' | 'gray' }>`
font-family: 'Montserrat', sans-serif;
cursor: ${({ disabled }) =>
        disabled ? 'default' : 'pointer'
    };
text-decoration: none;
color: ${ ({ color }) => color === 'gold' ? colors.primary : colors.gray};
text-transform: uppercase;
`;