import React from 'react';
import Modal from '../../components/Modal';
import styled from '@emotion/styled';
import Button from '../../components/Button';
import { useQuery } from '@apollo/react-hooks';
import { SubjectVisitLogsQuery, SubjectVisitLogsQueryVariables } from '../../graphql/types';
import { subjectVisitLogsQuery } from '../../graphql/queries';
import Flex from '../../components/Flex';
import moment from 'moment';
import { colors } from '../../libs/colors';

interface Props {
    subjectVisitId: number;
    onIsOpenChange: (isOpen: boolean) => void;
}

const regex = /\[.*?\]/g;

const LogModal = ({ subjectVisitId, onIsOpenChange } : Props) => {
    const { data } = useQuery<SubjectVisitLogsQuery, SubjectVisitLogsQueryVariables>(subjectVisitLogsQuery, {
        variables: {
            subjectVisitId
        },
        fetchPolicy: 'cache-and-network'
    })
    
    return(
        <div>
            <Modal
            isOpen={true}
            onRequestClose={onIsOpenChange}
            contentLabel="Logs"
            > 
                    <HeaderRow>
                        <Header width={0.2}>
                            User
                        </Header>
                        <Header width={0.3}>
                            Date
                        </Header>
                        <Header width={0.5}>
                            Action
                        </Header>
                    </HeaderRow>
                    <ScrollView>
                    {data?.subjectVisitLogs.map(l => {
                        const parts = l.message.split(regex);
                        const values = l.message.match(regex);
                        let mess = parts.map((p, index) => {
                            let value = (values as string[])[index];
                            if(value){
                                return <>{p}<b>{value.substring(1, value.length - 1)}</b></>;
                            }
                            return ""
                        })
                        return(
                            <Row mt={2}>
                                <UsernameView width={0.2}>
                                    {l.user.username}
                                </UsernameView>
                                <Flex width={0.3}>
                                    {moment(Number(l.createdAt)).format("DD-MM-YYYY, h:mm a")}
                                </Flex>
                                <div css={{ width: '60%' }}>
                                    {mess}
                                </div>
                            </Row>
                        )
                    })}
                </ScrollView>
                <Center>
                    <Button color='none' onClick={() => onIsOpenChange(false)}>Cancel</Button>
                </Center>
                
            </Modal>
        </div>
    )
}

export default LogModal;

const UsernameView = styled(Flex)`
    word-break: break-all;
`;

const HeaderRow = styled(Flex)`
    border-bottom: 1px solid ${colors.lightGray};
    padding: 8px 0;
`

const Row = styled(Flex)`
    border-bottom: 1px solid ${colors.lightGray};
    padding: 4px 0;
    &:last-child {
        border-bottom: none;
    }
`;

const Header = styled(Flex)`
    font-weight: 600;
`;

const Center = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
`;

const ScrollView = styled.div`
    width: 600px;
    max-height: 400px;
    overflow: auto;
`;
