import React, { useState } from 'react';
import { Title } from '../components/Title';
import styled from '@emotion/styled';
import SwitchButton from '../components/SwitchButton';
import { colors } from '../libs/colors';
import { css } from '@emotion/core';

interface Props {
    list: { label: string, subLabel?: string | null | number, value: string | number, isNotActive?: boolean }[];
    itemStyle?: any;
    title: string;
    onAddClick?: () => void;
    onItemClick?: (value: string | number | boolean) => void;
    onItemChange?: (value: string | number | boolean, active: boolean) => void;
    addButtonText?: string;
    onRemove?: (value: string | number | boolean) => void;
}

const EditableList =  ({ list, title, itemStyle, onAddClick, onItemClick, addButtonText, onRemove, onItemChange }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const count = list.length;
    const showMore = count > 3;
    return (
        <div css={css`width :100%;`}>
            <Title>{title}</Title>
            <div>
                {list.slice(0, isOpen ? count : 3).map(item =>
                    <SwitchButton label={item.label} subLabel={item.subLabel} type='gray' value={item.value} isActive={!item.isNotActive} style={itemStyle} onChange={onItemChange} onClick={onItemClick} onRemove={onRemove} key={item.value}/>
                )}
            </div>
            <Row>
                {showMore 
                    ? <ShowMoreButton style={{ paddingLeft: isOpen ? 14 : 0}} onClick={() => setIsOpen(!isOpen)} role="button"> 
                        {isOpen ? 'hide' : `+ ${count - 3} more`}
                      </ShowMoreButton> 
                    : <div/>
                }
                {onAddClick != null && <AddItemButton onClick={onAddClick}>+ {addButtonText || 'add'}</AddItemButton>}
            </Row>
        </div>
    )
}

export default EditableList;

const Row = styled.div`
    justify-content: space-between;
    display: flex;
    width: 100%;
`;

const ShowMoreButton = styled.div`
    color: ${colors.link};
    font-size: 14;
    border: none;
    padding: 0;
    background: none;
`;

const AddItemButton = styled.button`
    border: 1px dotted #979797;
    border-radius: 14px;
    padding: 8px 16px;
    color: #0173E3;
    background: none;
`;