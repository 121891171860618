import  React from 'react';
import styled from '@emotion/styled';
import PaymentStatus from '../../components/Status';
 
const PaymentsSearch = () => {
    return ( 
        <SearchPaymentsContainer>
            <PaymentStatus legend incomingStatus='DONE' type="paymentCompensation"/>
            <PaymentStatus legend incomingStatus='PLANNED' type="paymentCompensation" />
            <PaymentStatus legend incomingStatus='DELAYED' type="paymentCompensation" />
            <PaymentStatus legend incomingStatus='NOT_DONE' type="paymentCompensation" />
            <PaymentStatus legend incomingStatus='FAILED' type="paymentCompensation" />
        </SearchPaymentsContainer>
     );
}
 
export default PaymentsSearch;

const SearchPaymentsContainer = styled.div`
width: 100%;
margin: 1em 0;
border-radius: 2px;
display: flex;
align-items: center;
`;

