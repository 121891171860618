import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../libs/colors';
import { ReactComponent as Delete } from '../../icons/delete.svg';
import { StudyDetail_study_contacts, RemoveStudyContact, RemoveStudyContactVariables, StudyDetail, StudyDetailVariables } from '../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
import { removeStudyContactMutation } from '../../graphql/mutations';
import { studyDetailQuery } from '../../graphql/queries';

interface Props {
    data?: StudyDetail_study_contacts | null;
    studyId: number;
    isAdmin?: boolean;
}

const StudyContact = ({ data,studyId, isAdmin }: Props) => {
    const [removeContact] = useMutation<RemoveStudyContact, RemoveStudyContactVariables>(removeStudyContactMutation);
    if(!!!data) {
        return null;
    }

    const deleteHandler = () => {
        removeContact({
                variables: {
                studyId,
                contactId: data.id
            },
            update(cache, result) {
                const variables = { studyId };
                const studyDetailCache = cache.readQuery<StudyDetail, StudyDetailVariables>({ query: studyDetailQuery, variables });                
                if(studyDetailCache){
                    const newData: StudyDetail= {
                    ...studyDetailCache,
                    study: {
                        ...studyDetailCache.study,
                        contacts: studyDetailCache.study.contacts.filter(u => u.id !== data.id)
                    }
                    };
                    cache.writeQuery({
                        query: studyDetailQuery,
                        variables,
                        data: newData
                    })
                }
            }
    })
    }
    return (
        <div css={{ width: '50%'}}>
        <ContactBox>
            {isAdmin && <DeleteButton onClick={deleteHandler}><Delete /></DeleteButton>}
    <Header>{data.clientId ? 'Client Contact' : 'Admin Contact'}</Header>
            <Name>{`${data.firstName} ${data.lastName}`}</Name>
            <ContactText>{data.email}</ContactText>
            <ContactText>{data.phone}</ContactText>
        </ContactBox>
        </div>
    )
}

export default StudyContact;

const ContactBox = styled.div`
background-color: ${colors.subjectVisit};
border-radius: 4px;
position: relative;
margin: 0.5em;
padding: 0.5em;
`;

const Header = styled.p`
font-size: 10px;
margin-bottom: 0;
color: ${colors.darkGray};
`;

const Name = styled.p`
font-weight: 500;
margin-top: 0.2em;
`;

const ContactText = styled.p`
font-size: 12px;
margin: 0.5em 0;
word-wrap: break-word;
`;

const DeleteButton = styled.button`
border: none;
background-color: transparent;
position: absolute;
top: 1em;
right: 0.5em;
outline: none;
`;