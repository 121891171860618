import React from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';

const NotFound = (_: RouteComponentProps) => (
    <Center>
        Not found
    </Center>
);

export default NotFound;

const Center = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
`;