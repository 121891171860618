import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as LogSVG } from '../../icons/log.svg';
import LogModal from './LogModal';

interface Props {
    subjectVisitId: number;
}

const AuditLog = ({ subjectVisitId }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <LogButton onClick={() => setIsOpen(!isOpen)}>
                <LogSVG style={{ width: '24px', height: '24px'}}/>
            </LogButton>
            {isOpen && <LogModal subjectVisitId={subjectVisitId} onIsOpenChange={setIsOpen}/> }
        </>
    )

}

export default AuditLog;


const LogButton = styled.button`
    border: none;
    background: none;
    padding: 0;
    outline: none;
    position: absolute;
    right: 0;
    bottom: 12px;
    opacity: 0.75;
`;