import React from 'react';
import { Link } from '@reach/router';
import { css } from '@emotion/core';
import Status from '../../components/Status';
import { Row, TableItem, Label, Value } from '../../components/Table';
import { Study_studies_subjects_deniedPayments } from '../../graphql/types';
import { colors } from '../../libs/colors';
import { getFormatedDate } from '../../helpers';
import Highlight from 'react-highlighter';

interface Props {
    code: string;
    bankAcount: string | null;
    lastVisit?: string | null;
    status: string;
    studyId: number;
    delayedCompensations: number;
    subjectId: number;
    deniedPayments: Study_studies_subjects_deniedPayments[];
    searchBy: string;
}

const SubjectItem = ({ code, bankAcount, lastVisit, status, delayedCompensations, subjectId, studyId, deniedPayments, searchBy }: Props) => {
    return (
        <Link css={css`text-decoration: none;`} to={`../../study/${studyId}/subject/${subjectId}`}>
            <Row>
                <TableItem css={css`width: 25%;`}>
                    <Label>
                        Subject ID
                </Label>
                    <Value css={css`color: ${colors.primary}; font-weight: 700;`}>
                    <Highlight search={searchBy}>{code}</Highlight>
                    </Value>
                </TableItem>
                <TableItem css={css`width: 20%;`}>
                    <Label css={{ visibility: bankAcount == null ? 'hidden' as "hidden" : 'initial' as "initial"}}>
                        Bank Account
                    </Label>
                    <Value>
                    <Highlight search={searchBy}>{bankAcount || 'Postal order'}</Highlight>
                    </Value>
                </TableItem>
                <TableItem css={css`width: 15%;`}>
                    <Label>
                        Last Visit
                </Label>
                    <Value>
                        {lastVisit ? getFormatedDate(lastVisit) : "N/A"}
                    </Value>
                </TableItem >
                <TableItem css={css`width: 20%;`}>
                    <Label>
                        Status
                </Label>
                    <Value>
                        {status}
                    </Value>
                </TableItem>
                    <TableItem css={css`width: 8%;`}>
                    <Label>
                        Compensations
                </Label>
                    <Value>
                    {delayedCompensations > 0 ?
                        <Status incomingStatus="PENDING" value={delayedCompensations} type="compensation"/>
                    : <Status 
                    incomingStatus="ok"
                    type="general" 
                />}
                    </Value>
                </TableItem>
                <TableItem css={css`width: 7%;`}>
                    <Label>
                        Payments
                    </Label>
                    <Value>
                    {deniedPayments.length > 0 ?
                        <Status 
                            incomingStatus={deniedPayments[0].status} 
                            type="payment" 
                            tippyMessage={deniedPayments[0].errorMessage}
                        />
                        : <Status 
                            incomingStatus="ok"
                            type="general" 
                        />}
                    </Value>
                </TableItem>
            </Row>
        </Link>
    )
}

export default SubjectItem;