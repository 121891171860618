import React, { useState } from 'react';
import StudyContact from './StudyContact';
import { Title } from '../../components/Title';
import { StudyDetail_study_contacts } from '../../graphql/types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import AddContactModal from './AddContactModal';

interface Props {
    contacts: StudyDetail_study_contacts[];
    studyId: number;
    isAdmin?: boolean;
    clientId: number;
}

const ContactsPick = ({ contacts, studyId, isAdmin, clientId }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <>
            <Title>Contacts</Title>
            <ContactsBox>
                {contacts.map(c => 
                    <StudyContact isAdmin={isAdmin} studyId={studyId} data={c} key={c.id}/>
                 )}
            </ContactsBox>
            <div css={css`display: flex; justify-content: flex-end; margin-top: 8px;`}>
                {isAdmin && <AddItemButton onClick={() => setIsModalOpen(true)}>+ add Contact</AddItemButton>}
            </div>
            {isModalOpen && <AddContactModal studyId={studyId} isOpen={isModalOpen} setIsOpen={setIsModalOpen} clientId={clientId} actualContacts={contacts}/>}
        </>
    );
}
export default ContactsPick;

const ContactsBox = styled.div`
display: flex;
flex-wrap: wrap;
`;

const AddItemButton = styled.button`
    border: 1px dotted #979797;
    border-radius: 14px;
    padding: 8px 16px;
    color: #0173E3;
    background: none;
`;