import React, { useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import TextInput from '../../components/TextInput';
import styled from '@emotion/styled';
import Button from '../../components/Button';
import { addFlowChartMutation } from '../../graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import { addFlowChartVariables } from '../../graphql/types';
import { useParams } from '@reach/router';
import { studyDetailQuery } from '../../graphql/queries';
import SnackBar from '../../components/SnackBar';
import moment from 'moment';
import { dateTemplate } from '../../helpers';

interface Props {
    isOpen: boolean;
    onIsOpenChange: (isOpen: boolean) => void;
    lastVersion: number;
}

const AddFlowchartModal = ({ isOpen, onIsOpenChange, lastVersion } : Props) => {
    const [versionDate, setVersionDate] = useState("");
    const [version, setVersion] = useState((lastVersion + 1).toString());
    const [error, setError] = useState<null | string>(null)
    const { studyId } = useParams()
    const [addFlowchart] = useMutation<any, addFlowChartVariables>(addFlowChartMutation);

    useEffect(() => {
        if ((/^\d{2}$/.test(versionDate) || /^\d{2}-\d{2}$/.test(versionDate))) {
            setVersionDate(versionDate + '-');
        }
    }, [versionDate]);

    useEffect(() => {
        setVersion((lastVersion + 1).toString())
    }, [lastVersion])

    const handleChange = (value: string) => {
        if (value.length <= 10 && (value.match(/^[0-9-]*$/) || value === '' )) {
            setVersionDate(value);
        }
        if ((/^\d{2}-\d{2}$/.test(value) && /^\d{2}-\d{2}-$/.test(versionDate)) || (/^\d{2}$/.test(value) && /^\d{2}-$/.test(versionDate))) {
            setVersionDate(value.slice(0, -1));
        }
    }
    const handleAddFlowchart = async () => {
        if(moment(versionDate, dateTemplate, true).isValid()){
            await addFlowchart({
                variables: {
                    studyId: parseInt(studyId, 10),
                    version: parseInt(version, 10),
                    versionDate: moment(versionDate, dateTemplate).toISOString()
                },
                refetchQueries: [{query: studyDetailQuery, variables: { studyId: parseInt(studyId, 10) }}],
            });
            onIsOpenChange(false);
        } else {
            setError('Incorrect format of verion date.')
        }
    }

    return(
        <>
        <Modal
          isOpen={isOpen}
          onRequestClose={onIsOpenChange}
          contentLabel="Add flowchart"
        >
            <Row>
                <TextInput label="Version number" value={version} onChange={setVersion} regex={/^[0-9]*$/g}/>
                <div style={{ marginLeft: 32}}>
                </div>
                <TextInput label="Version date" value={versionDate} onChange={handleChange} placeholder={dateTemplate} style={{ marginLeft: 32}}/>
            </Row>
            <Center>
                <Button color='none' onClick={() => onIsOpenChange(false)}>Cancel</Button>
                <Button onClick={handleAddFlowchart} disabled={versionDate === "" || version === ""} style={{ marginLeft: 32 }}>Save</Button>
            </Center>    
        </Modal>
        {error != null && <SnackBar message={error} resetError={setError}/>}
        </>
    )
}

export default AddFlowchartModal;

const Row = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
`;
