import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from '@reach/router';
import TopBar from '../../components/TopBar';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { paymentsQuery, selectorSubjectsQuery, siteNumbersSelector } from '../../graphql/queries';
import { Payments as PaymentsType, PaymentsVariables, SelectorSubjectsQuery, SelectorSubjectsQueryVariables, SiteNumbersSelector, SiteNumbersSelectorVariables, UserRole, me_me, CompensationStatus } from '../../graphql/types';
import PaymentDetail from './PaymentDetail';
import PaymentsSearch from './PaymentsSearch';
import PaymentSummary from './PaymentSummary';
import { Container } from '../../libs/styles';
import FilterBar from '../../components/FilterBar';
import { css } from '@emotion/core';
import Button from '../../components/Button';
import { parse } from "query-string";
import Header from '../../components/Header';
import XLSX from 'xlsx';
import { getFormatedDate } from '../../helpers';
import moment from 'moment';
import { useMe } from '../../hooks';

interface Props {
    me?: me_me,
    onlyList?: boolean
    type?: string;
}

const Payments = ({ onlyList = false, type }: Props) => {
    const { me } = useMe()
   const { studyId } = useParams();
    const itemsPerPage = 15;
    const location = useLocation();
    const isAdmin = me?.role === UserRole.ADMIN;
    const isClient = me?.role === UserRole.CLIENT_USER;
    const locationArr = location.pathname.split("/");
    const [siteNumber, setSiteNumber] = useState<string | null>(null);
    const [subjectId, setSubjectId] = useState<number | null>(null);
    const client = useApolloClient();
    const [page, setPage] = useState(1);
    const variables = {
        studyId: (studyId != null && studyId !== 'all') ? parseInt(studyId, 10) : undefined,
        type: type === 'all' ? undefined : locationArr[1],
        page: page,
        count: itemsPerPage,
        subjectId: subjectId,
        siteNumber: siteNumber
    }
    const { data } = useQuery<PaymentsType, PaymentsVariables>(paymentsQuery, {
        variables,
        fetchPolicy: 'cache-and-network',
    });
    useEffect(() => {
        const searchParams = parse(location.search)
        if (searchParams.site && typeof searchParams.site === 'string') {
            setSiteNumber(searchParams.site);
        }
        else {
            setSiteNumber(null);
        }
        if (searchParams.subject && typeof searchParams.subject === 'string') {
            setSubjectId(parseInt(searchParams.subject, 10));
        }
        else {
            setSubjectId(null);
        }
    }, [studyId, data, location.search])

    const { data: siteNumbers, refetch: refetchSites } = useQuery<SiteNumbersSelector, SiteNumbersSelectorVariables>(siteNumbersSelector, { skip: studyId == null, variables: { studyId: studyId !== 'all' ? parseInt(studyId!, 10) : undefined } });

    const totalCount = data?.paymentInfo?.paymentData?.totalCount;
    const { data: subjectSelector, refetch: refetchSubjects } = useQuery<SelectorSubjectsQuery, SelectorSubjectsQueryVariables>(selectorSubjectsQuery, {
        skip: studyId == null,
        variables: {
            studyId: studyId !== 'all' ? parseInt(studyId!, 10) : undefined,
            siteNumber,
        }
    })

    let subjectsSelector = subjectSelector?.subjects?.map(subject => ({ key: `centrum-${subject.id}`, label: subject.code, value: subject.id }));
    let siteNumbersSelect;
    if (siteNumbers != null && siteNumbers.studies?.length > 1) {
        siteNumbersSelect = siteNumbers?.studies.reduce((value: { key: string, label: string, value: string }[], study) => {
            study.siteNumbers?.map(siteNumber => {
                return value.push({ key: `siteNumber-${siteNumber}`, label: siteNumber, value: siteNumber })
            })
            return value;
        }, []);
    }
    else {
        siteNumbersSelect = siteNumbers?.studies[0]?.siteNumbers?.map(siteNumber => ({ key: `siteNumber-${siteNumber}`, label: siteNumber, value: siteNumber }));
    }

    

    if (subjectsSelector && siteNumbersSelect) {
        subjectsSelector = [{ label: "All", value: null as any, key: "all" }, ...subjectsSelector];
        siteNumbersSelect = [{ label: "All", value: null as any, key: "all" }, ...siteNumbersSelect];
    }

    const navigationData = [
        { text: `${locationArr[1] === 'payments' ? 'Payments' : 'Postal Orders'}`, path: '/payments/all', nextIsHiden: studyId === 'all' },
    ];

    const handleExtract = async () => {
        const totalCount = data?.paymentInfo.paymentData.totalCount || 0;
        const queryData = await client.query<PaymentsType, PaymentsVariables>({
            query: paymentsQuery,
            variables: {
                ...variables, 
                page: Math.ceil(totalCount / itemsPerPage)
            }
        })
        let dataToExport: any[] = [];
        dataToExport.push(["Type", "Name", "Site number", "Study", "Subject", "Visit number", "Visit","Date", "Status", "Bank account", "Price"]);
        queryData.data.paymentInfo.paymentData.payments.forEach(p => {
            dataToExport.push([
                "PAYMENT",
                "",
                p.subjectVisit.studyCentrum.code, 
                p.subjectVisit.visit.study.code, 
                p.subjectVisit.subjectCode,
                p.subjectVisit.orderNumber.toString(),
                p.subjectVisit.visit.name || p.subjectVisit.visit.orderNumber?.toString(),
                getFormatedDate(p.subjectVisit.date),
                p.status.toLocaleUpperCase(),
                p.subjectVisit.subject.bankAccount,
                p.price.toString()
            ]);
            p.subjectVisit.subjectVisitCompensations.forEach(c => {
                const price = c.compensation?.price || c.price;
                dataToExport.push([
                    c.compensation?.isExpense ? "EXPENSE" : "COMPENSATION",
                    c.compensation?.name, 
                    p.subjectVisit.studyCentrum.code, 
                    p.subjectVisit.visit.study.code, 
                    p.subjectVisit.subjectCode,
                    p.subjectVisit.orderNumber.toString(),
                    p.subjectVisit.visit.name || p.subjectVisit.visit.orderNumber?.toString(),
                    c.status !== CompensationStatus.PLANNED ? getFormatedDate(c.createdAt) : 'N/A', 
                    c.status.toLocaleUpperCase(),
                    "",
                    price?.toString()
                ])
            })
        });
        var wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(dataToExport);

        ws["!cols"] = [{ width: 20}, { width: 10}, { width: 10}, { width: 10}, { width: 15}, { width: 10 }, { width: 10}, { width: 20}, { width: 10}, { width: 20}];

        XLSX.utils.book_append_sheet(wb, ws, "Payment export");
        XLSX.writeFile(wb, `payments_export_${moment().format("DD-MM-YYYY_HH:mm")}.xlsx`);
    }

    return (
        <>
            {!onlyList && <TopBar />}
            {!onlyList && <Header studyId={studyId} data={navigationData} optionProps={{ onExtract: handleExtract }} hidePlusButton={!isAdmin}/>}
            <Container>
                <div css={css`background-color: white; padding: 1em 1em;`}>
                    {subjectsSelector && siteNumbersSelect && <FilterBar
                        siteNumber={(isAdmin || isClient) ? siteNumber : siteNumbersSelect[siteNumbersSelect.length > 1 ? 1 : 0].value}
                        studyId={studyId}
                        setSiteNumber={setSiteNumber}
                        subject={subjectId}
                        setSubject={setSubjectId}
                        subjects={subjectsSelector}
                        siteNumbers={siteNumbersSelect}
                        refreshSites={refetchSites}
                        refreshSubjects={refetchSubjects}
                    />}
                    <PaymentsSearch />
                </div>
                {data?.paymentInfo?.paymentData.payments
                    .sort((payment, secondPayment) => payment.subjectVisit.subjectId - secondPayment.subjectVisit.subjectId || payment.subjectVisit.orderNumber - secondPayment.subjectVisit.orderNumber)
                    .map(payment => <PaymentDetail type={locationArr[1]} studyId={studyId} siteNumber={siteNumber} subjectId={subjectId} page={page} count={itemsPerPage} key={`payment-${payment.id}`} data={payment}
                    />)}
                {totalCount != null && totalCount >= (page * itemsPerPage) && <div css={css`display: flex; justify-content: center;`}>
                    <Button onClick={() => setPage(page + 1)} color="none">Load more</Button>
                </div>}
                <PaymentSummary
                    totalDenied={data?.paymentInfo?.totalDenied}
                    totalPayed={data?.paymentInfo?.totalPayed}
                    totalPlanned={data?.paymentInfo?.totalPlanned}
                    totalApproved={data?.paymentInfo.totalApproved}
                />
            </Container>
        </>
    );
}

export default Payments;
