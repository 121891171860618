import { useRef, useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import { meQuery } from "./graphql/queries";
import { me } from "./graphql/types";

const usePrevious = (value: any) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

const useCounter = () => {
  const [state, setState] = useState(0);

  const handleSetValue = useCallback((seconds: number) => {
    setState(seconds)
  }, [])

  useEffect(() => {
      const timer = setTimeout(() => {
        setState(prevState => prevState - 1);
      }, 1000);
      
      if(state === 0){
        clearTimeout(timer);
      }
      return () => clearTimeout(timer);
  }, [state])

  return [state, handleSetValue] as const;
}

const useMe = () => {
  const { data: dataMe } = useQuery<me>(meQuery, { fetchPolicy: 'cache-first' });

  return { me: dataMe ? dataMe.me : null };
}

export { usePrevious, useCounter, useMe }