import React, { useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import TextInput from '../../components/TextInput';
import styled from '@emotion/styled';
import Button from '../../components/Button';
import { emailRegex } from '../../helpers';

export interface Contact {
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    username: string;
}

interface Props {
    isOpen: boolean;
    onIsOpenChange: (isOpen: boolean) => void;
    onSave:(user: Contact) => void;
    contact?: Contact;
};

const defaultValue = {
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
    username: ""
}

const AddContactModal = ({ isOpen, onIsOpenChange, onSave, contact } : Props) => {
    const [contactValues, setContact] = useState(defaultValue);

    useEffect(() => {
        setContact(defaultValue)
    }, [isOpen]);

    useEffect(() => {
        if(contact){
            setContact(contact)

        }
    }, [contact]);

    const handleInput = (value: any, inputName?: string) => {
        if(inputName){
            setContact(prevState => ({
                ...prevState,
                [inputName]: value
            }))
        }
    }

    const handleSave = () => {
        if(emailRegex.test(contactValues.email)){
            onSave(contactValues);
            onIsOpenChange(false);
        } 
    }

    const foundEmpty = Object.keys(contactValues).find(key => (contactValues as any)[key].length === 0)
    return(
        <>
        <Modal
          isOpen={isOpen}
          onRequestClose={onIsOpenChange}
          contentLabel="Add center"
        > 
                <TextInput label="Username" value={contactValues.username} onChange={handleInput} name="username" style={{ marginTop: 16, width: 250 }}/>
                <TextInput label="Firstname" value={contactValues.firstname} onChange={handleInput} name="firstname" style={{ marginTop: 16, width: 250 }}/>
                <TextInput label="Lastname" value={contactValues.lastname} onChange={handleInput} name="lastname" style={{ marginTop: 16, width: 250 }}/>
                <TextInput label="Email" value={contactValues.email} onChange={handleInput} name="email" style={{ marginTop: 16, width: 250 }} />
                <TextInput label="Phone" value={contactValues.phone} onChange={handleInput} name="phone" style={{ marginTop: 16, width: 250 }}/>
            <Center>
                <Button color='none' onClick={() => onIsOpenChange(false)}>Cancel</Button>
                <Button onClick={handleSave} disabled={!!foundEmpty}>Save</Button>
            </Center>        
        </Modal>
        </>
    )
}

export default AddContactModal;

const Center = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
`;
