import React, { useState } from 'react';
import Modal from '../../components/Modal';
import { css } from '@emotion/core';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import { useMutation } from '@apollo/react-hooks';
import { addMoneyToBudget } from '../../graphql/mutations';
import { AddMoneyToBudget, AddMoneyToBudgetVariables } from '../../graphql/types';

interface Props {
    setOpenModal: (value: boolean) => void;
    openModal: boolean;
    studyId?: string;
}

const AddStudyBudgetModal = ({ setOpenModal, openModal, studyId }: Props) => {
    const [price, setPrice] = useState('');
    const [addMoney] = useMutation<AddMoneyToBudget, AddMoneyToBudgetVariables>(addMoneyToBudget);

    const addMoneyHandler = () => {
        if (studyId != null) {
            addMoney({
                variables: {
                    input: {
                        studyId: parseInt(studyId,10),
                        price: parseInt(price, 10),
                    }
                }
            })
            setOpenModal(false)
        }
    }

    return (
        <Modal onRequestClose={() => setOpenModal(false)} isOpen={openModal} >
            <div>
                <TextInput regex={/^-{0,1}(\s*|\d+)$/} style={{ minWidth: '200px' }} value={price} onChange={setPrice} label='price' />
                <div css={css`margin-top: 1em; display: flex; justify-content: center;`}>
                    <Button onClick={addMoneyHandler} disabled={!!!price}>Add money</Button>
                </div>
            </div>
        </Modal>
    )
}

export default AddStudyBudgetModal;

