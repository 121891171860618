import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { css } from '@emotion/core';
import { studyCompensationsQuery, editStudyVisitFragment, studySubjectVisitFragment } from '../graphql/queries';
import { StudyCompensations, StudyCompensationsVariables, AddVisitCompensation, AddVisitCompensationVariables, AddSubjectvisitCompensationVariables, AddSubjectvisitCompensation, StudyDetail_study_flowCharts_visits, StudySubjectVisit } from '../graphql/types';
import { addVisitCompensationMutation, addSubjectVisitCompensationMutation } from '../graphql/mutations';
import { colors } from '../libs/colors';
import { ReactComponent as Arrow } from '../icons/down-arrow.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import SetCompensationPriceModal from '../pages/SubjectDetail/SetCompensationPriceModal';


const getHeight = (count: number) => {
    switch (count) {
        case 1:
            return '160px'
        case 2:
            return '130px'
        case 3:
            return '90px';
        default:
            return '64px'

    }
}

interface Props {
    studyId: string;
    visitId: number;
    subjectVisitId?: number;
    disabled?: boolean;
    editable?: boolean;
    allowExpenses?: boolean | null;
    activeCompensationsCount?: number;
}

const AddCompensation = ({ studyId, visitId, subjectVisitId, disabled, editable, allowExpenses, activeCompensationsCount }: Props) => {
    const [showOptions, setShowOptions] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const { data } = useQuery<StudyCompensations, StudyCompensationsVariables>(studyCompensationsQuery, {
        variables: {
            studyId: parseInt(studyId, 10)
        },
        fetchPolicy: 'cache-and-network'
    });

    const handleAdd = () => {
        !disabled && setShowOptions(!showOptions);
    }

    let list = [];

    if (subjectVisitId && allowExpenses && data) {
        list.push({ name: "Compensations" })
        list.push(...data.studyCompensations.filter(c => !c.isExpense))
        list.push({ name: "Expenses" })
        list.push(...data.studyCompensations.filter(c => !!c.isExpense))
    } else if (data) {
        list.push(...data.studyCompensations.filter(c => !c.isExpense))
    }

    return (
        <OutsideClickHandler onOutsideClick={() => !isModalOpen && setShowOptions(false)}>
            <div css={css`position: relative; margin-right: ${editable ? '27px' : 0};`}>
                <AddCompensationButton disabled={disabled} onClick={handleAdd} isListOpen={showOptions}>
                    + Add Items
                    <CustomArrow isOpen={showOptions} />
                </AddCompensationButton>
                {showOptions &&
                    <CompensationsList style={{ maxHeight: activeCompensationsCount ? getHeight(activeCompensationsCount) : 'auto', overflow: 'auto' }}>
                        {list?.map((c, index) =>
                            <AddCompensationItem
                                key={`comp_${index}`}
                                isExpense={!!(c as any).isExpense}
                                subjectVisitId={subjectVisitId}
                                visitId={visitId}
                                studyId={studyId}
                                notifModalState={setModalOpen}
                                onClose={() => setShowOptions(false)}
                                name={c.name}
                                id={(c as any).id}
                            />
                        )}
                    </CompensationsList>
                }
            </div>
        </OutsideClickHandler>
    )
};
export default AddCompensation;

interface AddCompensationItemProps {
    name: string;
    id?: number;
    visitId: number;
    studyId: string;
    subjectVisitId?: number;
    isExpense: boolean;
    notifModalState: (isOpen: boolean) => void;
    onClose: () => void;
}

const AddCompensationItem = ({ name, id, studyId, visitId, subjectVisitId, isExpense, notifModalState, onClose }: AddCompensationItemProps) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [addNewCompensation] = useMutation<AddSubjectvisitCompensation, AddSubjectvisitCompensationVariables>(addSubjectVisitCompensationMutation);
    const [addVisitCompensation] = useMutation<AddVisitCompensation, AddVisitCompensationVariables>(addVisitCompensationMutation);

    const handleAddSubjectVisitCompensation = (price?: number) => {
        if (id && subjectVisitId) {
            addNewCompensation({
                variables: {
                    studyId: parseInt(studyId, 10),
                    compensationId: id,
                    visitId: visitId,
                    subjectVisitId: subjectVisitId,
                    price

                },
                update(cache, result) {
                    const newItem = result.data?.addSubjectVisitCompensation;
                    const studySubjectVisitCache = cache.readFragment<StudySubjectVisit>({ 
                        fragment: studySubjectVisitFragment, 
                        id: `SubjectVisit:${subjectVisitId.toString()}`
                    });   
                  
                    if(studySubjectVisitCache && newItem){
                        cache.writeFragment<StudySubjectVisit>({
                            fragment: studySubjectVisitFragment,                    
                            id: `SubjectVisit:${subjectVisitId.toString()}`,
                            data: {
                                ...studySubjectVisitCache,
                                subjectVisitCompensations: [
                                    ...studySubjectVisitCache.subjectVisitCompensations,
                                    { 
                                        ...newItem,
                                        compensation: {
                                            __typename: 'Compensation',
                                            id,
                                            name,
                                            isExpense: price != null,
                                            price: price == null ? null : price
                                        }
                                    }
                                ]
                            }
                        })
                    }
                }
            })
            closeModal(false);
        }
    }

    const handleAddVisitCompensation = () => {
        if (id && visitId) {
            addVisitCompensation({
                variables: {
                    studyId: parseInt(studyId, 10),
                    visitId: visitId,
                    compensationId: id
                },
                update(cache, result) {
                    const newItem = result.data?.addVisitCompensation;
                    const editStudyVisitCache = cache.readFragment<StudyDetail_study_flowCharts_visits>({ 
                        fragment: editStudyVisitFragment, 
                        id: `Visit:${visitId.toString()}`,
                        fragmentName: 'EditStudyVisit',
                    });   
                  
                    if(editStudyVisitCache && newItem){
                        cache.writeFragment<StudyDetail_study_flowCharts_visits>({
                            fragment: editStudyVisitFragment,
                            fragmentName: 'EditStudyVisit',
                            id: `Visit:${visitId.toString()}`,
                            data: {
                                ...editStudyVisitCache,
                                visitCompensations: [
                                    ...editStudyVisitCache.visitCompensations,
                                    {
                                        ...newItem,
                                        compensation: {
                                            __typename: 'Compensation',
                                            id,
                                            name,
                                            deletedAt: null
                                        }
                                    }
                                ]
                            }
                        })
                    }
                }
            })
        }
    }

    const handleClick = () => {
        if (isExpense) {
            setModalOpen(true);
            notifModalState(true);
        } else if (subjectVisitId) {
            handleAddSubjectVisitCompensation()
        } else {
            handleAddVisitCompensation();
        }
    }

    const closeModal = (isOpen: boolean) => {
        onClose();
        setModalOpen(isOpen);
        notifModalState(isOpen);
    }

    return (
        <>
            <AddCompensationItemButton onClick={handleClick} style={{ color: id == null ? colors.gray : colors.link, fontSize: id == null ? '11px' : '14px' }}>
                {name}
            </AddCompensationItemButton>
            {isModalOpen && <SetCompensationPriceModal onIsOpenChange={closeModal} onSave={handleAddSubjectVisitCompensation} />}
        </>
    )
}

const AddCompensationButton = styled.button<{ isListOpen: boolean, disabled?: boolean }>`
display: flex;
justify-content: space-between;
align-items: center;
border-style: dotted;
position: relative;
border-width: 1px;
border-radius: 14px;
margin: 0.5em 0;  
padding: 0.5em 1em 0.5em 1em;
cursor: pointer;
outline: none;
width: 100%;
background-color: transparent;
color: ${({ disabled }) => disabled ? colors.gray : colors.link};
font-size: 14px;
border-bottom-left-radius: ${props => props.isListOpen ? 0 : '14px'};
border-bottom-right-radius: ${props => props.isListOpen ? 0 : '14px'};
`;

const CompensationsList = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 24px;
    right: 0;
    padding: 8px 1.3em 0 1.3em;
    background: #f6f3f3;
    border: 1px dotted #767676;
    border-radius: 14px;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 23;
`;

const AddCompensationItemButton = styled.button`
border: none;
outline: none;
cursor: pointer;
background: none;
font-size: 14px;
color: ${colors.link};
text-align: left;
padding: 4px;
`;

const CustomArrow = styled(Arrow) <{ isOpen: boolean }>`
width: 12px;
height: 12px;
position: absolute;
color: ${colors.gray};
right: 14px;
top: 12px;
${({ isOpen }) => isOpen ? `transform: rotate(180deg);` : undefined}
`;