import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useParams, useNavigate } from '@reach/router';
import TopBar from '../../components/TopBar';
import Header from '../../components/Header';
import { css } from '@emotion/core';
import { Item } from '../../components/Item';
import { Container } from '../../libs/styles';
import TextInput from '../../components/TextInput';
import EditableList from '../../components/EditableList';
import InfoBar from '../../components/InfoBar';
import Button from '../../components/Button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { detailClientsQuery } from '../../graphql/queries';
import { DetailClientsQueryVariables, DetailClientsQuery, updateClientVariables, updateClient as UpdateClientQuery } from '../../graphql/types';
import { Contact } from './AddContactModal';
import AddContactModal from './AddContactModal';
import * as R from 'ramda';
import { updateClientMutation } from '../../graphql/mutations';
import TextArea from '../../components/TextArea';
import Flex from '../../components/Flex';


interface ClientPayload {
    contact: Contact;
    index: number;
}


const UpdateClient = (_: RouteComponentProps) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [note, setNote] = useState("");
    const { clientId } = useParams();
    const [info, setInfo] = useState<string | null>(null);
    const { data, loading } = useQuery<DetailClientsQuery, DetailClientsQueryVariables>(detailClientsQuery, {
        variables: {
            clientId: clientId ? Number(clientId) : undefined
        },
        fetchPolicy: 'cache-and-network',
        skip: clientId == null
    })

    const [updateClient, { loading: loadingUpdate, data: updateData }] = useMutation<UpdateClientQuery, updateClientVariables>(updateClientMutation);

    const [localContactList, setLocalContacList] = useState<ClientPayload[]>([])

    useEffect(() => {
        if (!loading && 
            (name !== (data?.clients[0].name || "") || 
            (data != null && 
            (!R.equals(data ? data.clients[0].contacts?.map(u => u.email) : [], localContactList.map(localContact => localContact.contact.email)))))) {
            setInfo('It looks like you have been editing something. If you leave before saving, your changes will be lost.');
        }
        else {
            setInfo(null);
        }
    }, [localContactList, data, name, loading]);

    const handleSaveClient = async () => {
        const contacts = localContactList.map(contact => contact.contact)
        await updateClient({
            variables: {
                clientInput: {
                    name,
                    contacts,
                    clientId: clientId ? parseInt(clientId, 10) : undefined,
                    note
                }
            },
        })
        navigate(-1);
    }

    const handleRemoveClient = (value: any) => {
        setLocalContacList(prevState => prevState.filter(u => u.index !== value));
    }

    const handleAddContact = (contact: Contact) => {
        if (localContactList != null) {
            const contactId = (localContactList != null ? ((localContactList.length) + 1) : 0);
            setLocalContacList(prevState => ([...prevState, { contact, index: contactId }]));
        }
    }
    

    useEffect(() => {
        if (data != null && data?.clients[0].contacts) {
            setLocalContacList(data.clients[0].contacts.map((contact, index) => 
                ({ 
                    contact: { 
                        email: contact.email, 
                        firstname: contact.firstName, 
                        lastname: contact.lastName, 
                        phone: contact.phone,
                        username: contact.user ? contact.user.username : ""
                    }, 
                    index: index + 1 
                })
            ))
            setName(data.clients[0].name);
            setNote(data.clients[0].note || "");
        }   
    }, [data])

    const navigationData = [
        { text: 'Sites', path: '/clients'},
        {text: clientId ? 'Edit Client' : 'Add Client' }

    ];

    return (
        <>
            <TopBar sites />
            <Header 
                hidePlusButton
                data={navigationData}
            />
            <Container css={css`padding-bottom: 3em;`}>
                <Flex>
                    <Flex flexDirection="column" width={0.4}>
                        <Item>
                            <TextInput label="Client Name" value={name} onChange={setName} style={{ width: '100%' }} />
                        </Item>
                        <Item>
                            <TextArea label="Note" value={note} onChange={setNote} style={{ width: '100%' }} />
                        </Item>
                    </Flex>
                    <Item css={css`margin-left: 16px; width: 60%;`}>
                        <EditableList
                            title="Contacts"
                            list={localContactList.map(client => { return { label: `${client.contact.firstname} ${client.contact.lastname} (${client.contact.username})`, subLabel:`${client.contact.phone}, ${client.contact.email}`, value: client.index } }).reverse()}
                            onAddClick={() => setModalOpen(true)}
                            addButtonText="Add Contact"
                            onRemove={handleRemoveClient}
                            itemStyle={{ marginBottom: 8, width: '100%' }}
                        />
                    </Item>
                    </Flex>
                <Button onClick={handleSaveClient} disabled={loadingUpdate || !!updateData}>Save</Button>

                <AddContactModal isOpen={isModalOpen} onIsOpenChange={setModalOpen} onSave={handleAddContact} />
            </Container>
            {!loading && info && <InfoBar buttonText='save' buttonClick={handleSaveClient} disabled={loadingUpdate || !!updateData} type='primary' message={info} />}
        </>
    )
};

export default UpdateClient;