import React from 'react';
import styled from '@emotion/styled';

interface Props {
    value: string | number;
    label: string;
    style?: any;
    responsible?: boolean;
}

const TextField = ({ label, value, style, responsible }: Props) => {
    return (
        <Container style={style}>
            <Label>
                {label}
            </Label>
            <Value responsible={responsible}>
                {value}
            </Value>
        </Container>
    )
};

export default TextField;

const Container = styled.div`
    border: 1px solid #DEDEDE;
    padding: 8px 8px 10px 12px;
    margin-right: 16px;
`;

const Label = styled.div`
    font-size: 10px;
    color: #363636;
    line-height: 10px;
`;

const Value = styled.div<{ responsible?: boolean }>`
    font-size: 16px;
    font-weight: 600;
    margin-top: 4px;
    ${({ responsible }) => `
        overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    `}

`;