import React, { useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import TextInput from '../../components/TextInput';
import styled from '@emotion/styled';
import Button from '../../components/Button';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { user as UserQueryType, centrumUser, centrumUserVariables } from '../../graphql/types';
import { userQuery, centrumUserQuery } from '../../graphql/queries';
import { emailRegex } from '../../helpers';

export interface User {
    username: string;
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
}

interface Props {
    isOpen: boolean;
    onIsOpenChange: (isOpen: boolean) => void;
    userId?: number;
    onSave:(user: User) => void;
    users: User[];
};

const defaultValue = {
    username: "",
    email: "",
    firstname: "",
    lastname: "",
    phone: ""
}

const AddUserModal = ({ isOpen, onIsOpenChange, userId, onSave, users } : Props) => {
    const [user, setUser] = useState(defaultValue);
    const [inputError, setInputError] = useState<{ email: boolean, username: boolean }>({
        email: false,
        username: false
    });

    const apolloClient = useApolloClient()

    useEffect(() => {
        setUser(defaultValue)
    }, [isOpen]);

    const { data } = useQuery<UserQueryType>(userQuery, { 
        variables: { userId: userId || -1 }, 
        skip: userId == null
    });

    useEffect(() => {
        if(data){
            const { user } = data;
            setUser({
                username: user.username,
                firstname: user.contact.firstName,
                lastname: user.contact.lastName,
                email: user.contact.email,
                phone: user.contact.phone
            })

        }
    }, [data]);

    const handleInput = (value: any, inputName?: string) => {
        if(inputName){
            setUser(prevState => ({
                ...prevState,
                [inputName]: value
            }))
        }
    }

    const handleSave = () => {
        if(emailRegex.test(user.email)){
            onSave(user);
            onIsOpenChange(false);
            setUser(defaultValue);
        } 
    }

    const handleBlur = async () => {
        const { data } = await apolloClient.query<centrumUser, centrumUserVariables>({
            query: centrumUserQuery,
            variables: {
                email: user.email,
                username: user.username,
            },
            fetchPolicy: 'network-only'
        });
        
        const sameUserWithUsername = users.find(u => u.username.toLocaleLowerCase() === user.username.toLocaleLowerCase());
        const sameUserWithEmail = users.find(u => u.email.toLocaleLowerCase() === user.email.toLocaleLowerCase())
        setInputError({
            username: ((data.centrumUser && data.centrumUser.username === user.username) || sameUserWithUsername) ? true : false,
            email:  ((data.centrumUser && data.centrumUser.contact.email === user.email) || sameUserWithEmail) ? true : false,
        })
    }

    const handleEmailBlur = async () => {
        await handleBlur();
        const isEmailValid = emailRegex.test(user.email);
        if(!isEmailValid){
            setInputError(prevState => ({
                ...prevState,
                email: true
            }))
        }
    }

    const isInputInvalid = Object.keys(inputError).find(key => (inputError as any)[key]);

    const isSaveDisabled = !!isInputInvalid || user.email === "" || user.firstname === "" || user.lastname === "" || user.phone === "" || user.username === "";
    return(
        <>
        <Modal
          isOpen={isOpen}
          onRequestClose={onIsOpenChange}
          contentLabel="Add center"
        > 
                <TextInput label="User Name" value={user.username} err={inputError.username} onChange={handleInput} name="username" style={{ width: 250 }} onBlur={handleBlur}/>
                <TextInput label="First Name" value={user.firstname} onChange={handleInput} name="firstname" style={{ marginTop: 16, width: 250 }}/>
                <TextInput label="Last Name" value={user.lastname} onChange={handleInput} name="lastname" style={{ marginTop: 16, width: 250 }}/>
                <TextInput label="Email" value={user.email} err={inputError.email} onChange={handleInput} name="email" style={{ marginTop: 16, width: 250 }} onBlur={handleEmailBlur}/>
                <TextInput label="Phone" value={user.phone} onChange={handleInput} name="phone" style={{ marginTop: 16, width: 250 }}/>
            <Center>
                <Button color='none' onClick={() => onIsOpenChange(false)}>Cancel</Button>
                <Button onClick={handleSave} disabled={isSaveDisabled}>Save</Button>
            </Center>        
        </Modal>
        </>
    )
}

export default AddUserModal;

const Center = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
`;
