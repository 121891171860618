import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as CheckedGraySVG } from '../icons/checked-gray.svg';
import { ReactComponent as CheckedSVG } from '../icons/done.svg';
import { ReactComponent as CancelSVG } from '../icons/not-done.svg';
import { ReactComponent as RemoveSVG } from '../icons/delete.svg';
import Flex from './Flex';


interface Props {
    label: string;
    subLabel?: string | null | number;
    value: number | string | boolean;
    isActive: boolean;
    onChange?: (value: number | string | boolean, active: boolean) => void;
    size?: string;
    type?: string
    style?: any;
    onRemove?: (id: string | number | boolean) => void;
    subLabelStyle?: any;
    onClick?: (value: string | number | boolean) => void;
}

const SwitchButton = ({ label, value, subLabel, isActive, onChange, size = "normal", type = "gray", style, onRemove, subLabelStyle, onClick }: Props) => {
    const handleChange = () => {
        onChange && onChange(value, !isActive);
    }
    
    const handleRemove = () => {
        onRemove && onRemove(value);
    }

    const handleClick = () => {
        onClick && onClick(value);
    }

    return (
        <Row style={style}>
            <Container>
                <Flex flex={1} role="button" onClick={handleClick}>
                <span style={{ paddingLeft: 16, fontSize: size === "normal" ? '14px' : '10px' }}>{label}</span>
                </Flex>
                <Row style={{ alignItems: 'center'}}>
                    {subLabel != null && <SubLabel size={size} style={subLabelStyle}>{subLabel}</SubLabel>}
                    <div style={{ marginLeft: 16, height: 31 }} role="button" onClick={handleChange}>
                        {isActive 
                        ? <CheckedSVG style={{ width: size === "normal" ? "auto" : "22px" }}/> 
                        : type === "gray" 
                            ? <CheckedGraySVG style={{ width: size === "normal" ? "auto" : "22px" }}/>
                            : <CancelSVG style={{ width: size === "normal" ? "auto" : "22px" }}/>
                        }
                    </div>
                </Row>
            </Container>
            <DeleteContainer>
                {onRemove != null && <RemoveSVG onClick={handleRemove}/>}
            </DeleteContainer>
        </Row>
    );
};

export default SwitchButton;

const DeleteContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
    padding-left: 8px;
`;

const Row = styled.div`
    display: flex;
`;

const Container = styled.div`
    background-color: #F6F3F3;
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 31px;
    border-radius: 15px;
    width: 100%;
`;

const SubLabel = styled.span<{ size: string }>`
    color: #727272;
    font-size: ${props => props.size === "normal" ? '14px' : '11px'};
    display: flex;
`;