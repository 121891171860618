import React from 'react';
import { detailCentrums_centrums } from '../../graphql/types';
import { Row, TableItem, Value, Label } from '../../components/Table';
import Highlight from 'react-highlighter';
import { Link } from '@reach/router';
import { css } from '@emotion/core';

interface Props {
    centrums: detailCentrums_centrums[];
    searchBy: string;
}

const CentrumList = ({ centrums, searchBy }: Props) => {
    return(
        <>
            {centrums.map(c => (
                <Link css={css`text-decoration: none;`} to={`/sites/${c.id}`}>
                    <Row>
                        <TableItem css={css`width: 20%;`}>
                            <Label>Site</Label>
                            <Value>
                                <Highlight search={searchBy}>{c.name}</Highlight>
                            </Value>
                        </TableItem>
                        <TableItem css={css`width: 30%;`}>
                            <Label>Studies</Label>
                            <Value>{c.studies.length}</Value>
                        </TableItem>
                        <TableItem css={css`width: 30%;`}>
                            <Label>Site Numbers</Label>
                            <Value>
                                <Highlight search={searchBy}>{c.codes.join(", ")}</Highlight>
                            </Value>
                        </TableItem>
                        <TableItem style={{ marginLeft: 8 }} css={css`width: 10%;`}>
                            <Label>Users</Label>
                            <Value>{c.users.length}</Value>
                        </TableItem>
                        <TableItem css={css`width: 5%;`}>
                            <Label>Subjects</Label>
                            <Value>{c.subjects.length}</Value>
                        </TableItem>
                    </Row>
                </Link>
            ))}
        </>
    );
}

export default CentrumList;