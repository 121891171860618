import React, { useState } from 'react';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import styled from '@emotion/styled';
import SelectBox from '../../components/SelectBox';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { selectorSubjectsQuery, studySubjectFragment } from '../../graphql/queries';
import { SelectorSubjectsQuery, SelectorSubjectsQueryVariables, AddSubjectVisitVariables, StudySubject, AddSubjectVisit } from '../../graphql/types';
import { addSubjectVisitMutation } from '../../graphql/mutations';
import { css } from '@emotion/core';
import TextInput from '../../components/TextInput';

interface Props {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    studyId: number;
    subjectId: number;
    orderNumbers: number[];
    flowChartId: number | null;
}
export interface LocalCompensations {
        status: any; id: number; compensation: { name: string; id: number; };
}

const AddUnscheduledVisitModal = ({ isOpen, setIsOpen, studyId, subjectId, orderNumbers, flowChartId }: Props) => {
    const { data } = useQuery<SelectorSubjectsQuery, SelectorSubjectsQueryVariables>(selectorSubjectsQuery, { variables: { studyId }, skip: !isOpen });
    const [addSubjectVisit] = useMutation<AddSubjectVisit, AddSubjectVisitVariables>(addSubjectVisitMutation);
    const subjects = data?.subjects.map(subject => ({ key: `study-${subject.id}`, label: subject.code, value: subject.id }));
    const visitsOrderNumbers = orderNumbers.map((number, index) => ({key: `order-number-${number}`, label: (index + 1).toString(), value: number}))
    const [subjectIdInput, setSubjectIdInput] = useState(subjectId);
    const [position, setPosition] = useState(orderNumbers[orderNumbers.length - 2]);
    const [visitName, setVisitName] = useState('');

    const createVisit = () => {
        if ( !!subjectIdInput) {
            addSubjectVisit({
                variables: {
                    input: {
                        subjectId: subjectIdInput,
                        studyId: studyId,
                        orderNumber: position,
                        flowChartId,
                        name: !!visitName ? visitName :  null,
                    }
                },
                update(cache, result) {
                    const newItem = result.data?.addSubjectVisit;
                    const studySubjectCache = cache.readFragment<StudySubject>({ 
                        fragment: studySubjectFragment, 
                        id: `Subject:${subjectIdInput.toString()}`,
                        fragmentName: 'StudySubject',
                    });   
                    if(studySubjectCache && newItem){
                        const orderNumber = newItem.orderNumber;
                        cache.writeFragment<StudySubject>({
                            fragment: studySubjectFragment,
                            fragmentName: 'StudySubject',
                            id: `Subject:${subjectIdInput.toString()}`,
                            data: {
                                ...studySubjectCache,
                                subjectVisits: [
                                    ...studySubjectCache.subjectVisits.slice(0, orderNumber - 1),
                                    { ...newItem },
                                    ...studySubjectCache.subjectVisits.slice(orderNumber - 1)
                                ]
                            }
                        })
                    }
                }
            })
        }
        setIsOpen(false);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            contentLabel="Add flowchart"
        >
            {!!subjects && <SelectBox list={subjects} label="Subject ID" minWidth="250px" value={subjectIdInput} onChange={(e) => setSubjectIdInput(e)} />}
            <div css={css`margin: 1em 0; position: relative; z-index: 1;`}>
            {!!orderNumbers && <SelectBox list={visitsOrderNumbers} label="set after" minWidth="250px" value={position} onChange={(e) => setPosition(e)} />}
            <TextInput label="Visit name" value={visitName} onChange={setVisitName} style={{ marginTop: 16 }} />
            </div>
            <ButtonsBox>
                <Button onClick={() => setIsOpen(false)} color='none'>
                    Cancel
            </Button>
            <Button onClick={createVisit}>
                    Add visit
            </Button>
            </ButtonsBox>
        </Modal>
    )
}

export default AddUnscheduledVisitModal;

const ButtonsBox = styled.div`
display: flex;
justify-content: space-between;
`;