import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import add from '../../icons/add.svg'
import styled from '@emotion/styled';

interface Props {
    onPay: (paymentId: number) => void;
    onCancel: (paymentId: number) => void;
    paymentId: number;
}

const OptionButton = ({ onPay, onCancel, paymentId }: Props) => {
    const [isOptionOpen, setOptionOpen] = useState(false);
    const handleOpenOption = () => {
        setOptionOpen(prevState => !prevState);
    }

    const handlePay = () => {
        setOptionOpen(false)
        onPay(paymentId);
    }

    const handleCancel = () => {
        setOptionOpen(false)
        onCancel(paymentId);
    }

    return(
        <OutsideClickHandler onOutsideClick={() => setOptionOpen(false)}>
            <div style={{ position: "relative", zIndex: isOptionOpen ? 3 : 2, height: '30.5px', width: '30.5px' }}>
                {isOptionOpen &&
                    <ListContainer>
                     <ListItem role="button" onClick={handlePay}>
                            Pay
                        </ListItem>
                        <ListItem role="button" onClick={handleCancel}>
                            Cancel
                        </ListItem>
                    </ListContainer>
                }
                <AddButoon onClick={handleOpenOption}>
                    <img alt='add-button' src={add} style={{ height: '100%'}}/>
                </AddButoon>
            </div>
        </OutsideClickHandler>
    )
}

export default OptionButton;

const ListItem = styled.li`
    padding: 0 16px 8px 16px;
    color: white;
    text-align: right;
    white-space: nowrap;
    font-size: 13px;
    cursor: pointer;
`;

const ListContainer = styled.ul`
    position: absolute;
    right: 0;
    top: 0;
    background: #d5a63f;
    padding: 30px 0 8px 0; 
    z-index: -1;
    border-radius: 2px;
    margin: 0;
    list-style-type: none;
`;

const AddButoon = styled.button`
border: none;
outline: none;
background-color: transparent;
z-index: 10;
margin: 0;
height: 100%;
padding: 0;
`;