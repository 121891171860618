import React, { useState } from 'react';
import Modal from '../../components/Modal';
import styled from '@emotion/styled';
import Button from '../../components/Button';
import { updateCompensationNameMutation } from '../../graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import { UpdateCompensationNameVariables, UpdateCompensationName } from '../../graphql/types';
import SnackBar from '../../components/SnackBar';
import TextInput from '../../components/TextInput';
import { css } from '@emotion/core';

interface Props {
    isOpen: boolean;
    onIsOpenChange: (isOpen: boolean) => void;
    compensationId: number;
    compensationName: string;
}

const ChangeCompensationNameModal = ({ isOpen, onIsOpenChange, compensationName, compensationId } : Props) => {
    const [name, setName] = useState(compensationName);
    const [addCompensation, { error }] = useMutation<UpdateCompensationName, UpdateCompensationNameVariables>(updateCompensationNameMutation);

    const handleAddCompensation = async () => {
        try{
            await addCompensation({
                variables: {
                    compensationId,
                    name,
                },
            });
            onIsOpenChange(false)
        } catch (e) {}
    }

    const isButtonDisabled = name === "";  
    return(
        <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={onIsOpenChange}
          contentLabel="Change compensation name"
        > 
            <Row css={css`width: 400px; justify-content: center;`}>
                <TextInput label="Name" value={name} onChange={setName} />    
            </Row>
            <Center>
                <Button color='none' onClick={() => onIsOpenChange(false)}>Cancel</Button>
                <Button onClick={handleAddCompensation} disabled={isButtonDisabled}>Save</Button>
            </Center>
            
        </Modal>
         {error != null && <SnackBar message={error?.message.split(":")[1]}/>}
        </div>
    )
}

export default ChangeCompensationNameModal;

const Row = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
`;
