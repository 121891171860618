/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeCompensationStatus
// ====================================================

export interface ChangeCompensationStatus_changeCompensationStatus {
  __typename: "SubjectVisitCompensation";
  id: number;
  status: CompensationStatus;
}

export interface ChangeCompensationStatus {
  changeCompensationStatus: ChangeCompensationStatus_changeCompensationStatus | null;
}

export interface ChangeCompensationStatusVariables {
  input: ChangeCompensationStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeSubjectStatus
// ====================================================

export interface ChangeSubjectStatus_changeSubjectStatus {
  __typename: "Subject";
  id: number;
  status: SubjectStatus;
}

export interface ChangeSubjectStatus {
  changeSubjectStatus: ChangeSubjectStatus_changeSubjectStatus;
}

export interface ChangeSubjectStatusVariables {
  subjectId: number;
  studyId: number;
  status: SubjectStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EnterVisitDate
// ====================================================

export interface EnterVisitDate_enterVisitDate_subjectVisit {
  __typename: "SubjectVisit";
  id: number;
  date: string | null;
}

export interface EnterVisitDate_enterVisitDate_subject {
  __typename: "Subject";
  id: number;
  status: SubjectStatus;
}

export interface EnterVisitDate_enterVisitDate {
  __typename: "EnterVisitDatePayload";
  subjectVisit: EnterVisitDate_enterVisitDate_subjectVisit;
  subject: EnterVisitDate_enterVisitDate_subject;
}

export interface EnterVisitDate {
  enterVisitDate: EnterVisitDate_enterVisitDate;
}

export interface EnterVisitDateVariables {
  subjectVisitId: number;
  date: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddSubjectvisitCompensation
// ====================================================

export interface AddSubjectvisitCompensation_addSubjectVisitCompensation {
  __typename: "SubjectVisitCompensation";
  id: number;
  price: number | null;
  status: CompensationStatus;
}

export interface AddSubjectvisitCompensation {
  addSubjectVisitCompensation: AddSubjectvisitCompensation_addSubjectVisitCompensation;
}

export interface AddSubjectvisitCompensationVariables {
  visitId: number;
  compensationId: number;
  studyId: number;
  subjectVisitId: number;
  price?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FinishCycle
// ====================================================

export interface FinishCycle {
  finishCycle: boolean;
}

export interface FinishCycleVariables {
  subjectId: number;
  studyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RepeatCycle
// ====================================================

export interface RepeatCycle {
  repeatCycle: boolean;
}

export interface RepeatCycleVariables {
  subjectId: number;
  studyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignFlowChartToCentrum
// ====================================================

export interface AssignFlowChartToCentrum {
  assignFlowChartToCentrum: number;
}

export interface AssignFlowChartToCentrumVariables {
  flowChartId: number;
  studyId: number;
  centrumId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddVisitCompensation
// ====================================================

export interface AddVisitCompensation_addVisitCompensation {
  __typename: "VisitCompensation";
  id: number;
}

export interface AddVisitCompensation {
  addVisitCompensation: AddVisitCompensation_addVisitCompensation;
}

export interface AddVisitCompensationVariables {
  visitId: number;
  studyId: number;
  compensationId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveVisitCompensation
// ====================================================

export interface RemoveVisitCompensation {
  removeVisitCompensation: number;
}

export interface RemoveVisitCompensationVariables {
  visitId: number;
  studyId: number;
  visitCompensationId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addVisit
// ====================================================

export interface addVisit_addVisit_visitCompensations_compensation {
  __typename: "Compensation";
  id: number;
  name: string;
  deletedAt: string | null;
}

export interface addVisit_addVisit_visitCompensations {
  __typename: "VisitCompensation";
  id: number;
  compensation: addVisit_addVisit_visitCompensations_compensation | null;
}

export interface addVisit_addVisit_flowChart {
  __typename: "FlowChart";
  id: number;
  cycleStartsAt: number | null;
  cycleEndsAt: number | null;
}

export interface addVisit_addVisit {
  __typename: "Visit";
  id: number;
  name: string | null;
  orderNumber: number | null;
  visitCompensations: addVisit_addVisit_visitCompensations[];
  flowChart: addVisit_addVisit_flowChart | null;
  type: VisitType;
}

export interface addVisit {
  addVisit: addVisit_addVisit;
}

export interface addVisitVariables {
  input: AddVisitInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddSubjectVisit
// ====================================================

export interface AddSubjectVisit_addSubjectVisit_payment {
  __typename: "Payment";
  status: string;
  id: number;
  isPostalOrder: boolean;
  hasPostalForm: boolean;
}

export interface AddSubjectVisit_addSubjectVisit_visit_flowChart {
  __typename: "FlowChart";
  id: number;
  version: number;
  versionDate: string;
  allowExpenses: boolean | null;
  cycleEndsAt: number | null;
  cycleStartsAt: number | null;
}

export interface AddSubjectVisit_addSubjectVisit_visit {
  __typename: "Visit";
  flowChart: AddSubjectVisit_addSubjectVisit_visit_flowChart | null;
  id: number;
  flowChartId: number | null;
  orderNumber: number | null;
  name: string | null;
}

export interface AddSubjectVisit_addSubjectVisit_subjectVisitCompensations_compensation {
  __typename: "Compensation";
  id: number;
  name: string;
  price: number | null;
  isExpense: boolean | null;
}

export interface AddSubjectVisit_addSubjectVisit_subjectVisitCompensations {
  __typename: "SubjectVisitCompensation";
  id: number;
  status: CompensationStatus;
  price: number | null;
  compensation: AddSubjectVisit_addSubjectVisit_subjectVisitCompensations_compensation | null;
}

export interface AddSubjectVisit_addSubjectVisit {
  __typename: "SubjectVisit";
  id: number;
  date: string | null;
  orderNumber: number;
  payment: AddSubjectVisit_addSubjectVisit_payment | null;
  visit: AddSubjectVisit_addSubjectVisit_visit;
  subjectVisitCompensations: AddSubjectVisit_addSubjectVisit_subjectVisitCompensations[];
}

export interface AddSubjectVisit {
  addSubjectVisit: AddSubjectVisit_addSubjectVisit;
}

export interface AddSubjectVisitVariables {
  input: AddVisitInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditSubjectMutation
// ====================================================

export interface EditSubjectMutation_editSubject {
  __typename: "Subject";
  id: number;
  bankAccount: string | null;
}

export interface EditSubjectMutation {
  editSubject: EditSubjectMutation_editSubject | null;
}

export interface EditSubjectMutationVariables {
  input: EditSubjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeVisit
// ====================================================

export interface removeVisit {
  removeVisit: number;
}

export interface removeVisitVariables {
  visitId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changeVisitName
// ====================================================

export interface changeVisitName {
  changeVisitName: number;
}

export interface changeVisitNameVariables {
  visitId: number;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCycle
// ====================================================

export interface updateCycle {
  updateCycle: number;
}

export interface updateCycleVariables {
  startsAt: number;
  endsAt: number;
  flowChartId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeCycle
// ====================================================

export interface removeCycle {
  removeCycle: number;
}

export interface removeCycleVariables {
  flowChartId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changeVisitType
// ====================================================

export interface changeVisitType {
  changeVisitType: number;
}

export interface changeVisitTypeVariables {
  visitId: number;
  type: VisitType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addFlowChart
// ====================================================

export interface addFlowChart {
  addFlowChart: number;
}

export interface addFlowChartVariables {
  studyId: number;
  version: number;
  versionDate: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addSubject
// ====================================================

export interface addSubject {
  addSubject: number;
}

export interface addSubjectVariables {
  subjectInput: SubjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addCentrumToStudy
// ====================================================

export interface addCentrumToStudy {
  addCentrumToStudy: number;
}

export interface addCentrumToStudyVariables {
  studyId: number;
  centrumId: number;
  centrumCode: string;
  flowChartId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addCompensation
// ====================================================

export interface addCompensation_addCompensation {
  __typename: "Compensation";
  id: number;
  name: string;
  price: number | null;
  currency: string;
  isExpense: boolean | null;
  deletedAt: string | null;
}

export interface addCompensation {
  addCompensation: addCompensation_addCompensation;
}

export interface addCompensationVariables {
  studyId: number;
  name: string;
  price?: number | null;
  isExpense: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetStudyContact
// ====================================================

export interface SetStudyContact_setStudyContact {
  __typename: "Contact";
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  clientId: number | null;
}

export interface SetStudyContact {
  setStudyContact: SetStudyContact_setStudyContact;
}

export interface SetStudyContactVariables {
  input: SetStudyContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveStudyContact
// ====================================================

export interface RemoveStudyContact {
  removeStudyContact: number;
}

export interface RemoveStudyContactVariables {
  contactId: number;
  studyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCenter
// ====================================================

export interface updateCenter {
  updateCenter: number;
}

export interface updateCenterVariables {
  centerInput: CenterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateClient
// ====================================================

export interface updateClient {
  updateClient: number;
}

export interface updateClientVariables {
  clientInput: ClientInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addStudy
// ====================================================

export interface addStudy_addStudy {
  __typename: "Study";
  id: number;
}

export interface addStudy {
  addStudy: addStudy_addStudy;
}

export interface addStudyVariables {
  input: StudyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadPostalFile
// ====================================================

export interface uploadPostalFile_uploadPostalFile {
  __typename: "Payment";
  id: number;
  hasPostalForm: boolean;
}

export interface uploadPostalFile {
  uploadPostalFile: uploadPostalFile_uploadPostalFile | null;
}

export interface uploadPostalFileVariables {
  input: PostalFileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePayment
// ====================================================

export interface updatePayment_updatePayment {
  __typename: "Payment";
  id: number;
  status: string;
}

export interface updatePayment {
  updatePayment: updatePayment_updatePayment;
}

export interface updatePaymentVariables {
  paymentId: number;
  studyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addUserToStudy
// ====================================================

export interface addUserToStudy {
  addUserToStudy: number;
}

export interface addUserToStudyVariables {
  userId: number;
  centrumId: number;
  studyId: number;
  studyRole: StudyRole;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendResetPasswordEmail
// ====================================================

export interface SendResetPasswordEmail {
  sendResetPasswordEmail: boolean;
}

export interface SendResetPasswordEmailVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddMoneyToBudget
// ====================================================

export interface AddMoneyToBudget_addMoneyToBudget_incomingBudget_contact {
  __typename: "Contact";
  id: number;
  firstName: string;
  lastName: string;
}

export interface AddMoneyToBudget_addMoneyToBudget_incomingBudget {
  __typename: "IncomingBudget";
  price: number;
  id: number;
  date: string;
  contact: AddMoneyToBudget_addMoneyToBudget_incomingBudget_contact | null;
}

export interface AddMoneyToBudget_addMoneyToBudget {
  __typename: "Study";
  id: number;
  actualBudget: number;
  incomingBudget: AddMoneyToBudget_addMoneyToBudget_incomingBudget[] | null;
}

export interface AddMoneyToBudget {
  addMoneyToBudget: AddMoneyToBudget_addMoneyToBudget;
}

export interface AddMoneyToBudgetVariables {
  input: AddMoneyToBudgetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableExpenses
// ====================================================

export interface enableExpenses_enableExpenses {
  __typename: "FlowChart";
  id: number;
  allowExpenses: boolean | null;
}

export interface enableExpenses {
  enableExpenses: enableExpenses_enableExpenses;
}

export interface enableExpensesVariables {
  flowChartId: number;
  isEnable: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: login
// ====================================================

export interface login {
  login: string;
}

export interface loginVariables {
  nickname: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifyUser
// ====================================================

export interface verifyUser {
  verifyUser: number;
}

export interface verifyUserVariables {
  userId: number;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateUser
// ====================================================

export interface updateUser_updateUser_contact {
  __typename: "Contact";
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface updateUser_updateUser {
  __typename: "User";
  id: number;
  username: string;
  contact: updateUser_updateUser_contact;
}

export interface updateUser {
  updateUser: updateUser_updateUser;
}

export interface updateUserVariables {
  input: UserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateExpensePrice
// ====================================================

export interface updateExpensePrice_updateExpensePrice {
  __typename: "SubjectVisitCompensation";
  id: number;
  price: number | null;
}

export interface updateExpensePrice {
  updateExpensePrice: updateExpensePrice_updateExpensePrice | null;
}

export interface updateExpensePriceVariables {
  input: UpdateExpensePriceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: synchronizePayments
// ====================================================

export interface synchronizePayments_synchronizePayments {
  __typename: "Payment";
  id: number;
}

export interface synchronizePayments {
  synchronizePayments: synchronizePayments_synchronizePayments[];
}

export interface synchronizePaymentsVariables {
  studyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: makePayments
// ====================================================

export interface makePayments_makePayments_payments {
  __typename: "Payment";
  id: number;
}

export interface makePayments_makePayments {
  __typename: "MakePaymentsPayload";
  payments: makePayments_makePayments_payments[];
  transactionId: string | null;
}

export interface makePayments {
  makePayments: makePayments_makePayments;
}

export interface makePaymentsVariables {
  studyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveStudyMutation
// ====================================================

export interface ArchiveStudyMutation {
  archiveStudy: number;
}

export interface ArchiveStudyMutationVariables {
  studyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserFromStudy
// ====================================================

export interface RemoveUserFromStudy {
  removeUserFromStudy: boolean;
}

export interface RemoveUserFromStudyVariables {
  studyId: number;
  userId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStudyCompensationState
// ====================================================

export interface UpdateStudyCompensationState_updateStudyCompensationState {
  __typename: "Compensation";
  id: number;
  deletedAt: string | null;
}

export interface UpdateStudyCompensationState {
  updateStudyCompensationState: UpdateStudyCompensationState_updateStudyCompensationState;
}

export interface UpdateStudyCompensationStateVariables {
  compensationId: number;
  isActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCompensationName
// ====================================================

export interface UpdateCompensationName_updateCompensationName {
  __typename: "Compensation";
  id: number;
  name: string;
}

export interface UpdateCompensationName {
  updateCompensationName: UpdateCompensationName_updateCompensationName;
}

export interface UpdateCompensationNameVariables {
  compensationId: number;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePaymentStatus
// ====================================================

export interface UpdatePaymentStatus_updatePaymentStatus {
  __typename: "Payment";
  id: number;
  status: string;
}

export interface UpdatePaymentStatus {
  updatePaymentStatus: UpdatePaymentStatus_updatePaymentStatus;
}

export interface UpdatePaymentStatusVariables {
  paymentId: number;
  status?: PaymentStatus | null;
  studyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveFlowChart
// ====================================================

export interface RemoveFlowChart_removeFlowChart {
  __typename: "FlowChart";
  id: number;
}

export interface RemoveFlowChart {
  removeFlowChart: RemoveFlowChart_removeFlowChart;
}

export interface RemoveFlowChartVariables {
  flowChartId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveSubject
// ====================================================

export interface RemoveSubject {
  removeSubject: number;
}

export interface RemoveSubjectVariables {
  subjectId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Studies
// ====================================================

export interface Studies_studies {
  __typename: "Study";
  id: number;
  code: string;
}

export interface Studies {
  studies: Studies_studies[];
}

export interface StudiesVariables {
  centrumId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectorStudies
// ====================================================

export interface SelectorStudies_studies {
  __typename: "Study";
  id: number;
  code: string;
}

export interface SelectorStudies {
  studies: SelectorStudies_studies[];
}

export interface SelectorStudiesVariables {
  centrumId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StudyCompensations
// ====================================================

export interface StudyCompensations_studyCompensations {
  __typename: "Compensation";
  id: number;
  name: string;
  price: number | null;
  isExpense: boolean | null;
  deletedAt: string | null;
}

export interface StudyCompensations {
  studyCompensations: StudyCompensations_studyCompensations[];
}

export interface StudyCompensationsVariables {
  studyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CentrumDataQuery
// ====================================================

export interface CentrumDataQuery_centrums {
  __typename: "Centrum";
  id: number;
  name: string;
  code: string | null;
}

export interface CentrumDataQuery {
  centrums: CentrumDataQuery_centrums[];
}

export interface CentrumDataQueryVariables {
  centrumId?: number | null;
  studyId?: number | null;
  takeAll?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StudyCodeQuery
// ====================================================

export interface StudyCodeQuery_study {
  __typename: "Study";
  id: number;
  code: string;
}

export interface StudyCodeQuery {
  study: StudyCodeQuery_study;
}

export interface StudyCodeQueryVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectorSubjectsQuery
// ====================================================

export interface SelectorSubjectsQuery_subjects {
  __typename: "Subject";
  id: number;
  code: string;
}

export interface SelectorSubjectsQuery {
  subjects: SelectorSubjectsQuery_subjects[];
}

export interface SelectorSubjectsQueryVariables {
  studyId?: number | null;
  siteNumber?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteNumbersSelector
// ====================================================

export interface SiteNumbersSelector_studies {
  __typename: "Study";
  id: number;
  siteNumbers: string[] | null;
}

export interface SiteNumbersSelector {
  studies: SiteNumbersSelector_studies[];
}

export interface SiteNumbersSelectorVariables {
  studyId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Study
// ====================================================

export interface Study_studies_client {
  __typename: "Client";
  name: string;
}

export interface Study_studies_subjects_deniedPayments {
  __typename: "Payment";
  id: number;
  errorMessage: string | null;
  status: string;
}

export interface Study_studies_subjects_lastVisit {
  __typename: "LastVisit";
  date: string;
}

export interface Study_studies_subjects_delayedSubjectCompensations {
  __typename: "SubjectVisitCompensation";
  id: number;
}

export interface Study_studies_subjects_unresolvedSubjectVisitWithPostalOrder {
  __typename: "SubjectVisit";
  id: number;
}

export interface Study_studies_subjects {
  __typename: "Subject";
  id: number;
  deniedPayments: Study_studies_subjects_deniedPayments[];
  code: string;
  bankAccount: string | null;
  lastVisit: Study_studies_subjects_lastVisit | null;
  delayedSubjectCompensations: Study_studies_subjects_delayedSubjectCompensations[];
  unresolvedSubjectVisitWithPostalOrder: Study_studies_subjects_unresolvedSubjectVisitWithPostalOrder[];
  status: SubjectStatus;
}

export interface Study_studies {
  __typename: "Study";
  id: number;
  eudraCtNumber: string;
  clientId: number;
  protocolNumber: string;
  code: string;
  sponsor: string | null;
  client: Study_studies_client;
  subjects: Study_studies_subjects[];
}

export interface Study {
  studies: Study_studies[];
}

export interface StudyVariables {
  studyId?: number | null;
  centrumId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StudyContacts
// ====================================================

export interface StudyContacts_contacts {
  __typename: "Contact";
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface StudyContacts {
  contacts: StudyContacts_contacts[];
}

export interface StudyContactsVariables {
  type: string;
  clientId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UnresolvedSubjectVisit
// ====================================================

export interface UnresolvedSubjectVisit_unresolvedSubjectVisit_unresolvedSubjectVisitWithPostalOrder_subjectVisitCompensations_compensation {
  __typename: "Compensation";
  id: number;
  isExpense: boolean | null;
}

export interface UnresolvedSubjectVisit_unresolvedSubjectVisit_unresolvedSubjectVisitWithPostalOrder_subjectVisitCompensations {
  __typename: "SubjectVisitCompensation";
  status: CompensationStatus;
  id: number;
  compensation: UnresolvedSubjectVisit_unresolvedSubjectVisit_unresolvedSubjectVisitWithPostalOrder_subjectVisitCompensations_compensation | null;
}

export interface UnresolvedSubjectVisit_unresolvedSubjectVisit_unresolvedSubjectVisitWithPostalOrder {
  __typename: "SubjectVisit";
  id: number;
  subjectVisitCompensations: UnresolvedSubjectVisit_unresolvedSubjectVisit_unresolvedSubjectVisitWithPostalOrder_subjectVisitCompensations[];
}

export interface UnresolvedSubjectVisit_unresolvedSubjectVisit_delayedSubjectCompensations_compensation {
  __typename: "Compensation";
  id: number;
  name: string;
}

export interface UnresolvedSubjectVisit_unresolvedSubjectVisit_delayedSubjectCompensations {
  __typename: "SubjectVisitCompensation";
  subjectVisitId: number;
  id: number;
  compensation: UnresolvedSubjectVisit_unresolvedSubjectVisit_delayedSubjectCompensations_compensation | null;
}

export interface UnresolvedSubjectVisit_unresolvedSubjectVisit {
  __typename: "UnresolvedSubjectVisits";
  unresolvedSubjectVisitWithPostalOrder: UnresolvedSubjectVisit_unresolvedSubjectVisit_unresolvedSubjectVisitWithPostalOrder[];
  delayedSubjectCompensations: UnresolvedSubjectVisit_unresolvedSubjectVisit_delayedSubjectCompensations[];
}

export interface UnresolvedSubjectVisit {
  unresolvedSubjectVisit: UnresolvedSubjectVisit_unresolvedSubjectVisit;
}

export interface UnresolvedSubjectVisitVariables {
  subjectId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StudySubject
// ====================================================

export interface StudySubject_study_client {
  __typename: "Client";
  name: string;
}

export interface StudySubject_study_subjects_lastVisit {
  __typename: "LastVisit";
  date: string;
  orderNumber: number;
}

export interface StudySubject_study_subjects_subjectVisits_payment {
  __typename: "Payment";
  status: string;
  id: number;
  isPostalOrder: boolean;
  hasPostalForm: boolean;
}

export interface StudySubject_study_subjects_subjectVisits_visit_flowChart {
  __typename: "FlowChart";
  id: number;
  version: number;
  versionDate: string;
  allowExpenses: boolean | null;
  cycleEndsAt: number | null;
  cycleStartsAt: number | null;
}

export interface StudySubject_study_subjects_subjectVisits_visit {
  __typename: "Visit";
  flowChart: StudySubject_study_subjects_subjectVisits_visit_flowChart | null;
  id: number;
  flowChartId: number | null;
  orderNumber: number | null;
  name: string | null;
}

export interface StudySubject_study_subjects_subjectVisits_subjectVisitCompensations_compensation {
  __typename: "Compensation";
  id: number;
  name: string;
  price: number | null;
  isExpense: boolean | null;
}

export interface StudySubject_study_subjects_subjectVisits_subjectVisitCompensations {
  __typename: "SubjectVisitCompensation";
  id: number;
  status: CompensationStatus;
  price: number | null;
  compensation: StudySubject_study_subjects_subjectVisits_subjectVisitCompensations_compensation | null;
}

export interface StudySubject_study_subjects_subjectVisits {
  __typename: "SubjectVisit";
  id: number;
  date: string | null;
  orderNumber: number;
  payment: StudySubject_study_subjects_subjectVisits_payment | null;
  visit: StudySubject_study_subjects_subjectVisits_visit;
  subjectVisitCompensations: StudySubject_study_subjects_subjectVisits_subjectVisitCompensations[];
}

export interface StudySubject_study_subjects {
  __typename: "Subject";
  id: number;
  code: string;
  bankAccount: string | null;
  lastVisit: StudySubject_study_subjects_lastVisit | null;
  status: SubjectStatus;
  subjectVisits: StudySubject_study_subjects_subjectVisits[];
}

export interface StudySubject_study {
  __typename: "Study";
  id: number;
  eudraCtNumber: string;
  clientId: number;
  code: string;
  sponsor: string | null;
  protocolNumber: string;
  client: StudySubject_study_client;
  subjects: StudySubject_study_subjects[];
}

export interface StudySubject {
  study: StudySubject_study;
}

export interface StudySubjectVariables {
  studyId: number;
  subjectId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: subjects
// ====================================================

export interface subjects_subjects {
  __typename: "Subject";
  id: number;
  code: string;
}

export interface subjects {
  subjects: subjects_subjects[];
}

export interface subjectsVariables {
  studyId: number;
  centrumId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BudgetGraph
// ====================================================

export interface BudgetGraph_paymentInfo {
  __typename: "PaymentInfo";
  totalPayed: number;
  totalPlanned: number;
  totalApproved: number;
}

export interface BudgetGraph {
  paymentInfo: BudgetGraph_paymentInfo;
}

export interface BudgetGraphVariables {
  studyId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Payments
// ====================================================

export interface Payments_paymentInfo_paymentData_payments_subjectVisit_subject {
  __typename: "Subject";
  id: number;
  bankAccount: string | null;
}

export interface Payments_paymentInfo_paymentData_payments_subjectVisit_subjectVisitCompensations_compensation {
  __typename: "Compensation";
  id: number;
  name: string;
  currency: string;
  price: number | null;
  isExpense: boolean | null;
}

export interface Payments_paymentInfo_paymentData_payments_subjectVisit_subjectVisitCompensations {
  __typename: "SubjectVisitCompensation";
  id: number;
  visitCompensationId: number;
  status: CompensationStatus;
  updatedAt: string | null;
  createdAt: string;
  price: number | null;
  compensation: Payments_paymentInfo_paymentData_payments_subjectVisit_subjectVisitCompensations_compensation | null;
}

export interface Payments_paymentInfo_paymentData_payments_subjectVisit_studyCentrum {
  __typename: "StudyCentrum";
  code: string;
  centrumId: number;
  studyId: number;
}

export interface Payments_paymentInfo_paymentData_payments_subjectVisit_visit_study {
  __typename: "Study";
  code: string;
  id: number;
}

export interface Payments_paymentInfo_paymentData_payments_subjectVisit_visit {
  __typename: "Visit";
  study: Payments_paymentInfo_paymentData_payments_subjectVisit_visit_study;
  name: string | null;
  orderNumber: number | null;
}

export interface Payments_paymentInfo_paymentData_payments_subjectVisit {
  __typename: "SubjectVisit";
  subject: Payments_paymentInfo_paymentData_payments_subjectVisit_subject;
  date: string | null;
  subjectVisitCompensations: Payments_paymentInfo_paymentData_payments_subjectVisit_subjectVisitCompensations[];
  subjectId: number;
  subjectCode: string;
  orderNumber: number;
  studyCentrum: Payments_paymentInfo_paymentData_payments_subjectVisit_studyCentrum;
  visit: Payments_paymentInfo_paymentData_payments_subjectVisit_visit;
}

export interface Payments_paymentInfo_paymentData_payments {
  __typename: "Payment";
  price: number;
  id: number;
  createdAt: string;
  errorMessage: string | null;
  isPostalOrder: boolean;
  hasPostalForm: boolean;
  variableSymbol: string;
  status: string;
  subjectVisit: Payments_paymentInfo_paymentData_payments_subjectVisit;
}

export interface Payments_paymentInfo_paymentData {
  __typename: "PaymentData";
  payments: Payments_paymentInfo_paymentData_payments[];
  totalCount: number;
}

export interface Payments_paymentInfo {
  __typename: "PaymentInfo";
  paymentData: Payments_paymentInfo_paymentData;
  totalPayed: number;
  totalPlanned: number;
  totalDenied: number;
  totalApproved: number;
}

export interface Payments {
  paymentInfo: Payments_paymentInfo;
}

export interface PaymentsVariables {
  studyId?: number | null;
  type?: string | null;
  siteNumber?: string | null;
  subjectId?: number | null;
  page: number;
  count: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StudyDetail
// ====================================================

export interface StudyDetail_study_incomingBudget_contact {
  __typename: "Contact";
  firstName: string;
  lastName: string;
}

export interface StudyDetail_study_incomingBudget {
  __typename: "IncomingBudget";
  date: string;
  price: number;
  contact: StudyDetail_study_incomingBudget_contact | null;
}

export interface StudyDetail_study_contacts {
  __typename: "Contact";
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  clientId: number | null;
}

export interface StudyDetail_study_compensations {
  __typename: "Compensation";
  id: number;
  name: string;
  price: number | null;
  currency: string;
  deletedAt: string | null;
  isExpense: boolean | null;
}

export interface StudyDetail_study_entered {
  __typename: "entered";
  earlyTerminated: number;
  completed: number;
  ongoing: number;
}

export interface StudyDetail_study_enrolled {
  __typename: "enrolled";
  screenFailure: number;
  inScreening: number;
}

export interface StudyDetail_study_flowCharts_visits_visitCompensations_compensation {
  __typename: "Compensation";
  id: number;
  name: string;
  deletedAt: string | null;
}

export interface StudyDetail_study_flowCharts_visits_visitCompensations {
  __typename: "VisitCompensation";
  id: number;
  compensation: StudyDetail_study_flowCharts_visits_visitCompensations_compensation | null;
}

export interface StudyDetail_study_flowCharts_visits_flowChart {
  __typename: "FlowChart";
  id: number;
  cycleStartsAt: number | null;
  cycleEndsAt: number | null;
}

export interface StudyDetail_study_flowCharts_visits {
  __typename: "Visit";
  id: number;
  name: string | null;
  orderNumber: number | null;
  visitCompensations: StudyDetail_study_flowCharts_visits_visitCompensations[];
  flowChart: StudyDetail_study_flowCharts_visits_flowChart | null;
  type: VisitType;
}

export interface StudyDetail_study_flowCharts {
  __typename: "FlowChart";
  id: number;
  version: number;
  isDeletable: boolean;
  isLocked: boolean;
  versionDate: string;
  allowExpenses: boolean | null;
  createdAt: string;
  cycleStartsAt: number | null;
  cycleEndsAt: number | null;
  visits: StudyDetail_study_flowCharts_visits[];
}

export interface StudyDetail_study_centrums_flowChart {
  __typename: "FlowChart";
  id: number;
}

export interface StudyDetail_study_centrums {
  __typename: "Centrum";
  id: number;
  name: string;
  code: string | null;
  flowChart: StudyDetail_study_centrums_flowChart;
}

export interface StudyDetail_study_users_userStudyCentrum {
  __typename: "UserStudyCentrum";
  userId: number;
  studyId: number;
  centrumId: number;
  studyRole: StudyRole;
}

export interface StudyDetail_study_users {
  __typename: "User";
  id: number;
  username: string;
  userStudyCentrum: StudyDetail_study_users_userStudyCentrum;
  centrumId: number | null;
}

export interface StudyDetail_study {
  __typename: "Study";
  id: number;
  code: string;
  eudraCtNumber: string;
  clientId: number;
  plannedVisitorsAmout: number;
  protocolNumber: string;
  incomingBudget: StudyDetail_study_incomingBudget[] | null;
  contacts: StudyDetail_study_contacts[];
  actualBudget: number;
  sponsor: string | null;
  plannendBudget: number;
  compensations: StudyDetail_study_compensations[];
  entered: StudyDetail_study_entered;
  enrolled: StudyDetail_study_enrolled;
  flowCharts: StudyDetail_study_flowCharts[];
  centrums: StudyDetail_study_centrums[];
  users: StudyDetail_study_users[];
}

export interface StudyDetail {
  study: StudyDetail_study;
}

export interface StudyDetailVariables {
  studyId: number;
  centrumId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: detailCentrums
// ====================================================

export interface detailCentrums_centrums_users_contact {
  __typename: "Contact";
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface detailCentrums_centrums_users {
  __typename: "User";
  id: number;
  username: string;
  contact: detailCentrums_centrums_users_contact;
}

export interface detailCentrums_centrums_subjects {
  __typename: "Subject";
  id: number;
  code: string;
  bankAccount: string | null;
}

export interface detailCentrums_centrums_studies {
  __typename: "Study";
  id: number;
  code: string;
  siteNumbers: string[] | null;
}

export interface detailCentrums_centrums {
  __typename: "Centrum";
  id: number;
  codes: string[];
  name: string;
  users: detailCentrums_centrums_users[];
  subjects: detailCentrums_centrums_subjects[];
  studies: detailCentrums_centrums_studies[];
}

export interface detailCentrums {
  centrums: detailCentrums_centrums[];
}

export interface detailCentrumsVariables {
  centrumId?: number | null;
  studyId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: compensations
// ====================================================

export interface compensations_compensations {
  __typename: "Compensation";
  id: number;
  name: string;
}

export interface compensations {
  compensations: compensations_compensations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: user
// ====================================================

export interface user_user_contact {
  __typename: "Contact";
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface user_user {
  __typename: "User";
  id: number;
  username: string;
  role: UserRole;
  contact: user_user_contact;
}

export interface user {
  user: user_user;
}

export interface userVariables {
  userId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EditSubjectQuery
// ====================================================

export interface EditSubjectQuery_subjects {
  __typename: "Subject";
  id: number;
  bankAccount: string | null;
  centrumId: number;
  code: string;
  gdprAgreement: boolean;
  notStarted: boolean;
  informationAgreement: boolean;
  status: SubjectStatus;
}

export interface EditSubjectQuery {
  subjects: EditSubjectQuery_subjects[];
}

export interface EditSubjectQueryVariables {
  studyId: number;
  subjectId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: centrumUsers
// ====================================================

export interface centrumUsers_centrumUsers {
  __typename: "User";
  username: string;
  id: number;
}

export interface centrumUsers {
  centrumUsers: centrumUsers_centrumUsers[];
}

export interface centrumUsersVariables {
  centrumId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: me
// ====================================================

export interface me_me_contact {
  __typename: "Contact";
  clientId: number | null;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

export interface me_me {
  __typename: "User";
  id: number;
  username: string;
  contact: me_me_contact;
  role: UserRole;
  passwordExpiration: string;
  centrumId: number | null;
}

export interface me {
  me: me_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userByToken
// ====================================================

export interface userByToken_userByToken {
  __typename: "User";
  id: number;
  username: string;
}

export interface userByToken {
  userByToken: userByToken_userByToken;
}

export interface userByTokenVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: centrumUser
// ====================================================

export interface centrumUser_centrumUser_contact {
  __typename: "Contact";
  email: string;
}

export interface centrumUser_centrumUser {
  __typename: "User";
  id: number;
  username: string;
  contact: centrumUser_centrumUser_contact;
}

export interface centrumUser {
  centrumUser: centrumUser_centrumUser | null;
}

export interface centrumUserVariables {
  username: string;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientsQuery
// ====================================================

export interface ClientsQuery_clients {
  __typename: "Client";
  id: number;
  name: string;
}

export interface ClientsQuery {
  clients: ClientsQuery_clients[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DetailClientsQuery
// ====================================================

export interface DetailClientsQuery_clients_contacts_user {
  __typename: "User";
  username: string;
}

export interface DetailClientsQuery_clients_contacts {
  __typename: "Contact";
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  user: DetailClientsQuery_clients_contacts_user | null;
}

export interface DetailClientsQuery_clients {
  __typename: "Client";
  id: number;
  name: string;
  note: string | null;
  contacts: DetailClientsQuery_clients_contacts[] | null;
}

export interface DetailClientsQuery {
  clients: DetailClientsQuery_clients[];
}

export interface DetailClientsQueryVariables {
  clientId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: approvedPayments
// ====================================================

export interface approvedPayments_approvedPayments_subjectVisit_visit_study {
  __typename: "Study";
  code: string;
  id: number;
}

export interface approvedPayments_approvedPayments_subjectVisit_visit {
  __typename: "Visit";
  study: approvedPayments_approvedPayments_subjectVisit_visit_study;
  name: string | null;
  orderNumber: number | null;
}

export interface approvedPayments_approvedPayments_subjectVisit {
  __typename: "SubjectVisit";
  subjectCode: string;
  subjectId: number;
  orderNumber: number;
  visit: approvedPayments_approvedPayments_subjectVisit_visit;
}

export interface approvedPayments_approvedPayments {
  __typename: "Payment";
  price: number;
  id: number;
  sentAt: string | null;
  status: string;
  errorMessage: string | null;
  variableSymbol: string;
  subjectVisit: approvedPayments_approvedPayments_subjectVisit;
}

export interface approvedPayments {
  approvedPayments: approvedPayments_approvedPayments[];
}

export interface approvedPaymentsVariables {
  studyId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: selectedPayments
// ====================================================

export interface selectedPayments_selectedPayments_subjectVisit_visit_study {
  __typename: "Study";
  code: string;
  id: number;
}

export interface selectedPayments_selectedPayments_subjectVisit_visit {
  __typename: "Visit";
  study: selectedPayments_selectedPayments_subjectVisit_visit_study;
  name: string | null;
  orderNumber: number | null;
}

export interface selectedPayments_selectedPayments_subjectVisit {
  __typename: "SubjectVisit";
  subjectCode: string;
  subjectId: number;
  orderNumber: number;
  visit: selectedPayments_selectedPayments_subjectVisit_visit;
}

export interface selectedPayments_selectedPayments {
  __typename: "Payment";
  price: number;
  id: number;
  sentAt: string | null;
  errorMessage: string | null;
  variableSymbol: string;
  status: string;
  subjectVisit: selectedPayments_selectedPayments_subjectVisit;
}

export interface selectedPayments {
  selectedPayments: selectedPayments_selectedPayments[];
}

export interface selectedPaymentsVariables {
  ids: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SubjectVisitLogsQuery
// ====================================================

export interface SubjectVisitLogsQuery_subjectVisitLogs_user {
  __typename: "User";
  username: string;
}

export interface SubjectVisitLogsQuery_subjectVisitLogs {
  __typename: "Log";
  id: number;
  message: string;
  createdAt: string;
  user: SubjectVisitLogsQuery_subjectVisitLogs_user;
}

export interface SubjectVisitLogsQuery {
  subjectVisitLogs: SubjectVisitLogsQuery_subjectVisitLogs[];
}

export interface SubjectVisitLogsQueryVariables {
  subjectVisitId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UsersQuery
// ====================================================

export interface UsersQuery_users_contact {
  __typename: "Contact";
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface UsersQuery_users_centrum {
  __typename: "Centrum";
  id: number;
  name: string;
}

export interface UsersQuery_users_studies {
  __typename: "Study";
  id: number;
  code: string;
}

export interface UsersQuery_users {
  __typename: "User";
  id: number;
  role: UserRole;
  contact: UsersQuery_users_contact;
  username: string;
  centrum: UsersQuery_users_centrum | null;
  studies: UsersQuery_users_studies[];
}

export interface UsersQuery {
  users: UsersQuery_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StudySubjectVisit
// ====================================================

export interface StudySubjectVisit_payment {
  __typename: "Payment";
  status: string;
  id: number;
  isPostalOrder: boolean;
  hasPostalForm: boolean;
}

export interface StudySubjectVisit_visit_flowChart {
  __typename: "FlowChart";
  id: number;
  version: number;
  versionDate: string;
  allowExpenses: boolean | null;
  cycleEndsAt: number | null;
  cycleStartsAt: number | null;
}

export interface StudySubjectVisit_visit {
  __typename: "Visit";
  flowChart: StudySubjectVisit_visit_flowChart | null;
  id: number;
  flowChartId: number | null;
  orderNumber: number | null;
  name: string | null;
}

export interface StudySubjectVisit_subjectVisitCompensations_compensation {
  __typename: "Compensation";
  id: number;
  name: string;
  price: number | null;
  isExpense: boolean | null;
}

export interface StudySubjectVisit_subjectVisitCompensations {
  __typename: "SubjectVisitCompensation";
  id: number;
  status: CompensationStatus;
  price: number | null;
  compensation: StudySubjectVisit_subjectVisitCompensations_compensation | null;
}

export interface StudySubjectVisit {
  __typename: "SubjectVisit";
  id: number;
  date: string | null;
  orderNumber: number;
  payment: StudySubjectVisit_payment | null;
  visit: StudySubjectVisit_visit;
  subjectVisitCompensations: StudySubjectVisit_subjectVisitCompensations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StudySubject
// ====================================================

export interface StudySubject_lastVisit {
  __typename: "LastVisit";
  date: string;
  orderNumber: number;
}

export interface StudySubject_subjectVisits_payment {
  __typename: "Payment";
  status: string;
  id: number;
  isPostalOrder: boolean;
  hasPostalForm: boolean;
}

export interface StudySubject_subjectVisits_visit_flowChart {
  __typename: "FlowChart";
  id: number;
  version: number;
  versionDate: string;
  allowExpenses: boolean | null;
  cycleEndsAt: number | null;
  cycleStartsAt: number | null;
}

export interface StudySubject_subjectVisits_visit {
  __typename: "Visit";
  flowChart: StudySubject_subjectVisits_visit_flowChart | null;
  id: number;
  flowChartId: number | null;
  orderNumber: number | null;
  name: string | null;
}

export interface StudySubject_subjectVisits_subjectVisitCompensations_compensation {
  __typename: "Compensation";
  id: number;
  name: string;
  price: number | null;
  isExpense: boolean | null;
}

export interface StudySubject_subjectVisits_subjectVisitCompensations {
  __typename: "SubjectVisitCompensation";
  id: number;
  status: CompensationStatus;
  price: number | null;
  compensation: StudySubject_subjectVisits_subjectVisitCompensations_compensation | null;
}

export interface StudySubject_subjectVisits {
  __typename: "SubjectVisit";
  id: number;
  date: string | null;
  orderNumber: number;
  payment: StudySubject_subjectVisits_payment | null;
  visit: StudySubject_subjectVisits_visit;
  subjectVisitCompensations: StudySubject_subjectVisits_subjectVisitCompensations[];
}

export interface StudySubject {
  __typename: "Subject";
  id: number;
  code: string;
  bankAccount: string | null;
  lastVisit: StudySubject_lastVisit | null;
  status: SubjectStatus;
  subjectVisits: StudySubject_subjectVisits[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditStudyCompensation
// ====================================================

export interface EditStudyCompensation {
  __typename: "Compensation";
  id: number;
  name: string;
  deletedAt: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditStudyVisitCompensation
// ====================================================

export interface EditStudyVisitCompensation_compensation {
  __typename: "Compensation";
  id: number;
  name: string;
  deletedAt: string | null;
}

export interface EditStudyVisitCompensation {
  __typename: "VisitCompensation";
  id: number;
  compensation: EditStudyVisitCompensation_compensation | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditStudyVisit
// ====================================================

export interface EditStudyVisit_visitCompensations_compensation {
  __typename: "Compensation";
  id: number;
  name: string;
  deletedAt: string | null;
}

export interface EditStudyVisit_visitCompensations {
  __typename: "VisitCompensation";
  id: number;
  compensation: EditStudyVisit_visitCompensations_compensation | null;
}

export interface EditStudyVisit_flowChart {
  __typename: "FlowChart";
  id: number;
  cycleStartsAt: number | null;
  cycleEndsAt: number | null;
}

export interface EditStudyVisit {
  __typename: "Visit";
  id: number;
  name: string | null;
  orderNumber: number | null;
  visitCompensations: EditStudyVisit_visitCompensations[];
  flowChart: EditStudyVisit_flowChart | null;
  type: VisitType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditStudyFlowChart
// ====================================================

export interface EditStudyFlowChart_visits_visitCompensations_compensation {
  __typename: "Compensation";
  id: number;
  name: string;
  deletedAt: string | null;
}

export interface EditStudyFlowChart_visits_visitCompensations {
  __typename: "VisitCompensation";
  id: number;
  compensation: EditStudyFlowChart_visits_visitCompensations_compensation | null;
}

export interface EditStudyFlowChart_visits_flowChart {
  __typename: "FlowChart";
  id: number;
  cycleStartsAt: number | null;
  cycleEndsAt: number | null;
}

export interface EditStudyFlowChart_visits {
  __typename: "Visit";
  id: number;
  name: string | null;
  orderNumber: number | null;
  visitCompensations: EditStudyFlowChart_visits_visitCompensations[];
  flowChart: EditStudyFlowChart_visits_flowChart | null;
  type: VisitType;
}

export interface EditStudyFlowChart {
  __typename: "FlowChart";
  id: number;
  version: number;
  isDeletable: boolean;
  isLocked: boolean;
  versionDate: string;
  allowExpenses: boolean | null;
  createdAt: string;
  cycleStartsAt: number | null;
  cycleEndsAt: number | null;
  visits: EditStudyFlowChart_visits[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CompensationStatus {
  DELAYED = "DELAYED",
  DONE = "DONE",
  NOT_DONE = "NOT_DONE",
  PLANNED = "PLANNED",
}

export enum PaymentStatus {
  APPROVED = "APPROVED",
  CANCELED = "CANCELED",
  DENIED = "DENIED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  PAID = "PAID",
  PLANNED = "PLANNED",
}

export enum StudyRole {
  INVESTIGATOR = "INVESTIGATOR",
  PRINCIPLE_INVESTIGATOR = "PRINCIPLE_INVESTIGATOR",
  STUDY_COORDINATOR = "STUDY_COORDINATOR",
  STUDY_NURSE = "STUDY_NURSE",
}

export enum SubjectStatus {
  COMPLETED = "COMPLETED",
  EARLY_TERMINATED = "EARLY_TERMINATED",
  IN_SCREENING = "IN_SCREENING",
  ONGOING = "ONGOING",
  SCREEN_FAILURE = "SCREEN_FAILURE",
}

export enum UserRole {
  ADMIN = "ADMIN",
  CENTER_USER = "CENTER_USER",
  CLIENT_USER = "CLIENT_USER",
}

export enum VisitType {
  endOfStudy = "endOfStudy",
  normal = "normal",
  screening = "screening",
}

export interface AddMoneyToBudgetInput {
  price: number;
  studyId: number;
}

export interface AddVisitInput {
  flowChartId?: number | null;
  name?: string | null;
  orderNumber?: number | null;
  studyId: number;
  subjectId?: number | null;
}

export interface CenterInput {
  centerId?: number | null;
  name: string;
  users: UserInput[];
}

export interface ChangeCompensationStatusInput {
  compensationId: number;
  status: CompensationStatus;
  subjectVisitCompensationId: number;
  subjectVisitId: number;
}

export interface ClientInput {
  clientId?: number | null;
  contacts: ContactInput[];
  name: string;
  note?: string | null;
}

export interface ContactInput {
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  username: string;
}

export interface EditSubjectInput {
  bankAccount?: string | null;
  id: string;
}

export interface PostalFileInput {
  postalFile: any;
  subjectVisitId: number;
}

export interface SetStudyContactInput {
  contactId: number;
  studyId: number;
}

export interface StudyInput {
  clientId: number;
  code: string;
  eudraCtNumber: string;
  plannedVisitorsAmout: number;
  plannendBudget: number;
  protocolNumber: string;
  sponsor?: string | null;
}

export interface SubjectInput {
  bankAccount?: string | null;
  centrumId: number;
  code: string;
  gdpr: boolean;
  informedConsent: boolean;
  status: SubjectStatus;
  studyId: number;
}

export interface UpdateExpensePriceInput {
  compensationId: number;
  price: number;
  subjectVisitCompensationId: number;
  subjectVisitId: number;
}

export interface UserInput {
  email: string;
  firstname: string;
  lastname: string;
  newPassword?: string | null;
  oldPassword?: string | null;
  phone: string;
  username: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
