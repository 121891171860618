import React from 'react';
import styled from '@emotion/styled';
import Navigation, { NavigationTextType } from './Navigation';
import { UserRole } from '../graphql/types';
import Option from './Option';
import { css } from '@emotion/core';
import Button from './Button';
import { useNavigate } from '@reach/router';
import Flex from './Flex';
import { useMe } from '../hooks';

interface Props {
    data: (NavigationTextType | null)[];
    hidePlusButton?: boolean;
    hide?: boolean;
    warning?: boolean;
    addStudy?: boolean;
    studyId?: string;
    optionProps?: {
        earlyTerminated?: boolean;
        subjectDetail?: boolean;
        editStudy?: boolean;
        onExtract?: () => void;
    }
}

const Header = ({ data, warning, optionProps, hidePlusButton, addStudy,studyId }: Props) => {
    const { me } = useMe()
    const navigate = useNavigate();
    const isAdmin = me?.role === UserRole.ADMIN;
    return (
        <div css={css`margin-top: 1em;`}>
            <HeaderContainer>
                <Navigation data={data} warning={warning} />
                <Flex alignItems="center">
                {!hidePlusButton && <Option {...optionProps} isAdmin={isAdmin} />}
                {addStudy && isAdmin && <Button onClick={() => navigate("/study/new")}>Add Study</Button>}
                {studyId && studyId !== 'all' && <div css={css`margin-left: 1em; display: flex; align-items: center; `}><Button onClick={() => navigate(isAdmin ? `/study/${studyId}` : `/site/${me?.centrumId}/study/${studyId}`)}>Go To Study</Button></div>}
                </Flex>
            </HeaderContainer>
        </div>
    )
}

export default Header;

const HeaderContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
max-width: 990px;
margin: auto;
height: 57px;
`;