import React from "react";
import { ApolloClient } from "apollo-client";
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { Router, navigate } from "@reach/router";
import { ApolloProvider } from "@apollo/react-hooks";
import Studies from "./pages/Studies";
import StudyDetail from "./pages/StudyDetail";
import "./global.css";
import Payments from "./pages/Payments";
import SubjectDetail from "./pages/SubjectDetail";
import EditStudy from "./pages/EditStudy";
import AddSubject from "./pages/AddSubject";
import Centrums from "./pages/Centrums";
import Users from "./pages/Users";
import UpdateCentrum from "./pages/UpdateCentrum";
import { createUploadLink } from "apollo-upload-client";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import NotFound from "./pages/NotFound";
import VerifyUser from "./pages/VerifyUser";
import Profile from "./pages/Profile";
import BankInfo from "./pages/BankInfo";
import ForgottenPassword from "./pages/ForgottenPassword";
import Clients from "./pages/Clients";
import UpdateClient from "./pages/UpdateClient";
import { onError } from "apollo-link-error";
import * as ApolloLink from "apollo-link";
import { UserRole } from "./graphql/types";
import "tippy.js/dist/tippy.css";

const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors?.length && graphQLErrors[0].message === "Not authorized") {
    navigate("/login", {
      state: {
        isLoggedOut: true,
      },
    });
  }
});

const uploadLink = createUploadLink({
  fetchOptions: { credentials: "include" },
  uri: `/api/graphql`,
});
const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: ApolloLink.concat(errorLink, uploadLink),
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Login path="/login" client={client} />
        <ForgottenPassword path="/reset_password" />
        <VerifyUser path="/verify_user/:token" />
        <ProtectedRoute
          path="/profile"
          Component={Profile}
          roles={[UserRole.CLIENT_USER, UserRole.CENTER_USER]}
        />
        <ProtectedRoute
          path="/"
          Component={Studies}
          roles={[UserRole.CLIENT_USER, UserRole.CENTER_USER]}
        />
        <ProtectedRoute
          path="/site/:centrumId"
          Component={Studies}
          roles={[UserRole.CENTER_USER]}
        />
        <ProtectedRoute path="/sites" Component={Centrums} />
        <ProtectedRoute path="/clients" Component={Clients} />
        <ProtectedRoute path="/clients/:clientId" Component={UpdateClient} />
        <ProtectedRoute path="/sites/:centrumId" Component={UpdateCentrum} />
        <ProtectedRoute
          path="/site/:centrumId/study/:studyId"
          Component={StudyDetail}
          roles={[UserRole.CENTER_USER]}
        />
        <ProtectedRoute path="/study/:studyId" Component={StudyDetail} />
        <ProtectedRoute
          path="/site/:centrumId/study/:studyId/add_subject"
          Component={AddSubject}
          roles={[UserRole.CENTER_USER]}
        />
        <ProtectedRoute
          path="/site/:centrumId/study/:studyId/subject/:subjectId/edit"
          Component={AddSubject}
          roles={[UserRole.CENTER_USER]}
        />
        <ProtectedRoute
          path="/study/:studyId/subject/:subjectId/edit"
          Component={AddSubject}
        />
        <ProtectedRoute
          path="/study/:studyId/add_subject"
          Component={AddSubject}
        />
        <ProtectedRoute path="/add_client" Component={UpdateClient} />
        <ProtectedRoute path="/add_site" Component={UpdateCentrum} />
        <ProtectedRoute
          path="/payments/:studyId"
          Component={Payments}
          roles={[UserRole.CENTER_USER]}
        />
        <ProtectedRoute
          path="/postal_orders/:studyId"
          Component={Payments}
          roles={[UserRole.CENTER_USER]}
        />
        <ProtectedRoute
          path="/site/:centrumId/study/:studyId/subject/:subjectId"
          Component={SubjectDetail}
          roles={[UserRole.CENTER_USER]}
        />
        <ProtectedRoute
          path="/study/:studyId/subject/:subjectId"
          Component={SubjectDetail}
        />
        <ProtectedRoute path="/study/:studyId/edit" Component={EditStudy} />
        <ProtectedRoute
          path="/site/:centrumId/study/:studyId/edit"
          Component={EditStudy}
        />
        <ProtectedRoute path="/study/new" Component={EditStudy} />
        <ProtectedRoute path="/bank_info/:studyId" Component={BankInfo} />
        <ProtectedRoute
          path="/study/:studyId/view"
          Component={EditStudy}
          roles={[UserRole.CLIENT_USER, UserRole.CENTER_USER]}
        />
        <ProtectedRoute
          path="/site/:centrumId/study/:studyId/view"
          Component={EditStudy}
          roles={[UserRole.CENTER_USER]}
        />
        <ProtectedRoute path="/users" Component={Users} />
        <NotFound default />
      </Router>
    </ApolloProvider>
  );
};

export default App;
