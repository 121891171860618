import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { unresolvedSubjectVisitQuery, studySubjectQuery } from '../../graphql/queries';
import { UnresolvedSubjectVisit, UnresolvedSubjectVisitVariables, StudySubject_study_subjects_subjectVisits } from '../../graphql/types';
import { useParams } from '@reach/router';
import OptionDialog from './OptionDialog';
import NotResolvedPostalOrderNotification from './NotResolvedPostalOrderNotification';
import DelayedCompensationNotification from './DelayedCompensationNotification';
import { finishCycle, repeatCycle, changeSubjectStatus } from '../../graphql/mutations';

interface Props {
    isEntryDialogVissible: boolean; 
    isCycleDialogVissible: boolean; 
    isOneCycleFlow: boolean;
    onFocusVisit: (index: number) => void;
    subjectVisits?: StudySubject_study_subjects_subjectVisits[];
}

const SubjectDialogs = ({ isEntryDialogVissible, isCycleDialogVissible, isOneCycleFlow, onFocusVisit, subjectVisits }: Props) => {
    const params = useParams();
    const studyId = Number(params.studyId);
    const subjectId = Number(params.subjectId);
    const { data } = useQuery<UnresolvedSubjectVisit, UnresolvedSubjectVisitVariables>(unresolvedSubjectVisitQuery, {
        variables: {
            subjectId: Number(subjectId)
        }
    });

    const cycleVariables = {
        studyId, subjectId
    }
    const refetchVariables = {
        studyId,
        subjectId
    }
    const [endCycle] = useMutation(finishCycle);
    const [startCycle, { loading: cycleLoading }] = useMutation(repeatCycle);
    const [changeStatus, { loading: enteredLoading } ] = useMutation(changeSubjectStatus);

    const handleClickEnteted = () => {
        changeStatus({
            variables: {
                studyId: Number(studyId),
                subjectId: Number(subjectId),
                status: "ONGOING"
            },
            refetchQueries: [{ query: studySubjectQuery, variables: refetchVariables }]
        })
    }

    const handleClickNotEntered = () => {
        changeStatus({
            variables: {
                studyId: Number(studyId),
                subjectId: Number(subjectId),
                status: "SCREEN_FAILURE"
            },
            refetchQueries: [{ query: studySubjectQuery, variables: refetchVariables }]
        })
    }

    const handleFinishCycle = () => {
        endCycle({
            variables: cycleVariables,
            refetchQueries: [{ query: studySubjectQuery, variables: refetchVariables }]
        })
    }

    const handleRepeatCycle = () => {
        startCycle({
            variables: cycleVariables,
            refetchQueries: [{ query: studySubjectQuery, variables: refetchVariables }]
        })
    }

    if(data == null){
        return null;
    }
    const { unresolvedSubjectVisitWithPostalOrder, delayedSubjectCompensations } = data.unresolvedSubjectVisit;
    return(
        <>
            {unresolvedSubjectVisitWithPostalOrder.map(c => 
                <NotResolvedPostalOrderNotification 
                    onClick={onFocusVisit} 
                    key={`c-notResolved${c.id}`} 
                    visitIndex={subjectVisits?.map(v => v.id).indexOf(c.id)} 
                />
            )}
            {delayedSubjectCompensations.map(c => 
                <DelayedCompensationNotification    
                    key={`c-delayed${c.id}`} 
                    onClick={onFocusVisit} 
                    data={c} 
                    visitIndex={subjectVisits?.map(v => v.id).indexOf(c.subjectVisitId)} 
                />
            )}
            {isEntryDialogVissible && 
                <OptionDialog 
                    text="Subject entered study?" 
                    onClickNo={enteredLoading ? undefined : handleClickNotEntered} 
                    onClickYes={enteredLoading ? undefined : handleClickEnteted} />
            }
            {isCycleDialogVissible && 
                <OptionDialog 
                    text={isOneCycleFlow ? "Do you want to add Regular visit?" : "Subject has reached the end of cycle. Do you want to add new cycle?"} 
                    onClickNo={cycleLoading ? undefined : handleFinishCycle} 
                    onClickYes={cycleLoading ? undefined : handleRepeatCycle} 
                />
            }
        </>
    )
}

export default SubjectDialogs;