import React, { useState } from 'react';
import { useParams, navigate } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { Study, StudyVariables, Study_studies_subjects, CentrumDataQuery } from '../../graphql/types';
import { studyQuery, centrumDataQuery } from '../../graphql/queries';
import StudyHeader from './StudyHeader';
import SubjectItem from './SubjectItem';
import TopBar from '../../components/TopBar';
import { Container } from '../../libs/styles';
import TextInput from '../../components/TextInput';
import styled from '@emotion/styled';
import SwitchButton from '../../components/SwitchButton';
import Header from '../../components/Header';
import Button from '../../components/Button';
import Flex from '../../components/Flex';

interface subjectsPayload {
    subject: Study_studies_subjects;
    studyId: number;
}

const StudyDetail = () => {
    const [searchBy, setSearch] = useState("");
    const {studyId, centrumId } = useParams();
    const [showSubjectsWithPending, setShowSubjectsWithPending] = useState(false);
    const { data } = useQuery<Study, StudyVariables>(studyQuery, {
        variables: {
            studyId: studyId != null && studyId !== 'all' ? parseInt(studyId, 10) : undefined,
            centrumId: centrumId != null ? parseInt(centrumId, 10) : null
        },
        fetchPolicy: 'cache-and-network'
    });
    const { data: centrumData } = useQuery<CentrumDataQuery>(centrumDataQuery, {
        variables: {
            centrumId: parseInt(centrumId, 10),
            studyId: parseInt(studyId, 10),
        }
        , skip: !!!centrumId,
    });
    const subjects = data?.studies.reduce((value: subjectsPayload[], study) => {
        study.subjects.filter(s => !!searchBy ? s.code.toLocaleLowerCase()
            .indexOf(searchBy.toLocaleLowerCase()) !== -1 || (s?.bankAccount?.toLocaleLowerCase().indexOf(searchBy.toLocaleLowerCase()) !== -1 && s?.bankAccount != null) : s)
            .filter(s => showSubjectsWithPending ? s.delayedSubjectCompensations.length > 0 || s.unresolvedSubjectVisitWithPostalOrder.length > 0 : s)
            .map(subject => {
                if (!!searchBy) {
                    if (subject.code.toLocaleLowerCase().indexOf(searchBy.toLocaleLowerCase()) !== -1) {
                        return value.push({ subject: subject, studyId: study.id });
                    }
                }
                return value.push({ subject: subject, studyId: study.id });
            });
        return value;
    }, []);

    const handleAddSubject = () => {
        if (centrumId) {
            navigate(`/site/${centrumId}/study/${studyId}/add_subject`)
        } else {
            navigate(`/study/${studyId}/add_subject`)
        }
    }

    let navigationData = [];

    if(centrumId){
        navigationData.push({ text: `Site ${centrumData?.centrums[0].code}`, path: `/site/${centrumId}` })
    }
    navigationData = [...navigationData, { text: `Study ${data?.studies[0].code ?? ''}` }, { text: 'Study Overview' }]

    return (
        <>
            <TopBar />
            <Header data={navigationData} />
            <Container>
                {data != null && data.studies != null && <StudyHeader sponsor={data.studies[0].sponsor} protocolNumber={data.studies[0].protocolNumber} code={data.studies.length > 1 ? 'All' : data?.studies[0].code} eudraCtNumber={data.studies.length > 1 ? ' All' : data.studies[0].eudraCtNumber} clientName={data.studies.length > 1 ? 'All' : data.studies[0].client.name} />}
                <FilterContainer>
                    <Flex alignItems="center" flex={1}>
                        <TextInput placeholder="Search Subjects" onChange={setSearch} value={searchBy} style={{ minWidth: 300, maxWidth: 500, marginRight: '2em' }} />
                        <SwitchButton label="Show Only Pending" value="expenses" type="gray" isActive={showSubjectsWithPending} onChange={(_, isActive) => setShowSubjectsWithPending(isActive)} />
                    </Flex>
                    <Button onClick={handleAddSubject}>Add Subject</Button>
                </FilterContainer>
                {subjects != null && subjects.map(subjectItem =>
                    <SubjectItem
                        key={`subject-${subjectItem.subject.id}`}
                        status={subjectItem.subject.status}
                        delayedCompensations={subjectItem.subject.delayedSubjectCompensations.length + subjectItem.subject.unresolvedSubjectVisitWithPostalOrder.length}
                        subjectId={subjectItem.subject.id}
                        studyId={subjectItem.studyId}
                        deniedPayments={subjectItem.subject.deniedPayments}
                        lastVisit={subjectItem.subject.lastVisit?.date}
                        bankAcount={subjectItem.subject.bankAccount}
                        searchBy={searchBy}
                        code={subjectItem.subject.code} />
                )}
            </Container>
        </>
    )
}

export default StudyDetail;

const FilterContainer = styled.div`
padding: 2em;
margin: 1em 0;
background-color: white;
display: flex;
align-items: center;
justify-content: space-space-between;
flex: 1;
`;

