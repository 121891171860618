import React, { useState, useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from '@emotion/styled';
import add from '../icons/add.svg'
import { useNavigate, useParams } from '@reach/router';
import { useMutation } from '@apollo/react-hooks';
import { changeSubjectStatus, archiveStudyMutation } from '../graphql/mutations';
import { studySubjectQuery } from '../graphql/queries';
import Modal from './Modal';
import Button from './Button';
import { css } from '@emotion/core';
import { ArchiveStudyMutationVariables, ArchiveStudyMutation } from '../graphql/types';

interface Props {
    earlyTerminated?: boolean;
    subjectDetail?: boolean;
    isAdmin?: boolean;
    editStudy?: boolean;
    onExtract?: () => void;
}


const Option = ({ earlyTerminated = false, subjectDetail, isAdmin, editStudy, onExtract }: Props) => {
    const [isOptionOpen, setOptionOpen] = useState(false);
    const [openTerminateModal, setOpenTerminateModal] = useState(false);
    const navigate = useNavigate();
    const [archiveStudy] = useMutation<ArchiveStudyMutation, ArchiveStudyMutationVariables>(archiveStudyMutation)
    const { studyId, subjectId, centrumId } = useParams();
    const handleOpenOption = () => {
        setOptionOpen(prevState => !prevState);
    }

    const handleNavigateEditSubject = () => {
        if (centrumId) {
            navigate(`/site/${centrumId}/study/${studyId}/subject/${subjectId}/edit`)
        } else {
            navigate(`/study/${studyId}/subject/${subjectId}/edit`)
        }
    }

    const handleNavigateEditStudy = () => {
        if (centrumId) {
            navigate(`/site/${centrumId}/study/${studyId}/${isAdmin ? 'edit' : 'view'}`);
        } else {
            navigate(`/study/${studyId}/${isAdmin ? 'edit' : 'view'}`);
        }
    }
    const handleArchiveStudy = async () => {
        if (window.confirm('Do you really want to archive this study? You will not see it anymore.')) {
            await archiveStudy({
                variables: {
                    studyId: Number(studyId)
                }
            })
            navigate("/");
        }
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setOptionOpen(false)}>
            <div style={{ position: "relative", zIndex: 2, height: '49.5px' }}>
                {isOptionOpen && studyId &&
                    <ListContainer>
                        {!onExtract && !editStudy && studyId !== "all" && <ListItem role="button" onClick={handleNavigateEditStudy}>
                            {isAdmin ? "Edit study" : "View study"}
                        </ListItem>}
                        {subjectDetail && <ListItem role='button' onClick={handleNavigateEditSubject}>Edit subject</ListItem>}
                        {earlyTerminated && <ListItem role="button" onClick={() => setOpenTerminateModal(true)}>
                            Early Terminate Subject
                            </ListItem>}
                        {isAdmin && editStudy && <ListItem role="button" onClick={handleArchiveStudy}>Archive study</ListItem>}
                        {isAdmin && onExtract && <ListItem role="button" onClick={onExtract}>Export</ListItem>}
                    </ListContainer>
                }
                <AddButoon onClick={handleOpenOption}>
                    <img alt='add-button' src={add} style={{ height: '100%'}}/>
                </AddButoon>
            </div>
            <TerminateModal studyId={studyId} subjectId={subjectId} isOpen={openTerminateModal} setIsOpen={setOpenTerminateModal} />
        </OutsideClickHandler>
    )
}

export default Option;

interface TerminateModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    studyId: string;
    subjectId: string;
}

const TerminateModal = ({ isOpen, setIsOpen, studyId, subjectId }: TerminateModalProps) => {
    const [changeStatus] = useMutation(changeSubjectStatus);

    const variables = useMemo(() => ({
        studyId: Number(studyId),
        subjectId: Number(subjectId),
    }), [studyId, subjectId])

    const handleEarlyTerminated = () => {
        changeStatus({
            variables: {
                studyId: Number(studyId),
                subjectId: Number(subjectId),
                status: "EARLY_TERMINATED"
            },
            refetchQueries: [{ query: studySubjectQuery, variables }]
        })
        setIsOpen(false);
    }
    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                contentLabel="Add flowchart"
            >
                <p>Are you sure you want to early terminate this Subjects's participation?</p>
                <div css={css`display: flex; justify-content: space-between; margin: auto; width: 300px;`}>
                    <Button onClick={handleEarlyTerminated} color='primary'>YES</Button>
                    <Button onClick={() => setIsOpen(false)} color='danger'>NO</Button>
                </div>
            </Modal>
        </>
    )
}

const ListItem = styled.li`
    padding: 0 16px 16px 16px;
    color: white;
    text-align: right;
    white-space: nowrap;
    font-size: 15px;
    cursor: pointer;
`;

const ListContainer = styled.ul`
    position: absolute;
    right: 0;
    top: 0;
    background: #d5a63f;
    padding: 0;
    padding-top: 53px;
    z-index: -1;
    border-radius: 2px;
    margin: 0;
    list-style-type: none;
`;

const AddButoon = styled.button`
border: none;
outline: none;
background-color: transparent;
z-index: 10;
margin: 0;
height: 100%;
padding: 0;
`;
