import React from 'react';
import planned from '../icons/planned.svg';
import done from '../icons/done.svg';
import notDone from '../icons/not-done.svg';
import delayed from '../icons/delayed.svg';
import failed from '../icons/failed.svg';
import styled from '@emotion/styled';
import Tippy from '@tippyjs/react';

interface Props {
    type: "payment" | "compensation" | "paymentCompensation" | "general";
    incomingStatus: string;
    value?: string | number;
    legend?: boolean;
    small?: boolean;
    tippyMessage?: string | null;
    subLabel?: string | number;
}

const compensationStatus = [
    { code: 'PENDING', text: 'Pending', img: planned },
    { code: 'ALL_SET', text: 'All set', img: done },
]

const paymentCompensationValues = [
    { code: 'PLANNED', text: 'Planned', img: delayed },
    { code: 'DELAYED', text: 'Delayed', img: planned },
    { code: 'NOT_DONE', text: 'Not Done', img: notDone },
    { code: 'FAILED', text: 'Denied', img: failed},
    { code: 'DONE', text: 'Done', img: done }]

const paymentValues = [
    { code: 'planned', text: 'Planned', img: delayed },
    { code: 'approved', text: 'Approved', img: done },
    { code: 'inProgress', text: 'In Progress', img: delayed},
    { code: 'paid', text: 'Paid', img: done },
    { code: 'denied', text: 'Denied', img: notDone },
    { code: 'canceled', text: 'Canceled', img: notDone },
]

const generalValues = [
    { code: 'ok', text: 'Ok', img: done },
]

const mapValues = {
    "paymentCompensation": paymentCompensationValues,
    "compensation": compensationStatus,
    "payment": paymentValues,
    "general": generalValues
}

const Status = ({ type, legend, incomingStatus, value, small, tippyMessage, subLabel }: Props) => {

    const status = mapValues[type].find(status => status.code === incomingStatus);
    return (
            <PaymentStatusBox legend={legend}>
                 <Tippy delay={[500,0]} placement='bottom' content={tippyMessage || undefined} disabled={tippyMessage == null}>
                <img width={small ? '14px' : '16px'} alt={status?.text} src={status?.img} />
                </Tippy>
                <Text small> {value ? value : ''} {status?.text} {subLabel != null ? subLabel : ""}</Text>
            </PaymentStatusBox>
    )
}
export default Status;

const Text = styled.span<{small: boolean}>`
font-weight: 500;
    font-size: ${props => props.small ? '12px' : '16px'};
    margin-left: 0.5em;
`;

const PaymentStatusBox = styled.div<{legend?: boolean}>`
display: flex;
align-items: center;
margin: ${props => props.legend ? `0 1em` : `0 0` };
`;
