import React from 'react';
import { DetailClientsQuery_clients } from '../../graphql/types';
import { Link } from '@reach/router';
import { Row, TableItem, Label, Value } from '../../components/Table';
import Highlight from 'react-highlighter';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Flex from '../../components/Flex';
import { colors } from '../../libs/colors';

interface Props {
    client: DetailClientsQuery_clients;
    searchBy: string;
}

const ClientItem = ({ client, searchBy }: Props) => {
    const contacts = client.contacts?.map(c => `${c.firstName} ${c.lastName}`);
    const contactsCount = contacts?.length || 0;

    const noteLength = client.note?.length || 0;
    return(
        <Link css={css`text-decoration: none;`} to={`/clients/${client.id}`}>
                    <Row>
                        <TableItem css={css`width: 20%;`}>
                            <Label>Client</Label>
                            <Value>
                                <Highlight search={searchBy}>{client.name}</Highlight>
                            </Value>
                        </TableItem>
                        <TableItem css={css`width: 40%;`}>
                            <Label>Contacts</Label>
                            <Value css={css`display: flex; flex-wrap: wrap;`}>
                                <Highlight search={searchBy}>{contacts?.slice(0, 2).join(", ")}</Highlight>
                                {contactsCount > 2 &&
                                     <ShowMoreButton ml={2} role="button"> 
                                        {`+ ${contactsCount - 2} more`}
                                    </ShowMoreButton> 
                                }
                            </Value>
                        </TableItem>
                        <TableItem css={css`width: 40%;`}>
                            <Label>Note</Label>
                            <Value css={css`display: flex; flex-wrap: wrap;`}>
                                <Highlight search={searchBy}>{client.note?.slice(0, 32)}</Highlight>
                                {noteLength > 32 &&
                                     <ShowMoreButton ml={2} role="button"> 
                                        more
                                    </ShowMoreButton> 
                                }
                            </Value>
                        </TableItem>
                    </Row>
                </Link>
    )
};

export default ClientItem;

const ShowMoreButton = styled(Flex)`
    color: ${colors.link};
    font-size: 14;
    border: none;
    padding: 0;
    background: none;
`;