import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useMutation } from '@apollo/react-hooks';
import { ReactComponent as CancelIcon } from '../icons/x.svg';
import { ReactComponent as DoneIcon } from '../icons/check.svg';
import { ReactComponent as DelayIcon } from '../icons/delay.svg';
import { ReactComponent as DeleteIcon } from '../icons/delete.svg';
import { useParams } from '@reach/router';
import { CompensationStatus, RemoveVisitCompensationVariables, ChangeCompensationStatus, ChangeCompensationStatusVariables } from '../graphql/types';
import { changeCompensationStatus, removeVisitCompensationMutation } from '../graphql/mutations';
import { studyDetailQuery, unresolvedSubjectVisitQuery } from '../graphql/queries';
import { colors } from '../libs/colors';
import UpdatePriceModal from '../pages/SubjectDetail/UpdatePriceModal';

const typeColors: any = {
    "DONE": "#01E398",
    "NOT_DONE": "#FF4005",
    "DELAYED":  "#D5A63F"
}

interface Props {
    name: string;
    visitCompensationId: number;
    status?: CompensationStatus;
    disabled?: boolean;
    editable?: boolean
    visitId?: number;
    subjectVisitCompensationId?: number;
    isExpense?: boolean;
    price?: number | null;
    subjectVisitId?: number;
    compensationId: number;
}

const CompensationItem = ({ name, visitCompensationId, status, disabled, editable = false, visitId, subjectVisitCompensationId, isExpense, price, subjectVisitId, compensationId }: Props) => {
    const [changeStatus] = useMutation<ChangeCompensationStatus, ChangeCompensationStatusVariables>(changeCompensationStatus);
    const [removeCompensation] = useMutation<any, RemoveVisitCompensationVariables>(removeVisitCompensationMutation);
    const { studyId, subjectId } = useParams(); 
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (status: string) => {
        if(!disabled && subjectVisitCompensationId && subjectVisitId){
            changeStatus({
                variables: {
                    input: {
                        subjectVisitCompensationId,
                        status: status as CompensationStatus,
                        subjectVisitId,
                        compensationId
                    }  
                },
                refetchQueries: [{ query: unresolvedSubjectVisitQuery, variables: { subjectId: Number(subjectId )}}]
            })
        }
    }

    const handleRemove = () => {
        if(visitId){
            removeCompensation({
                variables: {
                    studyId: parseInt(studyId, 10),
                    visitId,
                    visitCompensationId
                },
                refetchQueries: [{query: studyDetailQuery, variables: { studyId: parseInt(studyId, 10) }}],
            })
        }
    }

    const updateExpensionPrice = () => {
        setIsOpen(true);
    }   

    return (
        <Row>
            <CompensationItemBox disabled={disabled} onClick={(isExpense && !disabled) ? updateExpensionPrice : undefined}>
                <span css={css`margin-left: 0.7em; padding: 8px;`}>{name}</span>
                {price != null && <Price>{price} CZK</Price>}
                {!editable && !isExpense && 
                    <Flex>
                        <Button onClick={() => handleClick('DONE')} isActive={status === 'DONE'} statusType="DONE">
                            <DoneItem opacity={disabled ?  0.3 : 1} isActive={status === 'DONE'}/>
                        </Button>
                        <Button onClick={() => handleClick('NOT_DONE')} isActive={status === 'NOT_DONE'} statusType="NOT_DONE">
                            <CancelItem opacity={disabled ?  0.3 : 1} isActive={status === 'NOT_DONE'}/>
                        </Button>
                        <Button onClick={() => handleClick('DELAYED')} isActive={status === 'DELAYED'} statusType="DELAYED">
                            <DelayItem opacity={disabled ?  0.3 : 1} isActive={status === 'DELAYED'}/>
                        </Button>
                    </Flex>
                }
            </CompensationItemBox>
            {editable && 
                <RemoveButton onClick={handleRemove}>
                    <DeleteIcon />
                </RemoveButton>
            }
           {isOpen && price != null && subjectVisitId && subjectVisitCompensationId && <UpdatePriceModal setIsOpen={setIsOpen} price={price} isOpen={isOpen} name={name} subjectVisitCompensationId={subjectVisitCompensationId} subjectVisitId={subjectVisitId} compensationId={compensationId}/>}
        </Row>
    )
}

export default CompensationItem;

const Price = styled.span`
    padding-right: 16px;
    color: ${colors.darkGray};
`;

const RemoveButton = styled.button`
    border: none;
    padding: 0;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 4px 4px 8px;
    outline: none;
`;


const CompensationItemBox = styled.div<{disabled?: boolean;}>`
display: flex;
justify-content: space-between;
align-items: center;
background-color: ${colors.compensationItem};
border-radius: 9999px;
margin: 0.5em 0;  
font-size: 14px;
flex: 1;
color: ${({disabled}) => disabled ? colors.gray : 'black'};
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
`

const Flex = styled.div`
    display: flex;
`;

const Button = styled.button<{ isActive: boolean, statusType: string }>`   
    margin: 0;
    border: none;
    padding: 0;
    height: 32px;
    width: 32px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${props => props.isActive ? typeColors[props.statusType] : 'none'};
`

const CancelItem = styled(CancelIcon)<{ isActive: boolean }>`
    color: ${props => props.isActive ? 'white' : '#6D6D6D'};
`;

const DoneItem = styled(DoneIcon)<{ isActive: boolean }>`
    color: ${props => props.isActive ? 'white' : '#6D6D6D'};
`;

const DelayItem = styled(DelayIcon)<{ isActive: boolean }>`
    color: ${props => props.isActive ? 'white' : '#6D6D6D'};
`;