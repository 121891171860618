import React, { useState } from 'react';
import TopBar from '../../components/TopBar';
import { Container } from '../../libs/styles';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import Button from '../../components/Button';
import { useQuery } from '@apollo/react-hooks';
import { detailCentrumsQuery } from '../../graphql/queries';
import { detailCentrums, detailCentrumsVariables } from '../../graphql/types';
import CentrumList from './CentrumList';
import { Item } from '../../components/Item';
import { css } from '@emotion/core';
import TextInput from '../../components/TextInput';
import Header from '../../components/Header';


const Centrums = (_ :RouteComponentProps) => {
    const [searchBy, setSearch] = useState("");
    const { studyId } = useParams();
    const navigate = useNavigate();

    const { data } = useQuery<detailCentrums, detailCentrumsVariables>(detailCentrumsQuery, { 
        variables: { 
            studyId: studyId ? parseInt(studyId, 10) : undefined 
        },
        fetchPolicy: 'cache-and-network'
    });

    const filteredCenters = data?.centrums.filter(c => {
        if(c.name.toLocaleLowerCase().indexOf(searchBy.toLocaleLowerCase()) !== -1){
            return true;
        } else {
            const filtered = c.codes.filter(code => {
                return code.toLocaleLowerCase().indexOf(searchBy.toLocaleLowerCase()) !== -1
            })
            return filtered.length;
        }
    })

    const navigationData = [
        { text: 'Sites'},
    ];

    return(
        <>
            <TopBar sites/>
            <Header hidePlusButton data={navigationData} />
            <Container>
                <Item css={css`flex-direction: row; justify-content: space-between;`}>
                    <TextInput placeholder="Search Sites" onChange={setSearch} value={searchBy} style={{ minWidth: 250}}/>
                    <Button onClick={() => navigate("/add_site") }>Add Site</Button>
                </Item>
                {filteredCenters && <CentrumList centrums={filteredCenters} searchBy={searchBy}/>}
            </Container>
        </>
    )
}

export default Centrums;