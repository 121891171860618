import React from 'react';
import { UsersQuery_users } from '../../graphql/types';
import { Row, TableItem, Value, Label } from '../../components/Table';
import Highlight from 'react-highlighter';
import { css } from '@emotion/core';

interface Props {
    users: UsersQuery_users[];
    searchBy: string;
}

const UserList = ({ users, searchBy }: Props) => {
    return(
        <>
            {users.map(c => (
                <>
                    <Row>
                        <TableItem css={css`width: 15%;`}>
                            <Label>User Name</Label>
                            <Value>
                                <Highlight search={searchBy}>{c.username}</Highlight>
                            </Value>
                        </TableItem>
                        <TableItem css={css`width: 15%;`}>
                            <Label>First Name</Label>
                            <Value>{c.contact.firstName}</Value>
                        </TableItem>
                        <TableItem css={css`width: 15%;`}>
                            <Label>Last Name</Label>
                            <Value>
                                <Highlight search={searchBy}>{c.contact.lastName}</Highlight>
                            </Value>
                        </TableItem>
                        <TableItem css={css`width: 25%;`}>
                            <Label>Email</Label>
                            <Value>
                                <Highlight search={searchBy}>{c.contact.email}</Highlight>
                            </Value>
                        </TableItem>
                        <TableItem css={css`width: 16%;`}>
                            <Label>Phone</Label>
                            <Value>
                                <Highlight search={searchBy}>{c.contact.phone}</Highlight>
                            </Value>
                        </TableItem>
                        <TableItem css={css`width: 19%;`}>
                            <Label>Studies</Label>
                            <Value>
                                <Highlight search={searchBy}>{c.studies.map(s => s.code).join(", ")}</Highlight>
                            </Value>
                        </TableItem>
                    </Row>
                </>
            ))}
        </>
    );
}

export default UserList;