import React from 'react';
import styled from '@emotion/styled'
import { colors } from '../../libs/colors';
import arrow from '../../icons/arrow.svg';
import { Link } from '@reach/router';
import { css } from '@emotion/core';
import { useMe } from '../../hooks';
import { UserRole } from '../../graphql/types';


interface Props {
    code: string;
    id: number;
}

const StudyItem = ({ code, id }: Props) => {
    const { me } = useMe();
    return (
        <Link css={css`text-decoration: none; color: black;`} to={`study/${id}${me?.role === UserRole.CLIENT_USER ? '/view' : ''}`}>
            <StudyItemBox>
                <p>{code}</p>
                <Arrow>
                    <img width='32px' alt='arrow' src={arrow} />
                </Arrow>

            </StudyItemBox>
        </Link>
    )
}

export default StudyItem;


const StudyItemBox = styled.div`
display: flex;
align-items: center;
min-width: 250px;   
border-radius: 7px;
padding: 0 1em;
font-weight: 500;
justify-content: space-between;
background-color: ${colors.skinzonePink};
margin-top: 16px;
`;

const Arrow = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 4px;
width: 28px;
height: 28px;
background-color: ${colors.primary};
border-radius: 2px;
`;