import React, { useState } from 'react';
import { colors } from '../libs/colors';
import styled from '@emotion/styled';
import {ReactComponent as Menu} from '../icons/menu.svg';
import cross from '../icons/cross.svg'
import { Link, useParams, useNavigate, useLocation } from '@reach/router';
import SelectBox from './SelectBox';
import { css } from '@emotion/core';
import { useQuery } from '@apollo/react-hooks';
import { SelectorStudies, SelectorStudiesVariables, UserRole, CentrumDataQuery, CentrumDataQueryVariables } from '../graphql/types';
import { selectorStudiesQuery, centrumDataQuery } from '../graphql/queries';
import horizontalLogo from '../icons/horizontal-logo-bw.png'
import { useMe } from '../hooks';
import 'tippy.js/dist/tippy.css';

interface Props {
    studiesList?: boolean;
    sites?: boolean
    unsaved?: boolean;
}

const TopBar = ({ sites, unsaved }: Props) => {
    const { studyId, centrumId } = useParams();
    const location = useLocation()
    const path = location.pathname.split("/")[1];
    const isPayments = path === "payments";
    const isPostalOrders = path === "postal_orders";
    const isBank = path === "bank_info";

    const { data, refetch: refetchStudies } = useQuery<SelectorStudies, SelectorStudiesVariables>(selectorStudiesQuery,
        {
            variables: { centrumId: parseInt(centrumId, 10) },
        }
    );

    const { me } = useMe();

    const isCenterUser = me?.role === UserRole.CENTER_USER;

    const { data: centrumData, refetch: refetchCentrums } = useQuery<CentrumDataQuery, CentrumDataQueryVariables>(centrumDataQuery, {
        variables: {
            studyId: parseInt(studyId, 10),
            takeAll: true
        },
        skip: isPayments || isPostalOrders || isBank 
    });

    const navigate = useNavigate()
    
    let studies = data?.studies.map(study => ({ key: `study-${study.id}`, label: study.code, value: study.id }));
   

    if ((isPayments || isPostalOrders || isBank) && studies && !isCenterUser) {
        studies = [{ label: "All", value: "all" as any, key: "all" }, ...studies];
    }
    const selectedItem = studies ? studies.find(item => item.value.toString() === studyId) : null;
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleChooseStudy = (value: string) => {
        const centrum = centrumId || me?.centrumId;
        if (isBank) {
            navigate(`/bank_info/${value}`)
        } else if (isPayments) {
            navigate(`/payments/${value}`)
        } else if (isPostalOrders) {
            navigate(`/postal_orders/${value}`)
        } else {
            if (centrum) {
                navigate(`/site/${centrum}/study/${value}`)
            } else {
                navigate(`/study/${value}${me?.role === UserRole.CLIENT_USER ? '/view' : ''}`)
            }
        }
    }

    const handleChooseCentrum = (value: string) => {
        if (unsaved) {
            showWarning(`/site/${value}`);
        }
        else {
            navigate(`/site/${value}`)
        }
    }
    const isAdmin = me?.role === UserRole.ADMIN;
    const isClient = me ? me.role === UserRole.CLIENT_USER : false;
    const isUser = me?.role === UserRole.CENTER_USER;
    const activeCentrum = centrumData && centrumData.centrums.find(c => c.id.toString() === centrumId || me?.centrumId);

    const showWarning = (link: string) => {
        const result = window.confirm('Leave site? Changes that you made may not be saved.');
        if (!!result) {
            navigate(link, { replace: true })
        }
    }

    const linkHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
        if (unsaved) {
            e.preventDefault();
            showWarning(link);
        }
    }

    const centrums = centrumData?.centrums.map(centrum => ({ key: `centrum-${centrum.id}`, label: `${centrum.name}${centrum.code ? `(${centrum.code})` : ''}`, value: centrum.id }));
    const arePaymentsDisabled = isCenterUser && studyId == null;

    return (
        <TopBarContainer>
            <MenuBox isOpen={isMenuOpen}>
                <MenuButtonBox isOpen={isMenuOpen}>
                    <MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        {isMenuOpen ? <img width='26' height='26' alt='cross' src={cross} /> : <Menu css={css`color: white;`} width='26' height='26'  />}
                    </MenuButton>
                </MenuButtonBox>
                {isMenuOpen && <OpenedMenu>
                    <CustomLink onClick={e => linkHandler(e, '/')} to={isUser ? `/site/${me?.centrumId}` : '/'}><MenuItem>Studies</MenuItem></CustomLink>
                    {!isClient && 
                        <CustomLink onClick={e => linkHandler(e, `/payments/${studyId || 'all'}`)} disabled={arePaymentsDisabled} to={`/payments/${studyId || 'all'}`}>   
                                <MenuItem>Payments {arePaymentsDisabled ? '(no study selected)' : ''}</MenuItem>   
                        </CustomLink>
                    }
                    {!isClient && 
                        <CustomLink onClick={e => linkHandler(e, `/postal_orders/${studyId ? studyId : 'all'}`)} disabled={arePaymentsDisabled} to={`/postal_orders/${studyId ? studyId : 'all'}`}>                       
                                <MenuItem>Postal Orders {arePaymentsDisabled ? '(no study selected)' : ''}</MenuItem>              
                        </CustomLink>
                    }
                    {isAdmin && <CustomLink onClick={e => linkHandler(e, '/sites/')} to='/sites/'><MenuItem>Sites</MenuItem></CustomLink>}
                    {isAdmin && <CustomLink onClick={e => linkHandler(e, '/users/')} to='/users/'><MenuItem>Users</MenuItem></CustomLink>}
                    {isAdmin && <CustomLink onClick={e => linkHandler(e, '/clients/')} to='/clients/'><MenuItem>Clients</MenuItem></CustomLink>}
                    {isAdmin && <CustomLink onClick={e => linkHandler(e, `/bank_info/${studyId || 'all'}`)} to={`/bank_info/${studyId || 'all'}`}><MenuItem>Fio Bank</MenuItem></CustomLink>}
                    <CustomLink onClick={e => linkHandler(e, '/profile')} to='/profile'><MenuItem>Profile</MenuItem></CustomLink>
                    <CustomLink onClick={e => linkHandler(e, '/login')} to='/login'><MenuItem css={css`margin-top: 0.5em;`}>Logout</MenuItem></CustomLink>
                </OpenedMenu>}
            </MenuBox >

            <Container>
                <HorizontalLogoBox>
                    <CustomLink onClick={e => linkHandler(e, '/')} to={isAdmin ? '/' : `/site/${me?.centrumId}`}>
                        <img alt='clinpas horizontal logo' height='45px' src={horizontalLogo} />
                    </CustomLink>
                </HorizontalLogoBox>    
                <div css={css`margin-left: 0.5em; display: flex; z-index: 21;`}>
                    {!isPayments && !isPostalOrders && !isBank && centrums && !isClient && <SelectBox list={centrums} label="Sites" minWidth="270px" value={activeCentrum?.id} onChange={handleChooseCentrum} disabled={!isAdmin} onOpen={() => refetchCentrums({ studyId: parseInt(studyId, 10) })}/>}
                    {studies && !sites && <SelectBox list={studies} label="Study" minWidth="250px" value={selectedItem?.value} onChange={handleChooseStudy} onOpen={() => refetchStudies({ centrumId: parseInt(centrumId, 10) })}/>}
                </div>
            </Container>
        </TopBarContainer >
    )
}

export default TopBar;

const CustomLink = styled(Link)<{ disabled?: boolean }>`
    text-decoration: none;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    color: ${props => props.disabled ? colors.lightGray : 'white'};
`;

const MenuBox = styled.div<{ isOpen?: boolean }>`
position: ${({ isOpen }) => isOpen ? 'fixed' : 'absolute'};
 z-index: 100;
    left: 0;
    top: 0;
    bottom: ${({ isOpen }) => isOpen ? 0 : 'auto'};
    box-shadow: ${({ isOpen }) => isOpen ? '4px 0 6px -3px #888' : 'none'};
`;

const TopBarContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.5em 0;
    position: relative;
    top: 0;
    justify-content: space-between;
    left: 0;
    background-color: ${colors.primary};

`;

const MenuButtonBox = styled.div<{ isOpen: boolean }>`
    position: relative;
    z-index: 100;
    padding: 1.5em;
    width: 256px;
    display: flex;
    background-color: ${props => props.isOpen ? colors.primary : 'transparent'};
    align-items: center;
`;

const OpenedMenu = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 256px;
    background-color: ${colors.primary};
    height: 100vh;
    padding: 1em 1.5em;
`;

const MenuItem = styled.div`
    padding: 0.5em;
`;

const MenuButton = styled.button`
    outline: none;
    border: none;
    background-color: transparent;
`;

const Container = styled.div`
display: flex;
justify-content: space-between;
width: 990px;
align-items: center;
margin: auto;
`;

const HorizontalLogoBox = styled.div<{ hide?: boolean }>`
    @media (max-width: 1100px) {
        margin-left: 15vw;
      }
`;