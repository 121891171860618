import React from 'react';
import styled from '@emotion/styled';
import TextField from '../../components/TextField';
import moment from 'moment';
import { css } from '@emotion/core';

interface Props {
    date: string;
    price: number;
    name: string;
}

const StudyBudgetItem = ({date,name, price}: Props) => {
    return (
        <Flex css={css`margin: 0.5em 0;`}>
            <TextField style={{width: '15%'}} label='Date' value={moment(Number(date)).format("DD.MM.YYYY")} />
            <TextField style={{width: '15%', minWidth: '80px'}} label='Amount' value={price} />
            <TextField  responsible style={{width: '100%'}} label='Responsible Person' value={name} />
        </Flex>
    )
}

export default StudyBudgetItem;

const Flex = styled.div`

display: flex`;