import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { colors } from '../libs/colors';

interface Props {
    message: string;
    resetError?: (value: null) => void;
    type?: "error" | "done";
}

const SnackBar = ({ message, resetError, type = "error" }: Props) => {
    const [isAnimating, runAnimate] = useState(false);
    
    useEffect(() => {
        if(message != null){
            runAnimate(true);
            setTimeout(() => {
                runAnimate(false)
                resetError && resetError(null);
            }, 5000)
        }
        return (() => {
            runAnimate(false)
            resetError && resetError(null);
        })
    }, [message,resetError])

    if(isAnimating){
        return(
            <Container style={{ background: type === "error" ? colors.danger : colors.success }}>
                <Message>
                    {message}
                </Message>
            </Container>
        )
    }
    return null;
       
}

export default SnackBar;

const translate = keyframes`
  0% {
    transform: translate(0,100%);
  }
  10% {
    transform: translate(0,0);
  }
  90% {
    transform: translate(0,0);
  }

  100% {
    transform: translate(0,100%);
  }
`


const Container = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    z-index: 32;
    animation: ${translate} 5s ease-in-out;
`;

const Message = styled.span`
    color: white;
`;