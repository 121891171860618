import React from 'react';
import { selectedPayments_selectedPayments } from '../../graphql/types';
import styled from '@emotion/styled';
import { Title } from '../../components/Title';
import PaymentItem from './PaymentItem';
import Flex from '../../components/Flex';
import { v4 as uuidv4 } from 'uuid';

interface Props {
    payments?: selectedPayments_selectedPayments[];
    title?: string;
    subTitle?: string;
    showSentAt?: boolean;
    onPay?: (paymentId: number) => void;
    onCancel?: (paymentId: number) => void;
    loading?: number;
}

const PaymentList = ({ payments, title, showSentAt = false, onCancel, onPay, loading, subTitle }: Props) => {
    return (
        <Container>
            <Flex flex={1} alignItems="center">
                <Title>{title}</Title>
                {subTitle != null && <Title style={{ marginLeft: 32}}>{subTitle}</Title>}
            </Flex>
            <ListContainer>
                {payments && payments.map(p => (
                  <PaymentItem p={p} showSentAt={showSentAt} onPay={onPay} loading={loading} onCancel={onCancel} key={uuidv4()}/>
                ))}
            </ListContainer>
        </Container>
    )
};

export default PaymentList;

const Container = styled.div`
    padding: 32px;
    background: white;
    margin-bottom: 16px;
`;

const ListContainer = styled.div`
    margin-top: 32px;
`;


