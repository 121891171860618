import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../libs/colors';


interface Props {
    items: {
        label?: string;
        value: number;
        color: string;
    }[];
    title?: string;
    total: number;
}

const BarGraph = ({ items, title, total }: Props) => {
    let tot = 0;
    items.forEach(i => {
        tot += i.value;
    })
    let graphVal = 0;
    return (
        <div>
            {title != null && <Title>{title}</Title>}
            <Container style={{ border: total === 0 ? `1px solid ${colors.subjectVisit}` : 'none' }}>
                {items.map((i, index) => {
                    const value = (i.value / tot) * 100;
                    graphVal += value;
                    return <Slide value={graphVal} key={index} color={i.color} z={20 - index}/>
                })}
            </Container>
            <Row>
                {items.map(i => {
                    if(i.label == null){
                        return null;
                    }
                    return(
                        <div style={{ marginRight: 8, width: 80 }} key={i.label}>
                            {i.label}
                            <Value color={i.color === '#cfd1d0' ? 'black' : i.color}>
                                {i.value}
                            </Value>
                        </div>
                    )
                })}
            </Row>
        </div>
    )
}

export default BarGraph;

const Title = styled.span`
    color: #979797;
    font-size: 14px;
`;

const Container = styled.div`
    width: 100%;
    position: relative;
    height: 16px;
    border-radius: 14px;
    margin-top: 8px;
`;

const Row = styled.div`
    display: flex;
    font-size: 10px;
    margin-top: 4px;
`;

const Value = styled.div<{color: string}>`
    color: ${props => props.color === '#E8EBEA' ? 'black' : props.color};
    font-size: 16px;
    font-weight: 600;
    margin-top: 4px;
`;

const Slide = styled.div<{ value: number, color: string, z: number}>`
    background-color: ${props => props.color};
    border-radius: 14px;
    z-index: ${props => props.z};
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${props => `${props.value}%`};
`;