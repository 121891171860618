import styled from "@emotion/styled";
import { colors } from "../libs/colors";

export const TableItem = styled.div`
padding: 16px 8px 16px 0;
display: flex;
flex-direction: column;
`;

export const Label = styled.span`
color: ${colors.darkGray};
font-size: 10px;
margin-bottom: 4px;
`;

export const Value = styled.span`
font-weight: 500;
word-break: break-word;
font-size: 16px;
color: black;
`;

export const Row = styled.div`
display: flex;
border-radius: 2px;
background-color: #fff;
margin-bottom: 8px; 
padding: 0 16px;
`;