import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { StudyDetail_study_flowCharts, StudyDetail_study_centrums, AssignFlowChartToCentrumVariables, AssignFlowChartToCentrum as AssignType, removeCycleVariables, updateCycleVariables, enableExpensesVariables, RemoveFlowChartVariables, RemoveFlowChart, StudyDetail, StudyDetailVariables } from '../../graphql/types';
import TextField from '../../components/TextField';
import { SubTitle, Title } from '../../components/Title';
import SwitchButton from '../../components/SwitchButton';
import { useMutation } from '@apollo/react-hooks';
import { assignFlowChartToCentrum, updateFlowChartCycleMutation, removeFlowChartCycleMutation, enableExpensesMutation, removeFlowChartMutation } from '../../graphql/mutations';
import { useParams } from '@reach/router';
import Collapsible from 'react-collapsible';
import { studyDetailQuery } from '../../graphql/queries';
import Visit from './Visit';
import SelectBox from '../../components/SelectBox';
import {ReactComponent as Arrow} from '../../icons/down-arrow.svg';
import { getFormatedDate } from '../../helpers';
import Flex from '../../components/Flex';


interface Props {
    flowChart: StudyDetail_study_flowCharts;
    centrums: StudyDetail_study_centrums[];
    protocolNumber: string;
}

const FlowChartItem = ({ flowChart, centrums, protocolNumber }: Props) => {
    const { studyId } = useParams()
    const [assignFlowChart] = useMutation<AssignType, AssignFlowChartToCentrumVariables>(assignFlowChartToCentrum);
    const [updateCycle] = useMutation<AssignType, updateCycleVariables>(updateFlowChartCycleMutation);
    const [removeCycle] = useMutation<AssignType, removeCycleVariables>(removeFlowChartCycleMutation);
    const [enableExpenses] = useMutation<any, enableExpensesVariables>(enableExpensesMutation);
    const [deleteFlowchart] = useMutation<RemoveFlowChart, RemoveFlowChartVariables>(removeFlowChartMutation);

    const [cycleStart, setCycleStart] = useState(flowChart.cycleStartsAt || null);
    const [cycleEnd, setCycleEnd] = useState(flowChart.cycleEndsAt || null);
    const [openFlowcharts, setOpenFlowcharts] = useState(false);

    useEffect(() => {
        if (cycleStart && cycleEnd && cycleStart <= cycleEnd) {
            updateCycle({
                variables: {
                    startsAt: cycleStart,
                    endsAt: cycleEnd,
                    flowChartId: flowChart.id
                },
                refetchQueries: [{ query: studyDetailQuery, variables: { studyId: Number(studyId) } }]
            })
        } else if (cycleStart && cycleEnd && cycleStart > cycleEnd) {
            setCycleEnd(null)
        }
    }, [cycleEnd, cycleStart, flowChart.id, updateCycle, studyId]);

    useEffect(() => {
        setCycleEnd(flowChart.cycleEndsAt)
        setCycleStart(flowChart.cycleStartsAt)
    }, [flowChart.cycleEndsAt, flowChart.cycleStartsAt])

    const handleAssignCentrum = (centrumId: number | string | boolean, isActive: boolean) => {
        if (isActive) {
            assignFlowChart({
                variables: {
                    studyId: Number(studyId),
                    centrumId: Number(centrumId),
                    flowChartId: flowChart.id
                },
                refetchQueries: [{ query: studyDetailQuery, variables: { studyId: Number(studyId) } }]
            })
        }
    }

    const handleRemoveCycle = () => {
        removeCycle({
            variables: {
                flowChartId: flowChart.id
            },
            refetchQueries: [{ query: studyDetailQuery, variables: { studyId: Number(studyId) } }]
        })
    }

    const handleEnableExpenses = (_: string | number | boolean, isEnable: boolean) => {
        enableExpenses({
            variables: {
                flowChartId: flowChart.id,
                isEnable
            }
        })
    }

    const handleRemoveFlowchart = () => {
        deleteFlowchart({
            variables: {
                flowChartId: flowChart.id
            },
            update(cache, result) {
                const itemToDelete = result.data?.removeFlowChart;
                const editStudyCache = cache.readQuery<StudyDetail, StudyDetailVariables>({ 
                    query: studyDetailQuery,
                    variables: {
                        studyId: Number(studyId)
                    }
                });   
              
                if(editStudyCache && itemToDelete){
                    cache.writeQuery<StudyDetail, StudyDetailVariables>({
                        query: studyDetailQuery,                    
                        variables: {
                            studyId: Number(studyId)
                        },
                        data: {
                            ...editStudyCache,
                            study: {
                                ...editStudyCache.study,
                                flowCharts: editStudyCache.study.flowCharts.filter(f => f.id !== itemToDelete.id)
                            }
                        }
                    })
                }
            }
        })
    }

    const { visits } = flowChart;
    useEffect(() => {
        if(visits.length === 0 ) {
            setOpenFlowcharts(true)
        }
    },[visits])
    const cycleVisitList = visits.filter(v => v.type === "normal" && v.orderNumber != null).map(v => ({ key: v.id, label: v.orderNumber!.toString(), value: v.orderNumber }))
    return (
        <Item>
            <Title>
                Flow Chart
          </Title>
            <Row>
                <TextField label="Version number" value={flowChart.version} />
                <TextField label="Version date" value={getFormatedDate(flowChart.versionDate) || ''} />
                <TextField label="Protocol number" value={protocolNumber} />
                <SwitchButton
                    style={{ marginLeft: 32 }}
                    label={"Enable Expenses"}
                    type="gray"
                    isActive={flowChart.allowExpenses || false}
                    value="expenses"
                    onChange={handleEnableExpenses}
                />
            </Row>
            <SubTitle>
                Site numbers
            </SubTitle>
            <Row>
                {centrums.map(centrum => (
                    <SwitchButton
                        style={{ marginRight: 16 }}
                        label={centrum.code || ""}
                        isActive={centrum.flowChart.id === flowChart.id}
                        value={centrum.id}
                        key={centrum.id}
                        type='gray'
                        onChange={handleAssignCentrum}
                    />
                ))}
            </Row>
            <CustomCollapsable overflowWhenOpen="initial" handleTriggerClick={() => setOpenFlowcharts(!openFlowcharts)} open={openFlowcharts} trigger="Show flowchart visits">
                <CustomArrow  onClick={(e) => {setOpenFlowcharts(!openFlowcharts); e.stopPropagation() }} isOpen={openFlowcharts}/>
                <Row>
                    {visits.map(visit => (
                        <Visit visit={visit} isLocked={flowChart.isLocked} key={visit.id}/>
                    ))}
                    <Visit isAddVisit flowChartId={flowChart.id} />
                </Row>
            </CustomCollapsable>
            <Flex css={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Row>
                    {cycleVisitList != null && <SelectBox list={cycleVisitList} value={cycleStart} label="Cycle start" onChange={setCycleStart} disabled={!flowChart.isDeletable}/>}
                {cycleVisitList != null && <div style={{ marginLeft: 16 }}>
                        <SelectBox list={cycleVisitList} value={cycleEnd} label="Cycle end" onChange={setCycleEnd} disabled={!flowChart.isDeletable}/>
                    </div>}
                    {flowChart.isDeletable && <div style={{ marginLeft: 32 }}>
                        <ClearCycleButton onClick={handleRemoveCycle}>- Remove cycle</ClearCycleButton>
                    </div>}
                </Row>
                {flowChart.isDeletable && <div>
                    <RemoveFlowChartButton onClick={handleRemoveFlowchart}>- Remove flowchart</RemoveFlowChartButton>
                </div>}
            </Flex>
        </Item>
    );
};

export default FlowChartItem;

const RemoveFlowChartButton = styled.button`
    border: 1px dotted #979797;
    padding: 8px 16px;
    background: none;
    border-radius: 14px;
    color: #0173E3;
`;

const Item = styled.div`
    background: white;
    height: 100%;
    padding: 36px;
    margin-bottom: 16px;
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    align-items: center;
`;

const ClearCycleButton = styled.button`
    border: 1px dotted #979797;
    padding: 8px 16px;
    background: none;
    border-radius: 14px;
    color: #0173E3;
`;


const CustomCollapsable = styled(Collapsible)`
margin: 0.5em 0;
position: relative;
`;
const CustomArrow = styled(Arrow)<{isOpen: boolean}>`
width: 16px;
height: 16px;
position: absolute;
right: 24px;
top: 10px;
${({isOpen}) => isOpen ?`transform: rotate(180deg);` : undefined}
`;