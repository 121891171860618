import React, { useState, useEffect } from 'react';
import {useNavigate, useParams } from '@reach/router';
import TopBar from '../../components/TopBar';
import styled from '@emotion/styled';
import { Container } from '../../libs/styles';
import TextInput from '../../components/TextInput';
import SelectBox from '../../components/SelectBox';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { centrumDataQuery, selectorStudiesQuery, editSubjectQuery, selectorSubjectsQuery, studyCodeQuery } from '../../graphql/queries';
import { SelectorStudies, addSubjectVariables, SubjectStatus, EditSubjectQuery, EditSubjectMutation, EditSubjectMutationVariables, StudyCodeQuery, StudyCodeQueryVariables, CentrumDataQueryVariables, CentrumDataQuery, RemoveSubjectVariables, RemoveSubject } from '../../graphql/types';
import { Title } from '../../components/Title';
import SwitchButton from '../../components/SwitchButton';
import Button from '../../components/Button';
import { addSubjectMutation, editSubjectMutation, removeSubjectMutation } from '../../graphql/mutations';
import SnackBar from '../../components/SnackBar';
import { css } from '@emotion/core';
import { colors } from '../../libs/colors';
import Header from '../../components/Header';


const bankAcountRegex = /^\d{1,6}-{0,1}\d{1,10}\/\d{4}$/;
const bankAccountShortRegex = /^\d{1,10}\/\d{4}$/

const AddSubject = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { data: studiesData } = useQuery<SelectorStudies>(selectorStudiesQuery);
    const { data: studyCodeData } = useQuery<StudyCodeQuery, StudyCodeQueryVariables>(studyCodeQuery, {
        variables: {
            id: parseInt(params.studyId, 10),
        },
        skip: !!!params.studyId,
    });
    const { data: subjectData } = useQuery<EditSubjectQuery>(editSubjectQuery, { skip: params.subjectId == null, variables: { studyId: params.studyId != null && parseInt(params.studyId, 10), subjectId: params.subjectId && parseInt(params.subjectId, 10) } });
    const { data: centrumData } = useQuery<CentrumDataQuery, CentrumDataQueryVariables>(centrumDataQuery, {
        skip: params.studyId == null,
        variables: {
            studyId: params.studyId ? parseInt(params.studyId, 10) : 0
        }
    });
    const subject = subjectData?.subjects[0];
    const [saveSubject, { error }] = useMutation<any, addSubjectVariables>(addSubjectMutation);
    const [removeSubject] = useMutation<RemoveSubject, RemoveSubjectVariables>(removeSubjectMutation);
    const [editSubject, { error: editSubjectError }] = useMutation<EditSubjectMutation, EditSubjectMutationVariables>(editSubjectMutation);

    const studies = studiesData?.studies.map(study => ({ key: `study-${study.id}`, label: study.code, value: study.id }));
    const centrums = centrumData?.centrums.map(centrum => ({ key: `centrum-${centrum.id}`, label: centrum.code || 'undefined', value: centrum.id }));

    const [code, setCode] = useState("");
    const [centrumId, setCentrumId] = useState(params.centrumId || "")
    const [studyId, setStudyId] = useState(params.studyId || "")
    const [bankAccount, setBankAccount] = useState({ value: '', err: false })
    const [paymentType, setPaymentType] = useState("bankTransfer")

    useEffect(() => {
        if (params.studyId) {
            setStudyId(params.studyId)
        }
        if (params.centrumId) {
            setCentrumId(params.centrumId)
        }
    }, [params.centrumId, params.studyId]);

    useEffect(() => {
        if (!!subject) {
            if (subject.bankAccount) {
                setPaymentType('bankTransfer');
                setBankAccount({ value: subject.bankAccount, err: false });
            }
            else {
                setPaymentType('postal')
            }
            if (subject.informationAgreement) {
                setInformedConsent(true)
            }
            if (!!subject.code) {
                setCode(subject.code);
            }
            if (subject.gdprAgreement) {
                setGdpr(true)
            }
        }
    }, [subject])
    const [informedConsentChecked, setInformedConsent] = useState(false)
    const [gdprChecked, setGdpr] = useState(false)
    const isSaveable = ((informedConsentChecked && gdprChecked) || (subject && (subject.gdprAgreement && subject?.informationAgreement))) && !!studyId && (!!centrumId || !!subject) && !!code && (paymentType === "postal" || (paymentType === "bankTransfer" && !!bankAccount))

    const handleDisclaimer = (value: any, isActive: boolean) => {
        if (value === "informedConsent") {
            setInformedConsent(isActive)
        } else {
            setGdpr(isActive)
        }
    }

    const handleRemove = async () => {
        if(subject){
            await removeSubject({
                variables: {
                    subjectId: subject.id
                }
            });
            let navCentrum = params.centrumId ? `/site/${params.centrumId}` : '';
            navigate(`${navCentrum}/study/${params.studyId}`);
        }
    }

    const handleSave = async () => {
        if (subject == null) {
            try {
                await saveSubject({
                    refetchQueries: [{ query: selectorSubjectsQuery, variables: { studyId: parseInt(studyId, 10) } }],
                    variables: {
                        subjectInput: {
                            studyId: parseInt(studyId, 10),
                            centrumId: parseInt(centrumId, 10),
                            bankAccount: paymentType === 'bankTransfer' ? bankAccount.value : null,
                            code,
                            status: SubjectStatus.IN_SCREENING,
                            gdpr: gdprChecked,
                            informedConsent: informedConsentChecked
                        }
                    }
                })
                navigate(-1);
            } catch (e) { }
        }
        else {
            try {
                // means user tries to change his payment method  
                if ((subject.bankAccount == null && paymentType === "bankTransfer") || (subject.bankAccount && paymentType === "postal")) {
                    alert('This action will change payment method for all planned payments for this subject.')
                }
                await editSubject({
                    variables: {
                        input: {
                            id: subject.id.toString(),
                            bankAccount: paymentType === 'bankTransfer' ? bankAccount.value : null,
                        },
                    },
                })
                navigate(-1);
            } catch (e) { }
        }
    }

    const handleBankAcount = () => {
        setBankAccount({ value: bankAccount.value, err: bankAccount.value.includes("-") ? !bankAcountRegex.test(bankAccount.value) : !bankAccountShortRegex.test(bankAccount.value) })
    }

    const paymentTypes = [{ label: "Bank Transfer", value: "bankTransfer", key: "bankTransfer" }, { label: "Postal Order", value: "postal", key: "bankTransfer" }]

    let navigationData = [];
    if(centrumId){
        navigationData.push({ text: `Site ${centrumData?.centrums[0].code}`, path: `/site/${centrumId}` })
    }
    navigationData.push({ text: `Study ${studyCodeData?.study.code ?? ''}`, path: `/site/${centrumId}/study/${studyId}` })
    if(params.subjectId){
        navigationData.push({ text: `Subject ${subjectData?.subjects[0].code}`, path: `/site/${centrumId}/study/${studyId}/subject/${params.subjectId}` })
    }
    navigationData.push( { text: subject?.id ? 'Edit Subject' : 'Add Subject' })

    return (
        <>
            <TopBar />
            <Header data={navigationData} />
            <Container css={css`position: relative;`}>
                <Row>
                    <Item css={css`width: 50%; margin-right: 2em;`}>
                        <Row>
                            <TextInput label="Subject ID" disabled={!!params.subjectId} value={code} onChange={setCode} style={{ width: '180px' }} />
                            <TextInput label="Status" style={{ marginLeft: 16, width: '100%' }} value={subject?.status ?? 'In screening'} disabled />
                        </Row>
                        <Row>
                            {studies && <SelectBox list={studies} label="Study" minWidth="180px" value={Number(studyId)} disabled />}
                            {centrums && <SelectBox list={centrums} label="Site Number" style={{ width: '100%', marginLeft: 16 }} value={subject ? subject.centrumId : Number(centrumId)} disabled={params.centrumId != null || !!subject} onChange={setCentrumId} />}
                        </Row>
                        <Row>
                            <SelectBox list={paymentTypes} label="Payment Type" minWidth="180px" value={paymentType} onChange={setPaymentType} />
                            {paymentType === "bankTransfer" && <TextInput err={bankAccount.err} label="Bank Account" value={bankAccount.value} onChange={value => setBankAccount({ value: value, err: false })} onBlur={handleBankAcount} style={{ marginLeft: 16, width: '100%' }} />}
                            {paymentType === 'bankTransfer' && bankAccount.err && <ErrorText>Invalid bank account</ErrorText>}
                        </Row>
                    </Item>
                    <Item css={css`width: 50%;`}>
                        <Title>Disclaimers</Title>
                        <div>
                            <SwitchButton label="Subject has signed study PIS/ICF" value="informedConsent" isActive={subject != null && subject.informationAgreement != null ? subject.informationAgreement : informedConsentChecked} onChange={handleDisclaimer} type="gray" />
                            <div style={{ marginTop: 8 }}>
                                <SwitchButton label="Subject has signed study GDPR Consent" value="gdpr" isActive={subject != null && subject.gdprAgreement != null ? subject.gdprAgreement : gdprChecked} onChange={handleDisclaimer} type="gray" style={{ width: '100%' }} />
                            </div>
                        </div>
                    </Item>
                </Row>
                <Row css={css`justify-content: space-between;`}>
                    {subject?.notStarted && <Button onClick={handleRemove}>Remove</Button>}
                    <Button disabled={!isSaveable || (bankAccount.err && paymentType === 'bankTransfer')} onClick={handleSave}>Save</Button>
                </Row>
                {error && <SnackBar message={error?.message.split(":")[1]} />}
                {editSubjectError && <SnackBar message={editSubjectError?.message.split(":")[1]} />}

            </Container>
        </>
    )
};

export default AddSubject;

const Item = styled.div`
    background: white;
    height: 100%;
    padding: 36px;
    margin-bottom: 16px;
    max-width: 417px;
`;

const Row = styled.div`
    display: flex;
    margin-bottom: 16px;
`;

const ErrorText = styled.div`
    color: ${colors.danger};
 text-transform: uppercase;
  font-weight: bold;
   position: absolute;
   font-size: 10px;
   top: 230px;
   left: 240px;
`;