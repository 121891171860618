import React, { useState } from 'react';
import Modal from '../../components/Modal';
import TextInput from '../../components/TextInput';
import styled from '@emotion/styled';
import Button from '../../components/Button';
import { addCentrumToStudyMutation } from '../../graphql/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { addCentrumToStudyVariables, StudyDetail_study_flowCharts, StudyDetail_study_centrums, CentrumDataQuery } from '../../graphql/types';
import { useParams } from '@reach/router';
import { centrumDataQuery, studyDetailQuery } from '../../graphql/queries';
import SelectBox from '../../components/SelectBox';
import SnackBar from '../../components/SnackBar';

interface Props {
    isOpen: boolean;
    onIsOpenChange: (isOpen: boolean) => void;
    flowCharts: StudyDetail_study_flowCharts[];
    studyCenters: StudyDetail_study_centrums[];
}

const AddCentrumModal = ({ isOpen, onIsOpenChange, flowCharts, studyCenters } : Props) => {
    const [centrumId, setCentrumId] = useState("");
    const [centrumCode, setCentrumCode] = useState("");
    const [flowChartId, setFlowChartId] = useState("");

    const { studyId } = useParams()
    const [addCentrum, { error }] = useMutation<any, addCentrumToStudyVariables>(addCentrumToStudyMutation);
    const { data } = useQuery<CentrumDataQuery>(centrumDataQuery);

    const handleAddCenter = async () => {
        try{
            await addCentrum({
                variables: {
                    studyId: parseInt(studyId, 10),
                    centrumId: parseInt(centrumId, 10),
                    centrumCode,
                    flowChartId: parseInt(flowChartId, 10)
                },
                refetchQueries: [{ query: studyDetailQuery, variables: { studyId: parseInt(studyId, 10) } }]
            });
            onIsOpenChange(false)
            setCentrumId("");
            setCentrumCode("");
            setFlowChartId("");
        } catch (e) {}
    }

    const flowChartList = flowCharts.map(f => ({ label: f.version.toString(), key: f.id, value: f.id }))
    let centrumList = data?.centrums.map(c => ({ label: c.name, key: c.id, value: c.id }))
    const isButtonDisabled = centrumId === "" || centrumCode === "" || flowChartId === "";
    const studyCentersId = studyCenters.map(c => c.id);
    centrumList = centrumList && centrumList?.filter(c => !studyCentersId.includes(c.value) )
    
    return(
        <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={onIsOpenChange}
          contentLabel="Add Site"
        > 
            <Row>
                <SelectBox list={centrumList || []} label="Site" minWidth="250px" listHeight='200px' value={centrumId} onChange={setCentrumId}/>
                <SelectBox list={flowChartList} label="Flow chart" minWidth="250px" value={flowChartId} onChange={setFlowChartId} style={{ marginLeft: 16}}/>
                <TextInput label="SITE NUMBER" value={centrumCode} onChange={setCentrumCode} style={{ marginLeft: 16 }}/>
            </Row>
            <Center>
                <Button color='none' onClick={() => onIsOpenChange(false)}>Cancel</Button>
                <Button onClick={handleAddCenter} disabled={isButtonDisabled}>Save</Button>
            </Center>
            
        </Modal>
         {error != null && <SnackBar message={error?.message.split(":")[1]}/>}
        </div>
    )
}

export default AddCentrumModal;

const Row = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
`;
