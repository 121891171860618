import React from 'react';
import Modal from 'react-modal';

interface Props {
    isOpen: boolean;
    onRequestClose: (isOpen: boolean) => void;
    children: any;
    contentLabel?: string;
}

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      border                : 'none',
      padding               : '32px',
      overflow              : 'initial'
    },
    overlay: {
        background          : 'rgba(0, 0, 0, 0.29)',
        zIndex              : 30
    }
  };

const CustomModal = ({ isOpen, onRequestClose, children, contentLabel } : Props) => {
    return(
        <Modal
          isOpen={isOpen}
          onRequestClose={() => onRequestClose(false)}
          style={customStyles}
          contentLabel={contentLabel}
        >
         {children}
        </Modal>
    )
}

export default CustomModal;
