import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../libs/colors';

interface Props {
    color?: 'primary' | 'danger' | 'none';
    children?: string;
    disabled?: React.ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    style?: any;
    small?: boolean;
}

const Button = ({ color, children, onClick, disabled, style,small }: Props) => {
    return (
        <StyledButton color={color} small={small} onClick={onClick} disabled={disabled} style={style}>
            {children}
        </StyledButton>
    );
}

export default Button;

const StyledButton = styled.button<{ color?: 'primary' | 'danger' | 'none', small?: boolean }>`
padding: ${props => props.small ? `0.6em 1.25em` : `1.2em 2.5em`};
background-color: ${({ color }) => color === 'primary' || color == null ? colors.primary : color === 'none' ? `transparent` : colors.danger};
color: ${({color}) => color === 'none' ? colors.link : 'white'};
border: none;
outline: none;
cursor: pointer;
font-size: 14px;
font-weight: ${({color}) => color === 'none' ? 'normal' : 'bold'};
line-height: 16px;
border-radius: 2px;
:disabled {
    background: gray;
    cursor: default;
}
`;