import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { RouteComponentProps, useNavigate } from '@reach/router';
import logo from '../../icons/logo.png';
import SnackBar from '../../components/SnackBar';
import { useMutation } from '@apollo/react-hooks';
import { sendResetPasswordEmailMutation } from '../../graphql/mutations';
import { SendResetPasswordEmail, SendResetPasswordEmailVariables } from '../../graphql/types';

const ForgottenPassword = (_: RouteComponentProps) => {
    const [email, setEmail] = useState('');
    const [showSnackBar, setSnackBar] = useState<{ message: string, type: 'done' | 'error' } | null>(null);
    const [sendEmail, { data }] = useMutation<SendResetPasswordEmail, SendResetPasswordEmailVariables>(sendResetPasswordEmailMutation)
    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            if (data?.sendResetPasswordEmail) {
                setSnackBar({ type: 'done', message: 'RESET link was sent.' })

                setTimeout(() => { navigate('/login', { replace: true }) }, 2000)
            }
            else {
                setSnackBar({ type: 'error', message: "This email does not exist" });
            }
        }
    }, [data, navigate])
    const resetPasswordHandler = async () => {
        sendEmail({
            variables: {
                email
            }
        })

    }
    return (
        <LoginContainer>
            <ForgottenPasswordContainer>
                <img width={120} alt='clinpas logo' src={logo} />
                <div style={{ width: 300 }}>
                    <TextInput onChange={setEmail} value={email} label="Email" style={{ marginTop: 32 }} />
                    <Button style={{ marginTop: 16, width: '100%' }} disabled={!email} onClick={resetPasswordHandler}>Reset Password</Button>
                </div>
                {showSnackBar && <SnackBar message={showSnackBar.message} type={showSnackBar.type} />}
            </ForgottenPasswordContainer>
        </LoginContainer>
    )
}

export default ForgottenPassword;

const ForgottenPasswordContainer = styled.div`
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

const LoginContainer = styled.div`
width: 100%;
height: 100vh;
background-color: #fafafa;
`;