import React from 'react';
import SelectBox from './SelectBox';
import { css } from '@emotion/core';
import { useNavigate, useLocation } from '@reach/router';
import { useMe } from '../hooks';
import { UserRole } from '../graphql/types';
interface Props {
    siteNumbers: {key: string,label: string, value: string | null}[];
    subjects: {key: string,label: string, value: number | null}[];
    siteNumber?: string | null;
    setSiteNumber: (value: string | null) => void;
    subject: number | null;
    setSubject: (value: number | null) => void;
    studyId: string | undefined;
    paymentType?: string;
    refreshSubjects: () => void;
    refreshSites: () => void;
}


const FilterBar = ({siteNumbers,subjects, siteNumber, setSiteNumber, subject, setSubject, studyId, refreshSites, refreshSubjects }: Props) => {
    const { me } = useMe();
    const isUser = me?.role === UserRole.CENTER_USER;
    const isClient = me?.role === UserRole.CLIENT_USER;
    const location = useLocation();
    const locationArr = location.pathname.split("/");

    const createFilter = (site?: string | null, subject?: number | null) => {
        return `?${site != null ? ('site=' + site) : ''}${subject ? ((site != null ? '&' : '') + 'subject=' + subject) : ''}`;
    }

    const handleSetSite = (item: string) => {
        setSiteNumber(item); 
        navigate(`/${locationArr[1]}/${studyId}${isClient ? '/view' : ''}${(item != null || subject != null) ? createFilter(item,subject) : ''}`)
    }

    const handleSetSubject = (item: number) => {
        setSubject(item);
        navigate(`/${locationArr[1]}/${studyId}${isClient ? '/view' : ''}${(subject != null || item != null) ? createFilter(siteNumber,item) : ''}`)
    }

    const navigate = useNavigate();
    return(
        <div css={css`display: flex; margin: 0.5em;position: relative; z-index: 20;`}>
            <SelectBox  list={siteNumbers} label="Site Number" disabled={siteNumbers?.length === 1 || isUser}  minWidth="200px" value={siteNumber} onChange={handleSetSite} onOpen={() => refreshSites()}/>
            <SelectBox style={{margin: '0 1em'}} disabled={subjects.length === 1} list={subjects} label="Subject" minWidth="200px" value={subject} onChange={handleSetSubject} onOpen={() => refreshSubjects()}/>
        </div>
    )
}

export default FilterBar;