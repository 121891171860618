import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../libs/colors';
import { useMutation } from '@apollo/react-hooks';
import { uploadPostalFileMutation } from '../../graphql/mutations';
import { uploadPostalFile, uploadPostalFileVariables } from '../../graphql/types';
import { useParams } from '@reach/router';
import { unresolvedSubjectVisitQuery } from '../../graphql/queries';
import axios from 'axios';
import { ReactComponent as PostSVG } from '../../icons/post.svg';
import { ReactComponent as UploadSVG } from '../../icons/upload.svg';
import { css } from '@emotion/core';
import Tippy from '@tippyjs/react';

interface Props {
  subjectVisitId: number;
  paymentId: number;
  hasPostalForm: boolean;
  disabled: boolean;
  hasCompensations: boolean;
  hasRemovedForm: boolean;
  isAbleToAddPostalOrderForm: boolean;
}

const DownloadUpload = ({ subjectVisitId, paymentId, hasPostalForm, disabled, hasCompensations, hasRemovedForm,isAbleToAddPostalOrderForm }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { subjectId } = useParams();
  const [uploadIt] = useMutation<uploadPostalFile, uploadPostalFileVariables>(uploadPostalFileMutation);


  const handleUpload = ({ target: { validity, files } }: React.ChangeEvent<HTMLInputElement>) => {
    validity.valid && files && files[0]
      && uploadIt({
        variables: {
          input: {
            postalFile: files[0],
            subjectVisitId
          }
        },
        refetchQueries: [
          { query: unresolvedSubjectVisitQuery, variables: { subjectId: parseInt(subjectId, 10) } }
        ]
      });
  }

  const handleDownload = () => {
    axios({
      url: `/api/download/postal_form/${paymentId}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `postalForm_${paymentId}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  }
  return (
    <>
      <input type="file" ref={inputRef} style={{ display: 'none' }} onChange={handleUpload} />
      {hasCompensations && isAbleToAddPostalOrderForm && !hasPostalForm && !disabled &&
        <AttachButton onClick={() => inputRef.current && inputRef.current.click()}>
          Attach postal order form
            </AttachButton>
      }
      {(hasPostalForm || hasRemovedForm) &&
        <div css={css` position: absolute;left: 16px; bottom: 16px;`}>
          <Tippy delay={[500, 0]} placement='bottom' content="Download postal order form">
            <PostalButton disabled={disabled} onClick={handleDownload}>
              <PostSVG style={{ width: 30, height: 30 }} />
            </PostalButton>
          </Tippy>
          {!disabled &&
            <Tippy delay={[500,0]} placement='bottom' content="Upload new version">
              <PostalButton disabled={disabled} onClick={() => inputRef.current && inputRef.current.click()}>
                <UploadSVG style={{ width: 30, height: 30 }} />
              </PostalButton>
            </Tippy>}
        </div>
      }
    </>
  )
}

export default DownloadUpload;

const AttachButton = styled.button`
display: flex;
justify-content: space-between;
align-items: center;
border-style: dotted;
border-width: 1px;
border-radius: 14px;
margin: 0.5em 0;  
padding: 0.5em 1em 0.5em 1em;
cursor: pointer;
outline: none;
width: 100%;
background-color: transparent;
color: ${colors.link};
border-radius: 14px;
font-size: 14px;
`;
const PostalButton = styled.button`
    border: none;
 background: none;
`;