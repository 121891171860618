import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../libs/colors';
import { selectedPayments_selectedPayments } from '../../graphql/types';
import { getFormatedDate } from '../../helpers';
import Status from '../../components/Status';
import OptionButton from './OptionButton';

interface Props {
    p: selectedPayments_selectedPayments;
    showSentAt: boolean;
    onCancel?: (paymentId: number) => void;
    onPay?: (paymentId: number) => void;
    loading?: number;
}

const PaymentItem = ({ p, showSentAt, onCancel, onPay, loading }: Props) => {
    return(
        <Row>
            <Item>
                <Label>Study</Label>
                <Text>{p.subjectVisit.visit.study.code}</Text>
            </Item>
            <Item>
                <Label>Subject</Label>
                <Text>{p.subjectVisit.subjectCode}</Text>
            </Item>
            <Item>
                <Label>Visit number</Label>
                <Text>{p.subjectVisit.orderNumber}</Text>
            </Item>
            <Item>
                <Label>Variable symbol</Label>
                <Text>{p.variableSymbol}</Text>
            </Item>
            <Item>
                <Label>Price</Label>
                <Text>{p.price} CZK</Text>
            </Item>
            {showSentAt && 
                <Item>
                    <Label>Sent</Label>
                    <Text>{p.status === "denied" ? "N/A" : p.sentAt ?  getFormatedDate(p.sentAt) : "N/A"}</Text>
                </Item>
            }
            <Item>
                <Label>Status</Label>
                <Status 
                    type="payment" 
                    incomingStatus={p.status} 
                    tippyMessage={p.errorMessage} 
                    subLabel={(p.status === "inProgress" && onCancel == null) 
                        ? `(${loading})` 
                        : undefined
                    }
                />
            </Item>
            {onPay && onCancel && 
                <Item css={{ justifyContent: 'flex-end', width: '5%'}}>
                <OptionButton onCancel={onCancel} onPay={onPay} paymentId={p.id}/>
                </Item>
            }
        </Row>
    )
}

export default PaymentItem;


const Row = styled.div`
    display: flex;
`;

const Item = styled.div`
padding: 1em 0em 1em 0em;
width: 20%;
display: flex;
flex-direction: column;
`;

const Label = styled.span`
color: ${colors.darkGray};
font-size: 10px;
margin-bottom: 1em;
`;

const Text = styled.span`
font-weight: 500;
font-size: 14px;
word-break: break-all;
padding-right: 0.5em;
`;