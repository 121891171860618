import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../libs/colors';
import { Payments_paymentInfo_paymentData_payments, Payments_paymentInfo_paymentData_payments_subjectVisit_subjectVisitCompensations, updatePaymentVariables, CompensationStatus, UserRole } from '../../graphql/types';
import PayStatus from '../../components/Status';
import { css } from '@emotion/core';
import PriceFormat from '../../components/PriceFormat';
import SwitchButton from '../../components/SwitchButton';
import { ReactComponent as PostSVG } from '../../icons/post.svg';
import { updatePaymentMutation } from '../../graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import axios from 'axios';
import { paymentsQuery } from '../../graphql/queries';
import { getFormatedDate } from '../../helpers';
import SnackBar from '../../components/SnackBar';
import moment from 'moment';
import { useMe } from '../../hooks';

interface Props {
    data: Payments_paymentInfo_paymentData_payments
    page: number;
    count: number;
    studyId?: string | null;
    subjectId?: number | null;
    siteNumber: string | null;
    type: string;
}

const PaymentDetail = ({ data, page, type, count, subjectId, studyId, siteNumber }: Props) => {
    const { subjectVisit, status, price, id } = data;
    const [updatePayment, { error: updatePaymentError }] = useMutation<any, updatePaymentVariables>(updatePaymentMutation);

    const paymentStudyId = data.subjectVisit.visit.study.id;
    const { me } = useMe();
    const isClient = me?.role === UserRole.CLIENT_USER;
    const [showError, setError] = useState<{ message: string, type?: "error" | "done" } | null>(null);
    const handleMakePayment = async (paymentId: number | string | boolean) => {
        try {
            await updatePayment({
                variables: {
                    paymentId: Number(paymentId),
                    studyId: paymentStudyId
                },
                refetchQueries: [{
                    query: paymentsQuery, variables: {
                        studyId: parseInt(studyId || '1', 10),
                        type: type,
                        siteNumber: siteNumber,
                        subjectId: subjectId,
                        page: page,
                        count: count,
                    }
                }],

            })
        } catch (e) { }
    }

    useEffect(() => {
        if (updatePaymentError) {
            setError({ message: 'There is not enough of budget for this payment', type: 'error' })
        }
    }, [updatePaymentError])

    const handleDownload = () => {
        axios({
            url: `/api/download/postal_form/${id}`,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `postalForm_${id}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
    }
    const isAdmin = me?.role === UserRole.ADMIN;

    const notSolvedCompensations = data.subjectVisit.subjectVisitCompensations.filter(c => c.status === CompensationStatus.DELAYED || c.status === CompensationStatus.PLANNED);
    const compensations = subjectVisit.subjectVisitCompensations.filter(c => !c.compensation?.isExpense);
    const expenses = subjectVisit.subjectVisitCompensations.filter(c => !!c.compensation?.isExpense);

    return (
        <>
            <PaymentDetailBox>
                <SideBar />
                <TopBar>
                    <PaymentBox css={css`width: 10%;`}>
                        <Label>
                            Site Number
                        </Label>
                        <Text>
                            {subjectVisit.studyCentrum.code}
                        </Text>
                    </PaymentBox>
                    <PaymentBox css={css`width: 10%;`}>
                        <Label>
                            Study
                    </Label>
                        <Text>
                            {subjectVisit.visit.study.code}
                        </Text>
                    </PaymentBox>
                    <PaymentBox css={css`width: 15%;`}>
                        <Label>
                            Subject ID
                    </Label>
                        <Text>
                            {subjectVisit.subjectCode}
                        </Text>
                    </PaymentBox>
                    <PaymentBox css={css`width: 10%;`}>
                        <Label>
                            Visit Number
                    </Label>
                        <Text>
                            {subjectVisit.orderNumber}
                        </Text>
                    </PaymentBox>
                    <PaymentBox css={css`width: 15%;`}>
                        <Label>
                            Visit
                    </Label>
                        <Text>
                            {subjectVisit.visit.name !== null ? subjectVisit.visit.name : subjectVisit.visit.orderNumber} <br />{subjectVisit.date != null && getFormatedDate(subjectVisit.date)}
                        </Text>
                    </PaymentBox>
                    <PaymentBox css={css`width: 15%;`}>
                        <Label>
                            Variable symbol
                    </Label>
                        <Text css={css`text-transform: capitalize;`}>
                            {data.variableSymbol}
                        </Text>
                    </PaymentBox>
                    <PaymentBox css={css`width: 15%;`}>
                        <Label>
                            Payment Status
                    </Label>
                        <Text css={css`text-transform: capitalize;`}>
                            <PayStatus
                                incomingStatus={status}
                                type="payment"
                                tippyMessage={(status === "denied" || status === "failed") ? data.errorMessage : undefined}
                            />
                        </Text>
                    </PaymentBox>
                    {status === "paid" && <PaymentBox css={css`width: 10%;`}>
                        <Label>
                            Price
                    </Label>
                        <Text>
                            <PriceFormat value={price} />
                        </Text>
                    </PaymentBox>
                    }
                    {isAdmin && data.isPostalOrder && notSolvedCompensations.length < 1 && price > 0 && 
                        <PaymentBox css={css`width: 20%; align-items: flex-end; padding-right: 32px;`}>
                            <SwitchButton 
                                label={status === "paid" ? "Paid" : "Pay"} 
                                isActive={status === "paid"} 
                                type='gray' 
                                value={id} 
                                onChange={handleMakePayment} 
                            />
                        </PaymentBox>
                    }
                    {data.isPostalOrder && data.hasPostalForm && !isClient && 
                        <PaymentBox css={css`width: 10%; align-items: flex-end; padding-right: 32px;`}>
                            <PostalButton onClick={handleDownload}>
                                <PostSVG style={{ width: 30, height: 30 }} />
                            </PostalButton>
                        </PaymentBox>
                    }
                </TopBar>
                <BottomBar>
                    <CompensationTable>
                        {compensations.length > 0 && <>
                            <thead>
                                <tr>
                                    <CompensationHeaderItem css={css`width: 40%;`}>Compensations</CompensationHeaderItem>
                                    <CompensationHeaderItem css={css`width: 20%;`}>Item Status</CompensationHeaderItem>
                                    <CompensationHeaderItem css={css`width: 20%;`}>Date</CompensationHeaderItem>
                                    <CompensationHeaderItem css={css`width: 20%;`}>ID</CompensationHeaderItem>
                                </tr>

                            </thead>
                            <tbody>
                                {compensations.filter(c => !c.compensation?.isExpense).map(compensation => <CompensationDetail key={`compensation-${compensation.id}`} data={compensation} />)}
                            </tbody></>}
                        {expenses.length > 0 && <>
                            <thead>
                                <tr>
                                    <CompensationHeaderItem css={css`width: 40%;`}>Expenses</CompensationHeaderItem>
                                    <CompensationHeaderItem css={css`width: 20%;`}>Item Status</CompensationHeaderItem>
                                    <CompensationHeaderItem css={css`width: 20%;`}>Date</CompensationHeaderItem>
                                    <CompensationHeaderItem css={css`width: 20%;`}>ID</CompensationHeaderItem>
                                </tr>
                            </thead>
                            <tbody>
                                {expenses.filter(c => !!c.compensation?.isExpense).map(compensation => <CompensationDetail key={`compensation-${compensation.id}`} data={compensation} />)}
                            </tbody></>}
                    </CompensationTable>
                </BottomBar>
            </PaymentDetailBox>
            {showError && <SnackBar type={showError.type} message={showError.message} resetError={setError} />}
        </>
    )
}

export default PaymentDetail;

interface CompensationDetailProps {
    data: Payments_paymentInfo_paymentData_payments_subjectVisit_subjectVisitCompensations;
}


const CompensationDetail = ({ data }: CompensationDetailProps) => {
    const { compensation, status, visitCompensationId, updatedAt, createdAt } = data;
    const compensationDate = status !== CompensationStatus.PLANNED ? moment(updatedAt || Number(createdAt)).format("DD-MM-YYYY") : 'N/A';
    return (
        <tr>
            <CompensationBodyItem>
                <div css={css`display: flex;`}>
                    <div css={css`width: 50%;`}>
                        <CompensationText>{compensation?.name}</CompensationText>
                    </div>
                    <CompensationText><PriceFormat value={compensation?.price || data.price} /></CompensationText>
                </div>
            </CompensationBodyItem>
            <CompensationBodyItem>
                <PayStatus small incomingStatus={status} type="paymentCompensation" />
            </CompensationBodyItem>
            <CompensationBodyItem>
                <CompensationText>{compensationDate}</CompensationText>
            </CompensationBodyItem>
            <CompensationBodyItem>
                <CompensationText>{visitCompensationId}</CompensationText>
            </CompensationBodyItem>
        </tr>
    );

}

const PaymentDetailBox = styled.div`
border-radius: 2px;
width: 100%;
position: relative;
padding-bottom: 8px;
background: white;
`;

const PaymentBox = styled.div`
padding: 1em 0em 1em 0em;
display: flex;
flex-direction: column;
`;

const Label = styled.span`
color: ${colors.darkGray};
font-size: 10px;
margin-bottom: 1em;
`;

const Text = styled.span`
font-weight: 500;
font-size: 14px;
word-break: break-all;
padding-right: 0.5em;
`;

const TopBar = styled.div`
display: flex;
padding-left: 1em;
background-color: ${colors.menuPink};
`;

const BottomBar = styled.div`
display: flex;
align-items: center;
padding-bottom: 1em;
padding-top: 0.5em;
`;

const SideBar = styled.div`
background-color: ${colors.mediumGray};
position: absolute;
height: 100%;
width: 5px;
left: 0px;
top: 0px;
border-top-left-radius: 2px;
border-bottom-left-radius: 2px; 
`;

const CompensationText = styled.span`
font-weight: 500;
font-size: 12px;
`;

const CompensationHeaderItem = styled.th`
color: ${colors.darkGray};
font-size: 10px;
text-align: left;
font-weight: 400;
`;
const CompensationTable = styled.table`margin-left: 1em; width: 100%;`;

const CompensationBodyItem = styled.th`
text-align: left;
`;

const PostalButton = styled.button`
    border: none;
 background: none;
 outline: none;
`;