import React from 'react';
import styled from '@emotion/styled';
import yesIcon from '../../icons/done.svg';
import noIcon from '../../icons/not-done.svg';

interface Props {
    text: string;
    onClickYes?: () => void;
    onClickNo?: () => void;
}

const OptionDialog = ({ text, onClickYes, onClickNo }: Props) => {
    return (
        <Container>
            {text}
            <Button onClick={onClickYes}>
                <img alt='yes icon' src={yesIcon} />
            </Button>
            <Button onClick={onClickNo}>
                <img alt='no icon' src={noIcon} />
            </Button>
        </Container>
    )
}

export default OptionDialog;

const Container = styled.div`
    display: flex;
    align-items: center;
    background: #5F6164;
    border-radius: 10px;
    padding: 4px 16px;
    color: white;
    font-weight: 500;
    margin-top: 16px;
`;

const Button = styled.button`
    border: none;
    outline: none;
    padding: 0;
    margin-left: 16px;
    background: none;
`