import React, { useState, useEffect } from 'react';
import { Chart as GoogleChart } from "react-google-charts";
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import clinpas from '../../icons/clinpas.svg';
import { useQuery } from '@apollo/react-hooks';
import { budgetGraphqQuery } from '../../graphql/queries';
import { useParams } from '@reach/router';
import { BudgetGraph, BudgetGraphVariables, StudyDetail_study_incomingBudget } from '../../graphql/types';

interface Props {
    actualBudget: number;
    incomingBudget: StudyDetail_study_incomingBudget[];
}

const options = {
    pieHole: 0.85,
    enableInteractivity: false,
    is3D: false,
    legend: 'none',
    'tooltip': {
        trigger: 'none'
    },
    pieSliceText: 'none',
    colors: ['#D5A63F', '#d468fa', '#01E398']
};

const BudgetChart = ({ actualBudget, incomingBudget }: Props) => {
    const { studyId } = useParams();
    const [loading, setLoading] = useState(true);

    const { data } = useQuery<BudgetGraph, BudgetGraphVariables>(budgetGraphqQuery, {
        variables: {
            studyId: Number(studyId)
        },
        fetchPolicy: 'cache-and-network'
    });

    const usedBudget = data?.paymentInfo.totalPayed || 0;
    const inPerspective = data?.paymentInfo.totalPlanned || 0;
    const approvedBudget = data?.paymentInfo.totalApproved || 0;

    let totalIncome = 0;
    incomingBudget.forEach(b => {
        totalIncome += b.price
    })

    const chartData = [
        ["Type", "Value"],
        ["Used budget", usedBudget],
        ["Approved budget", approvedBudget],
        ["Actual budget", actualBudget],
    ];

    const getPercentilValue = () => {
        if(usedBudget === 0){
            return '0%';
        } 
        const result = ((usedBudget + approvedBudget) / totalIncome) * 100;
        return `${Math.round(result * 10) / 10}%`
    }

    return (
        <div css={css`position: relative;`}>
           {!loading && <>
                <BudgetPercentilBox>
                    <PercentilText>{getPercentilValue()}</PercentilText>
                    <p css={css`margin-top: 0;`}>Income used</p>
                </BudgetPercentilBox>
                <ValueBox top='30px' left='0'>
                    <p css={css`margin-bottom: 0;`}>Account Balance</p>
                    <Value css={{ color: '#01E398'}}>{`${actualBudget} CZK`}</Value>
                </ValueBox>
                <ValueBox top='30px' left='310px'>
                    <p css={css`margin-bottom: 0;`}>Paid</p>
                    <Value css={{ color: '#D5A63F'}}>{`${usedBudget} CZK`}</Value>
                </ValueBox>
                <ValueBox top='150px' left='0'>
                    <p css={css`margin-bottom: 0;`}>Total Income</p>
                    <Value>{`${totalIncome} CZK`}</Value>
                </ValueBox>
                <ValueBox top='90px' left='310px'>
                    <p css={css`margin-bottom: 0;`}>Approved</p>
                    <Value css={{ color: '#d468fa'}}>{`${approvedBudget} CZK`}</Value>
                </ValueBox>
                <ValueBox top='150px' left='310px'>
                    <p css={css`margin-bottom: 0;`}>Planned</p>
                    <Value>{`${inPerspective} CZK`}</Value>
                </ValueBox>
            </>}
            <GoogleChart
                loader={<ChartLoader setLoading={setLoading} />}
                css={css`margin-left: 20%;`}
                chartType="PieChart"
                width="80%"
                height="250px"
                data={chartData}
                options={options}
            />
        </div>
    )
}

export default BudgetChart;

const BudgetPercentilBox = styled.div`
position: absolute;
z-index: 10;
display: flex;
flex-direction: column;
align-items: center;
color: #74798C;
top: 100px;
justify-content: center;
left: -6px;
width: 100%;
`;

const PercentilText = styled.span`
font-size: 26px;
font-weight: bold;
color: #282D30;
`;

const ValueBox = styled.div<{ top?: string; left?: string; right?: string; }>`
z-index: 10;
position: absolute;
${({ top }) => !!top ? `top: ${top};` : undefined}
${({ left }) => !!left ? `left: ${left};` : undefined}
${({ right }) => !!right ? `right: ${right};` : undefined}
color: #979797;
font-size: 14px;
`;

const Value = styled.span`
font-size: 18px;
color: black;
font-weight: 500;
`;

const LoadingBox = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 250px;
`;

const Clinpas = styled.img`
width: 24px;
height:24px;
-webkit-animation:spin 0.6s infinite;
    -moz-animation:spin 0.6s infinite;
    animation:spin 0.6s infinite;
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
`;

interface ChartLoader {
    setLoading: (value: boolean) => void;
}

const ChartLoader = ({ setLoading }: ChartLoader) => {

    useEffect(() => {

        return () => {
            setLoading(false);
        }
    }, [setLoading])
    return (
        <LoadingBox><Clinpas src={clinpas} alt='clinpas logo' /></LoadingBox>
    )
}