import React, { useState, useEffect } from 'react';
import TextInput from '../../components/TextInput';
import styled from '@emotion/styled';
import SelectBox from '../../components/SelectBox';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ClientsQuery, StudyDetail_study, addStudy as AddStudyMutationType, addStudyVariables } from '../../graphql/types';
import { clientsQuery, studyDetailQuery } from '../../graphql/queries';
import { css } from '@emotion/core';
import Button from '../../components/Button';
import { addStudyMutation } from '../../graphql/mutations';
import { useNavigate } from '@reach/router';

interface Props {
    data?: StudyDetail_study | null;
    studyId?: string | null;
}

interface StudyType {
    code: { value: string, error: null | string };
    eudra: { value: string, error: null | string };
    plannedSubjects: { value: string, error: null | string };
    plannedBudget: { value: string, error: null | string };
    protocolNumber: { value: string, error: null | string };
    client: { value: number, error: null | string };
    sponsor: { value: string, error: null | string };
}

const initState = {
    code: { value: "", error: null },
    eudra: { value: "", error: null },
    plannedSubjects: { value: "0", error: null },
    plannedBudget: { value: "0", error: null },
    client: { value: 0, error: null },
    protocolNumber: { value: "", error: null },
    sponsor: { value: "", error: null },
}

const StudyInfo = ({ data, studyId }: Props) => {
    const [addStudy, { data: responseData }] = useMutation<AddStudyMutationType, addStudyVariables>(addStudyMutation);
    const [study, setStudy] = useState<StudyType>(initState)
    const { data: clientsData } = useQuery<ClientsQuery>(clientsQuery);
    const clients = clientsData?.clients?.map(client => { return { key: `client-${client.id}`, label: client.name, value: client.id } })

    useEffect(() => {
        if (!!data) {
            setStudy({
                code: { value: data.code, error: null },
                eudra: { value: data.eudraCtNumber, error: null },
                plannedSubjects: { value: data.plannedVisitorsAmout.toString(), error: null },
                plannedBudget:  { value: data.plannendBudget.toString(), error: null },
                client: { value: data.clientId ? data.clientId : 0, error: null },
                protocolNumber: { value: data.protocolNumber.toString(), error: null },
                sponsor: { value: data.sponsor || "", error: null },
            })
        }
        else {
            setStudy(initState);
        }
    }, [data])

    const navigate = useNavigate();

    useEffect(() => {
        if (!!responseData?.addStudy.id) {
            navigate(`../study/${responseData?.addStudy.id}/edit`);
        }
    }, [responseData, navigate])

    const createStudy = async () => {
        if (study != null) {
            let regex = /^\d{3}[0-9]{1}-\d{5}[0-9]{1}-\d{1}[0-9]$/;
            if(!regex.test(study.eudra.value)){
                setStudy((prevState: any) => { return { ...prevState, eudra: { text: study.eudra.value, error: 'Wrong format. The correct format is YYYY-NNNNNN-CC'}} })
            } else {
                await addStudy({
                    variables: {
                        input: {
                            code: study.code.value,
                            eudraCtNumber: study.eudra.value,
                            plannedVisitorsAmout: parseInt(study.plannedSubjects.value, 10),
                            plannendBudget: parseInt(study.plannedBudget.value, 10),
                            protocolNumber: study?.protocolNumber.value,
                            sponsor: study.sponsor.value,
                            clientId: study.client.value,
                        }
                    },
                    refetchQueries: studyId ? [{ query: studyDetailQuery, variables: { studyId } }] : []
                })
            }
        }
    }

    const inputHandler = (value: string | number, inputName?: string) => {
        if (!!inputName) {
            setStudy((prevState: StudyType) => { return { ...prevState, [inputName]: { value: value, error: null}} })
        }
    }

    return (
        <div>
            <Flex>
                <TextInput disabled={!!studyId} style={{ marginRight: '1em' }} label='Short Name/Study Code' name='code' onChange={inputHandler} value={study.code.value} />
                <TextInput disabled={!!studyId} label='Eudra CT Number' errMessage={study.eudra.error} style={{ flex: 1 }} onChange={inputHandler} name='eudra' value={study.eudra.value} regex={/^(\s*|[0-9-]+)$/}/>
            </Flex>
            <Flex css={css`margin: 1em 0;`}>
                {clients && <div css={css`margin-right: 1em; width: 100%; position: relative; z-index: 5;`}><SelectBox disabled={clients.find(client => client.value === data?.clientId) ? true : false} value={study.client.value} onChange={inputHandler} name='client' label='Client' list={clients} /></div>}
                <TextInput name='plannedSubjects' regex={/^(\s*|\d+)$/}  onChange={(value, name) => inputHandler(value, name)} disabled={!!studyId} style={{ width: '30%' }} label='Planned Subjects' value={study.plannedSubjects.value} />
            </Flex>
            <Flex>
                <TextInput name='protocolNumber' onChange={(value, name) => inputHandler(value, name)} disabled={!!studyId} style={{ width: '50%', marginRight: '1em' }} label='Protocol Number' value={study.protocolNumber.value} />
                <TextInput name='plannedBudget' regex={/^(\s*|\d+)$/} disabled={!!studyId} style={{ width: '50%' }} label='Planned Budget' value={study.plannedBudget.value} onChange={inputHandler} />
            </Flex>
            <Flex css={css`margin-top: 1em;`}>
                <TextInput disabled={!!studyId} style={{ width: '100%' }} name='sponsor' label='Sponsor' value={study.sponsor.value} onChange={inputHandler} />
            </Flex>
            <Flex css={css`justify-content: flex-end;`}>
                {!!!studyId && <Button onClick={createStudy} disabled={!!!study?.code || !!!study.eudra || !!!study.protocolNumber || parseInt(study.plannedSubjects.value, 10) <= 0 || study.plannedSubjects.value === '' || parseInt(study.plannedBudget.value, 10) <= 0 || study.plannedBudget.value === '' || study.client.value === 0 } style={{ marginTop: '1em' }} color='primary'>Save</Button>}
            </Flex>
        </div>
    );
}

export default StudyInfo;

const Flex = styled.div`
    display: flex;
`;
