import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { colors } from '../libs/colors';
import DropDown from '../icons/drop-down.svg';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

interface Props {
    list: { key: string | number, label: string, value: any}[];
    value?: number | string | null;
    onChange?:(value: any, name?: string) => void;
    label: string;
    minWidth?: string;
    disabled?: boolean;
    style?: any;
    listHeight?: string;
    name?: string;
    onOpen?: () => void;
}

const SelectBox = ({ list, value, onChange, label, minWidth, disabled = false, style,name, onOpen }: Props) => {
    const selectedItem = list.find(item => item.value === value);
    const [inputState, setState] = useState(selectedItem);
    useEffect(() => {
        setState(selectedItem);
    }, [selectedItem])

    const DropdownIndicator = (
        props: any
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <img alt='dropDown' src={DropDown} />
            </components.DropdownIndicator>
        );
    };

    const handleChange = (selectedOption: any) => {
        onChange && onChange(selectedOption.value, name);
        setState(selectedOption);
    }
    

    const customStyles = {
        container: (base: any, state: any) => ({
            ...base,
            border: state.isFocused ? null : null,
            zIndex: 21
        }),
        control: (base: any) => ({
            ...base,
            background: 'white',
            height: '53px',
            borderRadius: '2px'
        }),
        valueContainer: (base: any) => ({
            ...base,
            marginTop: '8px',
            fontWeight: 600
        }),
        multiValue: (base: any) => ({
            ...base,
            background: "lightYellow",
            maxWidth: "100px"
        }),
        indicatorsContainer: (base: any) => ({
            ...base,
            border: 'none',
            background: colors.selectorGray,
            height: '100%',
            width: '35px',
            justifyContent: 'center',
        }),
        indicatorSeparator: () => ({
            display: 'none'
        }),
        menu: (base: any) => ({
            ...base,
            marginTop: 0,
            borderRadius: 2,
            background: 'white',
            paddingTop: '1em',
            paddingBottom: '1em',
        }),
        menuList: (base: any) => ({
            ...base,
            background: 'white',
        }),
        clearIndicator: (base: any) => ({
            ...base,
        })
    };
    
    return (
        <div css={css`min-width: ${minWidth || '120px'}; position: relative;`} style={style}>  
            <Label>{label}</Label>
            <Select
            isDisabled={disabled}
                components={{ DropdownIndicator }}
                onMenuOpen={onOpen}
                styles={customStyles}
                value={inputState || null}
                onChange={handleChange}
                options={list}
            />
        </div>
    );
}

export default SelectBox;

const Label = styled.span`
    position: absolute;
    z-index: 22;
    font-size: 10px;
    left: 12px;
    top: 8px;
`;