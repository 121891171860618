import React, { useState, useEffect } from 'react';
import TopBar from '../../components/TopBar';
import { Container } from '../../libs/styles';
import { Item } from '../../components/Item';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { useMutation } from '@apollo/react-hooks';
import { updateUser, updateUserVariables } from '../../graphql/types';
import { updateUserMutation } from '../../graphql/mutations';
import SnackBar from '../../components/SnackBar';
import { getGraphqlErrorMessage, passwordRegex } from '../../helpers';
import SwitchButton from '../../components/SwitchButton';
import styled from '@emotion/styled';
import Header from '../../components/Header';
import moment from 'moment';
import { colors } from '../../libs/colors';
import { useMe } from '../../hooks';

const initState = {
  username: "",
  email: "",
  firstname: "",
  lastname: "",
  phone: ""
}

const Profile = () => {
  const [user, setUser] = useState(initState);
  const [snackBarInfo, setSnackBarInfo] = useState<null | { type: "error" | "done", message: string}>(null);
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    repeatPassword: ""
  })

  const [isSettingPassword, setIsSettingPassword] = useState(false);

  const { me } = useMe();
  const [update, { error: mutationError, data: mutationData }] = useMutation<updateUser, updateUserVariables>(updateUserMutation)

  useEffect(() => {
    if(me){
      setUser({
        username: me.username,
        email: me.contact.email,
        firstname: me.contact.firstName,
        lastname: me.contact.lastName,
        phone: me.contact.phone
      })
    }
  }, [me])

  useEffect(() => {
    if(mutationError || mutationData){
      setSnackBarInfo({
        type: mutationError ? 'error' : 'done',
        message: mutationError ? getGraphqlErrorMessage(mutationError) : "It's saved!"
      })
    }
  }, [mutationError, mutationData])

  const handleChange = (value: string, name: any) => {
    setUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangePassword = (value: string, name: any) => {
    setPassword(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSave = async () => {
    if(isSettingPassword && !passwordRegex.test(password.newPassword)){
      setSnackBarInfo({
        type: 'error',
        message: "Password has to contain at least 1 uppercase character, 1 lowercase character, 1 number and 1 special character. Length has to be at least 10 characters"
      })
    } else if((!isSettingPassword || (password.newPassword === password.repeatPassword)) && user){
      try{
        await update({
          variables: {
            input: {
              ...user,
              oldPassword: isSettingPassword ? password.oldPassword : undefined,
              newPassword: isSettingPassword ? password.newPassword : undefined
            }
          }
        })
      } catch (e) {}
    } else {
      setSnackBarInfo({
        type: 'error',
        message: "Password has to be same."
      })
    }
  }

  const navigationData = [
    { text: 'Profile'},
    {text: 'Edit Profile'},
    ]

  return(
    <>
      <TopBar />
      <Header hidePlusButton data={navigationData}/>
      <Container>
        <div style={{ maxWidth: '480px'}}>
          <Item>
            <Row>
              <TextInput label="User Name" value={user.username} disabled/>
            </Row>
            <Row style={{ marginTop: 32 }}>
              <TextInput label="Email" value={user.email} name="email" onChange={handleChange}/>
              <TextInput label="Phone" value={user.phone}  style={{marginLeft: 16 }} name="phone" onChange={handleChange}/>
            </Row>
            <Row style={{ marginTop: 32}}>
              <TextInput label="First Name" value={user.firstname} name="firstname" onChange={handleChange}/>
              <TextInput label="Last Name" value={user.lastname} style={{ marginLeft: 16 }} name="lastname" onChange={handleChange}/>
            </Row>
            <div css={{ alignItems: "center", justifyContent: 'space-between' }}>
              <SwitchButton 
                type='gray' 
                label="Set New Password" 
                style={{ marginTop: '32px' }} 
                subLabel={`expires on ${me?.passwordExpiration}`} 
                isActive={isSettingPassword} 
                value='password' 
                onChange={(_, isActive) => setIsSettingPassword(isActive)}
                subLabelStyle={{ 
                  color: Math.abs(moment().diff(moment(me?.passwordExpiration, "DD-MM-YYYY"), "days")) <= 10 ? colors.danger : colors.success
                }}
              /> 
            </div>
            {isSettingPassword && 
              <>
                <TextInput label="Old Password" value={password.oldPassword} style={{marginTop: 16 }} type="password" name="oldPassword" onChange={handleChangePassword}/>
                <TextInput label="New Password" value={password.newPassword}  style={{marginTop: 32 }} type="password" name="newPassword" onChange={handleChangePassword}/>
                <TextInput label="Repeat Password" value={password.repeatPassword}  style={{marginTop: 32 }} type="password" name="repeatPassword" onChange={handleChangePassword}/>
              </>
            }
          </Item>
        <Button onClick={handleSave}>Save</Button>
        </div>
      </Container>
      {snackBarInfo && <SnackBar message={snackBarInfo.message} type={snackBarInfo.type} resetError={setSnackBarInfo}/>}
    </>
  )
}

export default Profile;

const Row = styled.div`
  display: flex;
`;