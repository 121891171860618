import React, { useState } from 'react';
import Modal from '../../components/Modal';
import styled from '@emotion/styled';
import Button from '../../components/Button';
import { addCompensationMutation } from '../../graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import { addCompensationVariables, StudyDetail, StudyDetailVariables, StudyCompensationsVariables, StudyCompensations } from '../../graphql/types';
import { useParams } from '@reach/router';
import { studyDetailQuery, studyCompensationsQuery } from '../../graphql/queries';
import SnackBar from '../../components/SnackBar';
import TextInput from '../../components/TextInput';
import SwitchButton from '../../components/SwitchButton';
import { css } from '@emotion/core';

interface Props {
    isOpen: boolean;
    onIsOpenChange: (isOpen: boolean) => void;
}

const AddCompensationModal = ({ isOpen, onIsOpenChange } : Props) => {
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [isExpense, setIsExpense] = useState(false);

    const { studyId } = useParams()
    const [addCompensation, { error }] = useMutation<any, addCompensationVariables>(addCompensationMutation);

    const handleAddCompensation = async () => {
        try{
            await addCompensation({
                variables: {
                    studyId: parseInt(studyId, 10),
                    name,
                    price: isExpense ? null : parseInt(price, 10),
                    isExpense
                },
                update(cache, result) {
                    const variables = { studyId: parseInt(studyId, 10) };
                    const newItem = result.data.addCompensation;
                    const studyDetailCache = cache.readQuery<StudyDetail, StudyDetailVariables>({ query: studyDetailQuery, variables });              
                    if(studyDetailCache){
                        const newData: StudyDetail= {
                        ...studyDetailCache,
                        study: {
                            ...studyDetailCache.study,
                            compensations: [...studyDetailCache.study.compensations, newItem]
                        }
                        };
                        cache.writeQuery({
                            query: studyDetailQuery,
                            variables,
                            data: newData
                        })
                    }
                    const studyCompensationsCache = cache.readQuery<StudyCompensations, StudyCompensationsVariables>({ query: studyCompensationsQuery, variables });                
                    if(studyCompensationsCache){
                        const newData: StudyCompensations= {
                                ...studyCompensationsCache,
                                studyCompensations: [
                                    ...studyCompensationsCache.studyCompensations,
                                    newItem
                                ]
                            };
                            cache.writeQuery({
                                query: studyCompensationsQuery,
                                variables,
                                data: newData
                            })
                    }
                }
            });
            onIsOpenChange(false)
        } catch (e) {}
    }

    const isButtonDisabled = name === "" || (price === "" && !isExpense);   
    return(
        <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={onIsOpenChange}
          contentLabel="Add Site"
        > 
            <Row css={css`width: 400px; justify-content: center;`}>
                <TextInput label="Name" value={name} onChange={setName} />
                {!isExpense && <TextInput label="Price (CZK)" value={price} onChange={setPrice} style={{ marginLeft: 16 }} regex={/^[0-9]*$/g}/>}
            </Row>
            <Row css={css`justify-content: center; margin-top: 16px;`}>
            <SwitchButton label="Expense" value="expenses" type="gray" isActive={isExpense} onChange={(_, isActive) => setIsExpense(isActive)}/>
            </Row>
            <Center>
                <Button color='none' onClick={() => onIsOpenChange(false)}>Cancel</Button>
                <Button onClick={handleAddCompensation} disabled={isButtonDisabled}>Save</Button>
            </Center>
            
        </Modal>
         {error != null && <SnackBar message={error?.message.split(":")[1]}/>}
        </div>
    )
}

export default AddCompensationModal;

const Row = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
`;
