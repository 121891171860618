import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../libs/colors';

interface Props {
    value: string;
    err?: boolean;
    onChange?:(value: string, name?: string) => void;
    onFocus?:(e: React.FocusEvent<HTMLTextAreaElement>) => void;
    onBlur?:(e: React.FocusEvent<HTMLTextAreaElement>) => void;
    disabled?: boolean;
    name?: string;
    label?: string;
    placeholder?: string;
    regex?: RegExp;
    style?: any;
    inputStyle?: any;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(({ inputStyle, style, value, err = false, onChange, onFocus, onBlur, disabled, label, placeholder, regex, name, onKeyDown }, ref) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if(regex == null || (regex && e.currentTarget.value.match(regex))){
            onChange && onChange(e.currentTarget.value, name)
        }
    }
    return(
    <div style={style}>
        <Box isError={err} disabled={disabled} style={inputStyle}>
        {label != null && <Label>{label}</Label>}
        <TextAreaInput
            value={value} 
            ref={ref} 
            onKeyDown={onKeyDown}
            isError={err} 
            onChange={handleChange} 
            onFocus={onFocus} 
            onBlur={onBlur} 
            disabled={disabled} 
            placeholder={placeholder}
        />
        </Box>
    </div>
    )
})

export default TextArea;

const Box = styled.div<{ isError: boolean, disabled?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: ${props => props.isError ? `1px solid red` : '1px solid #DEDEDE'};
    border-radius: 3px;
    padding: ${props => props.isError ? '7px 7px 9px 11px' : '8px 8px 10px 12px'};
    background: ${props => props.disabled ? "none" : "white"};
`;

const TextAreaInput = styled.textarea<{ isError: boolean }>`
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    border: none;
    padding: none;
    background: none;
    outline: none;
    color: ${props => props.isError ? "red" : "black"};
    :disabled {
        color: #999999;
    }
`;

const Label = styled.span`
    color: ${colors.darkGray};
    font-size: 10px;
    line-height: 10px
`;