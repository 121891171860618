import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { usersQuery } from '../../graphql/queries';
import { UsersQuery } from '../../graphql/types';
import TopBar from '../../components/TopBar';
import { css } from '@emotion/core';
import { Container } from '../../libs/styles';
import Header from '../../components/Header';
import TextInput from '../../components/TextInput';
import { Item } from '../../components/Item';
import UserList from './UserList';

const Users = () => {
    const { data } = useQuery<UsersQuery>(usersQuery, {
        fetchPolicy: 'cache-and-network'
    });

    const [searchBy, setSearch] = useState("");

    const filteredUsers = data?.users.filter(c => {
        if(c.username.toLocaleLowerCase().indexOf(searchBy.toLocaleLowerCase()) !== -1){
            return true;
        } else if(c.contact.email.toLocaleLowerCase().indexOf(searchBy.toLocaleLowerCase()) !== -1){
            return true
        } else if(c.contact.lastName.toLocaleLowerCase().indexOf(searchBy.toLocaleLowerCase()) !== -1){
            return true
        } else if(c.contact.phone.toLocaleLowerCase().indexOf(searchBy.toLocaleLowerCase()) !== -1){
            return true
        }
        return false;
    })

    const navigationData = [
        { text: 'Users'},
    ];

    return(
        <>
            <TopBar sites/>
            <Header hidePlusButton data={navigationData} />
            <Container>
                <Item css={css`flex-direction: row; justify-content: space-between;`}>
                    <TextInput placeholder="Search Users" onChange={setSearch} value={searchBy} style={{ minWidth: 250}}/>
                </Item>
                {filteredUsers && <UserList users={filteredUsers} searchBy={searchBy}/>}
            </Container>
        </>
    )
}

export default Users;