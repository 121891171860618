import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../libs/colors';
import done from '../../icons/done.svg';
import failed from '../../icons/failed.svg';
import delayed from '../../icons/delayed.svg';
import PriceFormat from '../../components/PriceFormat';
import { css } from '@emotion/core';

export interface Props {
    totalPayed?: number ;
    totalPlanned?: number;
    totalDenied?: number;
    totalApproved?: number;
}
 
const PaymentSummary = ({ totalPayed, totalPlanned, totalDenied, totalApproved }: Props) => {
    return (
        <PaymentSummaryContainer>
          <div css={css`display: flex; align-items: center; width: 100%;`}>
          <Row>
          <img width='32' height='32' alt="" src={delayed}/>
          <Column>
            <Info>
                Planned
              </Info>
              <Price>
              <PriceFormat value={totalPlanned}/>
              </Price>
          </Column>
          </Row>
          <Row>
          <img width='32' height='32'  alt="" src={failed}/>
          <Column>
          <Info>
                Denied
              </Info>
              <Price>
                <PriceFormat value={totalDenied}/>
              </Price>
          </Column>
          </Row>
          <Row>
          <img width='32' height='32'  alt="" src={done}/>
          <Column>
          <Info>
                Approved
              </Info>
              <Price>
                <PriceFormat value={totalApproved}/>
              </Price>
          </Column>
          </Row>
          </div>
          <Row css={css`width: 15%;`}>
            <img width='32' height='32'  alt="" src={done}/>
            <Column>
              <Info>
                Paid
              </Info>
              <Price>
              <PriceFormat value={totalPayed}/>
              </Price>
            </Column>
          </Row>
        </PaymentSummaryContainer>
      );
}
 
export default PaymentSummary;

const PaymentSummaryContainer = styled.div`
width: 100%;
background-color: white;
border-radius: 2px;
height: 100px;
border-style: solid;
border-color: ${colors.lightGray};
border-width: 10px 0 0 0;
justify-content: space-between;
display: flex;
align-items: center;
`;

const Row = styled.div`
  display: flex;
  width: 20%;
  padding: 0 36px;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const Info = styled.span`
  font-size: 10px;
`;

const Price = styled.span`
  font-size: 16px;  
  font-weight: 500;
  margin-top: 4px;
  white-space: nowrap;
`;
