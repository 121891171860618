import React, { useState } from 'react';
import TextInput from '../../components/TextInput';
import Modal from '../../components/Modal';
import styled from '@emotion/styled';
import Button from '../../components/Button';

interface Props {
    onIsOpenChange: (isOpen: boolean) => void;
    onSave: (price: number) => void;
}

const SetCompensationPriceModal = ({ onIsOpenChange, onSave }: Props) => {
    const [price, setPrice] = useState("");

    return (
        <Modal
        isOpen={true}
        onRequestClose={onIsOpenChange}
        contentLabel={"Add price"}
      > 
              <TextInput label="Price (CZK)" value={price} onChange={setPrice} name="price" regex={/^[0-9]*$/g} style={{ width: 250 }}/>
          <Center>
              <Button onClick={() => onIsOpenChange(false)}>Cancel</Button>
              <Button onClick={() => onSave(Number(price))} disabled={price === ""}>Save</Button>
          </Center>        
      </Modal>
    )
};  

export default SetCompensationPriceModal;

const Center = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
`;