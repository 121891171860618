import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { detailClientsQuery } from '../../graphql/queries';
import { DetailClientsQuery } from '../../graphql/types';
import TopBar from '../../components/TopBar';
import Header from '../../components/Header';
import { Container } from '../../libs/styles';
import { css } from '@emotion/core';
import { Item } from '../../components/Item';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { useNavigate } from '@reach/router';
import ClientItem from './ClientItem';

const Clients = () => {
    const { data } = useQuery<DetailClientsQuery>(detailClientsQuery, {
        fetchPolicy: 'cache-and-network'
    });
    const [searchBy, setSearch] = useState("");
    const navigate = useNavigate();

    const filteredClients = data?.clients?.filter(c => {
        if(c.name.toLocaleLowerCase().indexOf(searchBy.toLocaleLowerCase()) !== -1){
            return true;
        }
        return false;
    })

    const navigationData = [
        { text: 'Clients'},
    ];

    return (
        <>
            <TopBar sites/>
            <Header hidePlusButton data={navigationData}/>
            <Container>
                <Item css={css`flex-direction: row; justify-content: space-between;`}>
                    <TextInput placeholder="Search Clients" onChange={setSearch} value={searchBy} style={{ minWidth: 250}}/>
                    <Button onClick={() => navigate("/add_client") }>Add Client</Button>
                </Item>
                {filteredClients?.map(c => <ClientItem client={c} searchBy={searchBy}/>)}
            </Container>
        </>
    )
};

export default Clients;