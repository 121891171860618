import React, { useState } from 'react';
import Modal from '../../components/Modal';
import SelectBox from '../../components/SelectBox';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { studyContactsQuery, studyDetailQuery } from '../../graphql/queries';
import { StudyContacts, SetStudyContact, SetStudyContactVariables, StudyContactsVariables, StudyDetail_study_contacts, StudyDetail, StudyDetailVariables } from '../../graphql/types';
import Button from '../../components/Button';
import { css } from '@emotion/core';
import { setStudyContactMutation } from '../../graphql/mutations';

interface Props {
    isOpen: boolean;
    studyId: number;
    setIsOpen: (isOpen: boolean) => void;
    clientId: number;
    actualContacts: StudyDetail_study_contacts[];
}

const AddContactModal = ({ isOpen, studyId, setIsOpen, clientId, actualContacts }: Props) => {
    const contactsIds = actualContacts.map(c => c.id);
    const [input, setInput] = useState(1);
    const [type, setType] = useState('admin');
    const { data } = useQuery<StudyContacts, StudyContactsVariables>(studyContactsQuery, {
      variables: {
        type,
        clientId
      }
    });
    const [ setAdmin ] = useMutation<SetStudyContact, SetStudyContactVariables>(setStudyContactMutation);
    let contacts = data?.contacts?.map((contact, index) => { return { key: index, label: `${contact.firstName} ${contact.lastName}`, value: contact.id } })
    const types = [{
        key: 'admin',
        label: 'Admin',
        value: 'admin'
      },
      {
        key: 'client',
        label: 'Client',
        value: 'client'
      }
    ]
    const buttonHandler = () => {
        setAdmin({
            variables: {
                input: {
                    studyId,
                    contactId: input,
                }
            },
            update(cache, result){
              const variables = { studyId };
                const studyDetailCache = cache.readQuery<StudyDetail, StudyDetailVariables>({ query: studyDetailQuery, variables });                
                if(studyDetailCache && result.data?.setStudyContact){
                    const newData: StudyDetail= {
                    ...studyDetailCache,
                    study: {
                        ...studyDetailCache.study,
                        contacts: [ ...studyDetailCache.study.contacts, result.data?.setStudyContact]
                    }
                    };
                    cache.writeQuery({
                        query: studyDetailQuery,
                        variables,
                        data: newData
                    })
                }
            }
        })
        setIsOpen(false);
    }

    contacts = contacts && contacts?.filter(c => !contactsIds.includes(c.value))

    return (
        <Modal onRequestClose={() => setIsOpen(false)} isOpen={isOpen}>
            <div css={css`display: flex;`}>
            <SelectBox label='Type' value={type} onChange={setType} list={types} />
            {contacts && <SelectBox style={{ marginLeft: 16, width: 220 }} label='Contact' value={input} onChange={setInput} list={contacts} />}
            <Button onClick={buttonHandler} style={{marginLeft: '1em'}}>Set contact</Button>
            </div>
        </Modal>
    )
}

export default AddContactModal;