import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as PostSVG } from '../../icons/post.svg';

interface Props {
    onClick: (visitIndex: number) => void;
    visitIndex?: number;
 }

    const NotResolvedPostalOrderNotification = ({ onClick, visitIndex }: Props) => {
        const handleClick = () => {
            onClick(visitIndex != null ? visitIndex : 0);
        }
        return (
            <Container role="button" onClick={handleClick}>
                Pending action on:<Bold>{visitIndex != null && visitIndex + 1}, postal order form</Bold>
                <PostSVG style={{ marginLeft: 32, height: 31, width: 31}} />
            </Container>
        )
    }
    
    export default NotResolvedPostalOrderNotification;
    
    const Container = styled.div`
        display: flex;
        align-items: center;
        background: #5F6164;
        border-radius: 10px;
        padding: 4px 16px;
        color: white;
        font-weight: 500;
        margin-top: 16px;
    `;
    
const Bold = styled.span`
    font-weight: 800;
    margin-left: 4px;
`
