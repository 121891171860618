import React from 'react';
import compensationControlSVG from '../../icons/compensationControl.svg';
import styled from '@emotion/styled';
import { UnresolvedSubjectVisit_unresolvedSubjectVisit_delayedSubjectCompensations } from '../../graphql/types';

interface Props {
    data: UnresolvedSubjectVisit_unresolvedSubjectVisit_delayedSubjectCompensations;
    onClick: (visitIndex: number) => void;
    visitIndex?: number;
 }

    const DelayedCompensationNotification = ({ data, onClick, visitIndex }: Props) => {
        const handleClick = () => {
            onClick(visitIndex != null ? visitIndex : 0);
        }
        return (
            <Container role="button" onClick={handleClick}>
                Pending action on:<Bold>{visitIndex != null && visitIndex + 1}, {data.compensation?.name}</Bold>
                <Img src={compensationControlSVG} />
            </Container>
        )
    }
    
    export default DelayedCompensationNotification;
    
    const Container = styled.div`
        display: flex;
        align-items: center;
        background: #5F6164;
        border-radius: 10px;
        padding: 4px 16px;
        color: white;
        font-weight: 500;
        margin-top: 16px;
    `;
    
    const Img = styled.img`
        border: none;
        outline: none;
        padding: 0;
        margin-left: 16px;
        background: none;
        margin-left: 32px;
    `;

const Bold = styled.span`
    font-weight: 800;
    margin-left: 4px;
`
