import gql from 'graphql-tag';

export const studiesQuery = gql`
query Studies($centrumId: Int) {
    studies(centrumId: $centrumId) {
      id
      code
    }
  }
`;

export const selectorStudiesQuery = gql`
query SelectorStudies($centrumId: Int) {
    studies(centrumId: $centrumId) {
      id
      code
    }
  }
`;

export const studyCompensationsQuery = gql`
query StudyCompensations($studyId: Int!) {
  studyCompensations(studyId: $studyId) {
    id
    name
    price
    isExpense
    deletedAt
  }
} 
`;

export const centrumDataQuery = gql`
query CentrumDataQuery($centrumId: Int, $studyId: Int, $takeAll: Boolean) {
    centrums(centrumId: $centrumId, studyId: $studyId, takeAll: $takeAll) {
    id
    name
    code(studyId: $studyId)
  }
}
`;

export const studyCodeQuery = gql`
query StudyCodeQuery($id: Int!) {
  study(id: $id) {
    id
    code
  }
}
`;

export const selectorSubjectsQuery = gql`
query SelectorSubjectsQuery($studyId: Int, $siteNumber: String) {
  subjects(studyId: $studyId, siteNumber: $siteNumber) {
      id
      code
    }
  }
`;

export const siteNumbersSelector = gql`
query SiteNumbersSelector($studyId: Int)  {
studies(studyId: $studyId) {
id
siteNumbers
}
}
`;


export const studyQuery = gql`
query Study($studyId: Int, $centrumId: Int) {
  studies(studyId: $studyId) {
    id
    eudraCtNumber
    clientId
    protocolNumber
    code
    sponsor
    client {
      name
    }
    subjects(centrumId: $centrumId) {
      id
      deniedPayments {
        id
        errorMessage
        status
      }
      code
      bankAccount
      lastVisit {
        date
      }
      delayedSubjectCompensations{
        id
      }
      unresolvedSubjectVisitWithPostalOrder{
        id
      }
      status
    }
  }
}
`;

export const studyContactsQuery = gql`
query StudyContacts($type: String!, $clientId: Int) {
  contacts(type: $type, clientId: $clientId) {
    id
    firstName
    lastName
    email
  }
}
`;

export const studySubjectVisitFragment = gql`
  fragment StudySubjectVisit on SubjectVisit {
    id
    date
    orderNumber
    payment {
      status
      id
      isPostalOrder
      hasPostalForm
    }
    visit {
      flowChart {
        id
        version
        versionDate
        allowExpenses
        cycleEndsAt
        cycleStartsAt
      }
      id
      flowChartId
      orderNumber
      name
    }
    subjectVisitCompensations {
      id
      status
      price
      compensation {
        id
        name
        price
        isExpense
      }
    }
  }
`;

export const unresolvedSubjectVisitQuery = gql`
  query UnresolvedSubjectVisit($subjectId: Int!) {
    unresolvedSubjectVisit {
      unresolvedSubjectVisitWithPostalOrder(subjectId: $subjectId){
        id
        subjectVisitCompensations {
          status
          id
          compensation {
            id
            isExpense
          }
        }
      }
      delayedSubjectCompensations(subjectId: $subjectId){
        subjectVisitId
        id
        compensation {
          id
          name
        }
      }
    }
  }
`

export const studySubjectFragment = gql`
  fragment StudySubject on Subject {
    id
    code
    bankAccount
    lastVisit {
      date
      orderNumber
    }
    status
    subjectVisits{
      ...StudySubjectVisit
    }
  }
  ${studySubjectVisitFragment}
`;

export const studySubjectQuery = gql`
query StudySubject($studyId: Int!, $subjectId: Int!) {
  study(id: $studyId) {
    id
    eudraCtNumber
    clientId
    code
    sponsor
    protocolNumber
    client {
      name
    }
    subjects(subjectId: $subjectId) {
      ...StudySubject
    }
  }
}
${studySubjectFragment}
`;

export const allSubjectsQuery = gql`
query subjects($studyId: Int!, $centrumId: Int) {
  subjects(studyId: $studyId, centrumId: $centrumId) {
    id
    code
  }
}
`;

export const budgetGraphqQuery = gql`
query BudgetGraph($studyId: Int) {
  paymentInfo {
      totalPayed(studyId: $studyId)
      totalPlanned(studyId: $studyId)
      totalApproved(studyId: $studyId)
    }
  }
`;

export const paymentsQuery = gql`
query Payments($studyId: Int, $type: String,$siteNumber: String, $subjectId: Int, $page: Int!, $count: Int!) {
  paymentInfo {
      paymentData(studyId: $studyId, type: $type, siteNumber: $siteNumber, subjectId: $subjectId, page: $page,count: $count) {
        payments {
          price
          id
          createdAt
          errorMessage
          isPostalOrder
          hasPostalForm
          variableSymbol
          status
          subjectVisit {
            subject {
              id
              bankAccount
            }
            date
            subjectVisitCompensations {
              id
              visitCompensationId
              status
              updatedAt
              createdAt
              price
              compensation {
                id
                name
                currency
                price
                isExpense
              }
            }
            subjectId
            subjectCode
            orderNumber
            studyCentrum {
              code
              centrumId
              studyId
            }
            visit {
              study {
                code
                id
              }
              name
              orderNumber
            }
          }
        }
totalCount

      }
      totalPayed(studyId: $studyId, type: $type, siteNumber: $siteNumber, subjectId: $subjectId)
      totalPlanned(studyId: $studyId, type: $type, siteNumber: $siteNumber, subjectId: $subjectId)
      totalDenied(studyId: $studyId, type: $type, siteNumber: $siteNumber, subjectId: $subjectId)
      totalApproved(studyId: $studyId, type: $type, siteNumber: $siteNumber, subjectId: $subjectId)
    }
  }
`;

export const editStudyCompensationFragment = gql`
  fragment EditStudyCompensation on Compensation {
    id
    name
    deletedAt
  }
`;

export const editStudyVisitCompensationFragment = gql`
  fragment EditStudyVisitCompensation on VisitCompensation {
    id
    compensation {
      ...EditStudyCompensation
    }
  }
  ${editStudyCompensationFragment}
`;

export const editStudyVisitFragment = gql`
  fragment EditStudyVisit on Visit {
    id
    name
    orderNumber
    visitCompensations {
      ...EditStudyVisitCompensation
    }
    flowChart {
      id
      cycleStartsAt
      cycleEndsAt
    }
    type
  }
  ${editStudyVisitCompensationFragment}
`;

export const editStudyFlowChartFragment = gql`
  fragment EditStudyFlowChart on FlowChart {
    id
    version
    isDeletable
    isLocked
    versionDate
    allowExpenses
    createdAt
    cycleStartsAt
    cycleEndsAt
    visits {
      ...EditStudyVisit
    }
  }
  ${editStudyVisitFragment}
`;

export const studyDetailQuery = gql`
  query StudyDetail($studyId: Int!, $centrumId: Int) {
    study(id: $studyId) {
      id
      code
      eudraCtNumber
      clientId
      plannedVisitorsAmout
      protocolNumber
      incomingBudget {
        date
        price
        contact {
          firstName
          lastName
        }
      }
      contacts {
        id
        firstName
        lastName
        phone
        email
        clientId
      }
      actualBudget
      sponsor
      plannendBudget
      protocolNumber
      compensations {
        id
        name
        price
        currency
        deletedAt
        isExpense
      }
      entered(centrumId: $centrumId) {
        earlyTerminated
        completed
        ongoing
      }
      enrolled(centrumId: $centrumId) {
        screenFailure
        inScreening
      }
      flowCharts {
        ...EditStudyFlowChart
      }
      centrums {
        id
        name
        code(studyId: $studyId)
        flowChart(studyId: $studyId){
          id
        }
      }
      users {
        id
        username
        userStudyCentrum(studyId: $studyId){
          userId
          studyId
          centrumId
          studyRole
        }
        centrumId
      }
    }
  }
  ${editStudyFlowChartFragment}
`;

export const detailCentrumsQuery = gql`
  query detailCentrums($centrumId: Int, $studyId: Int) {
    centrums(studyId: $studyId, centrumId: $centrumId) {
      id
      codes
      name
      users {
        id
        username
        contact {
          firstName
          lastName
          email
          phone
        }
      }
      subjects(studyId: $studyId) {
        id
        code
        bankAccount
      }
      studies(studyId: $studyId) {
        id
        code
        siteNumbers(centrumId: $centrumId)
      }
    }
  }
`;

export const compensationsQuery = gql`
  query compensations {
    compensations {
      id
      name
    }
  }
`;

export const userQuery = gql`
  query user($userId: Int!) {
    user(userId: $userId) {
      id
      username
      role
      contact {
        firstName
        lastName
        email
        phone
      }
    }
  }
`;

export const editSubjectQuery = gql`
query EditSubjectQuery($studyId: Int!, $subjectId: Int) {
  subjects(studyId: $studyId, subjectId: $subjectId) {
      id
      bankAccount
      centrumId
      code
      gdprAgreement
      notStarted
      informationAgreement
      status
  }
}
`;

export const centrumUsersQuery = gql`
  query centrumUsers($centrumId: Int!){
    centrumUsers(centrumId: $centrumId){
      username
      id
    }
  }
`;

export const meQuery = gql`
  query me {
    me {
      id
      username
      contact {
        clientId
        firstName
        lastName
        phone
        email
      }
      role
      passwordExpiration
      centrumId
    }
  }
`;

export const userByTokenQuery = gql`
  query userByToken($token: String!) {
    userByToken(token: $token) {
      id
      username
    }
  }
`;

export const centrumUserQuery = gql`
  query centrumUser($username: String!, $email: String!){
    centrumUser(username: $username, email: $email){
      id
      username
      contact {
        email
      }
    }
  }
`;

export const clientsQuery = gql`
query ClientsQuery {
  clients {
    id
    name
  }
}
`;

export const detailClientsQuery = gql`
query DetailClientsQuery($clientId: Int) {
  clients(clientId: $clientId) {
    id
    name
    note
    contacts {
      id
      firstName
      lastName
      email
      phone
      user {
        username
      }
    }
  }
}
`;

export const approvedPaymentsQuery = gql`
  query approvedPayments($studyId: Int) {
    approvedPayments(studyId: $studyId){
      price
      id
      sentAt
      status
      errorMessage
      variableSymbol
      subjectVisit {
        subjectCode
        subjectId
        orderNumber
        visit {
          study {
            code
            id
          }
          name
          orderNumber
        }
      }
    }
  }
`;

export const selectedPaymentsQuery = gql`
  query selectedPayments($ids: [Int!]!) {
    selectedPayments(ids: $ids){
      price
      id
      sentAt
      errorMessage
      variableSymbol
      status
      subjectVisit {
        subjectCode
        subjectId
        orderNumber
        visit {
          study {
            code
            id
          }
          name
          orderNumber
        }
      }
    }
  }
`;

export const subjectVisitLogsQuery = gql`
  query SubjectVisitLogsQuery($subjectVisitId: Int!) {
    subjectVisitLogs(subjectVisitId: $subjectVisitId) {
      id
      message
      createdAt
      user {
        username
      }
    }
  }
`;

export const usersQuery = gql`
  query UsersQuery {
    users {
      id
      role
      contact {
        firstName
        lastName
        email
        phone
      }
      username
      centrum {
        id
        name
      }
      studies {
        id
        code
      }
    }
  }
`



