export const colors = {
    projectWhite: '#FAFAFA',
    skinzonePink: '#F5EFEF',
    menuPink: '#EFEBEB',
    primary: '#D5A63F',
    gray: '#979797',
    darkGray: '#363636',
    lightGray: '#d7d4d4',
    mediumGray: '#e8e1e1',
    selectorGray: '#F6F6F6',
    danger: '#FF4005',
    subjectVisit: '#F6F3F3',
    compensationItem: '#E5E4E4',
    link: '#0173E3',
    success: '#01E398',
    gold: '#d19f44',
}