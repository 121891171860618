import React, { useState } from 'react';
import { StudyDetail_study_users, StudyDetail_study_centrums, RemoveUserFromStudyVariables, RemoveUserFromStudy, StudyDetail, StudyDetailVariables } from '../../graphql/types';
import EditableList from '../../components/EditableList';
import AddUserModal from './AddUserModal';
import { useMutation } from '@apollo/react-hooks';
import { removeUserFromStudyMutation } from '../../graphql/mutations';
import { useParams } from '@reach/router';
import { studyDetailQuery } from '../../graphql/queries';

interface Props {
    users: StudyDetail_study_users[];
    centers: StudyDetail_study_centrums[];
}

const UserPick = ({ users, centers }: Props) => {
    const { studyId } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [updateUserState] = useMutation<RemoveUserFromStudy, RemoveUserFromStudyVariables>(removeUserFromStudyMutation)

    const handleUpdateUserState = (userId: any) => {
        updateUserState({
            variables: {
                userId,
                studyId: Number(studyId),
            },
            update(cache, result) {
                const variables = { studyId: parseInt(studyId, 10) };
                const studyDetailCache = cache.readQuery<StudyDetail, StudyDetailVariables>({ query: studyDetailQuery, variables });                
                if(studyDetailCache){
                    const newData: StudyDetail= {
                    ...studyDetailCache,
                    study: {
                        ...studyDetailCache.study,
                        users: studyDetailCache.study.users.filter(u => u.id !== userId)
                    }
                    };
                    cache.writeQuery({
                        query: studyDetailQuery,
                        variables,
                        data: newData
                    })
                }
            }
        })
    }

    const list = users.map(u => {
        const centrum = centers.find(c => u.centrumId === c.id) 
        return { 
            label: `${u.username}(${centrum ? centrum.code : ''})`, 
            subLabel: u.userStudyCentrum.studyRole.toString(), 
            value: u.id
        }
    })

    return (
        <>
           {users && <EditableList title="Users" onAddClick={() => setIsModalOpen(true)} list={list} itemStyle={{ marginBottom: 8 }} addButtonText="Add User" onItemChange={handleUpdateUserState}/>}
           <AddUserModal isOpen={isModalOpen} onIsOpenChange={setIsModalOpen} studyCenters={centers} users={users}/>
        </>
    )
}

export default UserPick;