import React from 'react';

interface Props {
    value: any;
}

const PriceFormat = ({ value }: Props) => {
    return <>{value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} CZK </>
}

export default PriceFormat;