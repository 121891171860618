import React from 'react';
import TopBar from '../../components/TopBar';
import { Container } from '../../libs/styles';
import styled from '@emotion/styled';
import BarGraph from './BarGraph';
import { useParams } from '@reach/router';
import { studyDetailQuery, centrumDataQuery, } from '../../graphql/queries';
import { StudyDetail, StudyDetailVariables, UserRole, CentrumDataQuery } from '../../graphql/types';
import { useQuery } from '@apollo/react-hooks';
import FlowCharts from './FlowCharts';
import CentersPick from './CentersPick';
import CompensationPick from './CompensationPick';
import UsersPick from './UsersPick';
import StudyInfo from './StudyInfo';
import BudgetChart from './BudgetChart';
import Header from '../../components/Header';
import ContactsPick from './ContactsPick';
import { css } from '@emotion/core';
import StudyBudget from './StudyBudget';
import { useMe } from '../../hooks';
import Payments from '../Payments';


const EditStudy = () => {
    const { studyId, centrumId } = useParams();
    const { me } = useMe()
    const isAdmin = me?.role === UserRole.ADMIN;
    const isClient = me?.role === UserRole.CLIENT_USER;
    const { data } = useQuery<StudyDetail, StudyDetailVariables>(studyDetailQuery, {
        variables: {
            studyId: Number(studyId),
            centrumId: centrumId ? Number(centrumId) : undefined
        },
        skip: studyId == null,
        fetchPolicy: 'cache-and-network'
    });

    const screened = data != null ? (data?.study.entered.ongoing + data?.study.entered.earlyTerminated + data.study.entered.completed) : 0;
    const { data: centrumData } = useQuery<CentrumDataQuery>(centrumDataQuery, {
        variables: {
            centrumId: parseInt(centrumId, 10),
            studyId: parseInt(studyId, 10),
        }
        , skip: !!!centrumId,
    });
    let navigationData = [];
    if(centrumId){
        navigationData.push({ text: `Site ${centrumData?.centrums[0].code}`, path: `/site/${centrumId}` })
    }
    if(studyId){
        navigationData.push({ 
            text: `Study ${data?.study.code ?? ''}`, 
            path: isClient
                ? `/study/${studyId}/view`
                : `${centrumId ? `/site/${centrumId}` : ''}/study/${studyId}` })
    }
    navigationData.push({ text: studyId ? isAdmin ? 'Edit Study' : 'View study' : 'New Study' })

    return (
        <>
            <TopBar />
            <Header data={navigationData} optionProps={{ editStudy: true }}/>
            <Container>
                <Row>
                    <div style={{ width: '50%' }}>
                        <Item>
                            <StudyInfo studyId={studyId} data={data?.study} />
                        </Item>
                        {data && <>
                            <Item>
                                <ContactsPick isAdmin={isAdmin} studyId={data.study.id} contacts={data.study.contacts} clientId={data.study.clientId}/>
                            </Item>
                            {isAdmin &&
                                <>
                                    <Item>
                                        <CentersPick flowCharts={data.study.flowCharts} centers={data.study.centrums} />
                                    </Item>
                                    <Item>
                                        <UsersPick users={data.study.users} centers={data.study.centrums} />
                                    </Item>
                                    <Item>
                                        <CompensationPick studyCompensations={data.study.compensations} />
                                    </Item>
                                </>}
                        </>}
                    </div>
                    <div css={css`width: 50%; margin-left:1em;`}>
                        <Item>
                            <div style={{ height: 70, marginBottom: '36px' }}>
                                <BarGraph title={`Enrolled ${data ? (screened + data?.study.enrolled.screenFailure + data?.study.enrolled.inScreening) : ''}`} total={data != null ? (screened + data?.study.enrolled.screenFailure + data?.study.enrolled.inScreening) : 0} items={[
                                    { value: screened, label: 'Entered', color: '#01E398' },
                                    { value: data?.study.enrolled.screenFailure || 0, label: 'Screen Failure', color: '#FF4005' },
                                    { value: data?.study.enrolled.inScreening || 0, label: 'In Screening', color: '#cfd1d0' }
                                ]} />
                            </div>
                            <div style={{ height: 70 }}>
                                <BarGraph title={`Entered ${data != null ? (data.study.entered.completed + data.study.entered.earlyTerminated + data.study.entered.ongoing) : 0}`} total={data != null ? (data?.study.entered.completed + data?.study.entered.earlyTerminated + data?.study.entered.ongoing) : 0} items={[
                                    { value: data != null ? data.study.entered.completed : 0, label: 'Completed', color: '#01E398' },
                                    { value: data != null ? data.study.entered.earlyTerminated : 0, label: 'Early Terminated', color: '#FF4005' },
                                    { value: data != null ? data.study.entered.ongoing : 0, label: 'Ongoing', color: '#cfd1d0' },
                                    { value: data != null ? data.study.enrolled.inScreening : 0, color: 'white' },
                                    { value: data != null ? data.study.enrolled.screenFailure : 0, color: 'white' }
                                ]} />
                            </div>
                        </Item>
                        {!!data && (isAdmin || isClient) && <Item>
                            {data && <BudgetChart actualBudget={data.study.actualBudget} incomingBudget={data.study.incomingBudget || []}/>}
                            <StudyBudget data={data?.study.incomingBudget} studyId={studyId} />
                        </Item>}
                    </div>
                </Row>
                {data && isAdmin ? <FlowCharts flowCharts={data.study.flowCharts} centrums={data.study.centrums} protocolNumber={data.study.protocolNumber} /> : null}
                {isClient && <Payments onlyList type="all"/>}
            </Container>
        </>
    )
};

export default EditStudy;

const Row = styled.div`
    display: flex;
`;

const Item = styled.div`
    background: white;
    padding: 36px;
    margin-bottom: 16px;
`;