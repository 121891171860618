import React, { useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import styled from '@emotion/styled';
import Button from '../../components/Button';
import { addUserToStudyMutation } from '../../graphql/mutations';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { addUserToStudyVariables, centrumUsers, centrumUsersVariables, StudyRole, StudyDetail_study_centrums, StudyDetail_study_users } from '../../graphql/types';
import { useParams } from '@reach/router';
import { studyDetailQuery, centrumUsersQuery } from '../../graphql/queries';
import SelectBox from '../../components/SelectBox';
import SnackBar from '../../components/SnackBar';

interface Props {
    isOpen: boolean;
    onIsOpenChange: (isOpen: boolean) => void;
    studyCenters: StudyDetail_study_centrums[];
    users?: StudyDetail_study_users[];
}

const AddUserModal = ({ isOpen, onIsOpenChange, studyCenters, users } : Props) => {
    const [centrumId, setCentrumId] = useState("");
    const [studyRole, setStudyRole] = useState("");
    const [userId, setUserId] = useState("");

    const { studyId } = useParams()
    const [addUserToStudy, { error }] = useMutation<any, addUserToStudyVariables>(addUserToStudyMutation);
    const { data: userData } = useQuery<centrumUsers, centrumUsersVariables>(centrumUsersQuery, {
        variables: { 
            centrumId: Number(centrumId)
        },
        fetchPolicy: 'cache-and-network'
    });
    useEffect(() => {
        setUserId('');
    }, [centrumId])

    const handleAddUser = async () => {
        try{
            await addUserToStudy({
                variables: {
                    studyId: parseInt(studyId, 10),
                    centrumId: parseInt(centrumId, 10),
                    studyRole: studyRole as StudyRole,
                    userId: parseInt(userId, 10)
                },
                refetchQueries: [{ query: studyDetailQuery, variables: { studyId: parseInt(studyId, 10) } }]
            });
            onIsOpenChange(false)
            setCentrumId("");
            setStudyRole("");
            setUserId("");
        } catch (e) {}
     }

    const centrumList = studyCenters.map(c => ({ label: c.name, key: c.id, value: c.id }))
    let userList = userData?.centrumUsers.map(c => ({ label: c.username, key: c.id, value: c.id }));
    const studyRoleList = [
        { label: StudyRole.STUDY_COORDINATOR, value: StudyRole.STUDY_COORDINATOR, key: StudyRole.STUDY_COORDINATOR },
        { label: StudyRole.INVESTIGATOR, value: StudyRole.INVESTIGATOR, key: StudyRole.INVESTIGATOR },
        { label: StudyRole.PRINCIPLE_INVESTIGATOR, value: StudyRole.PRINCIPLE_INVESTIGATOR, key: StudyRole.PRINCIPLE_INVESTIGATOR },
        { label: StudyRole.STUDY_NURSE, value: StudyRole.STUDY_NURSE, key: StudyRole.STUDY_NURSE },
    ]
    const isButtonDisabled = centrumId === "" || studyRole === "" || userId === "";

    const studyUsersId = users?.map(u => u.id);
    userList = userList?.filter(u => !studyUsersId?.includes(u.value));

    
    return(
        <div>
            <Modal
            isOpen={isOpen}
            onRequestClose={onIsOpenChange}
            contentLabel="Add Site"
            > 
                <Row>
                    <SelectBox list={centrumList || []} label="Site" minWidth="250px" value={centrumId} onChange={setCentrumId}/>
                    {userList && <SelectBox list={userList} label="Users" minWidth="250px" value={userId} onChange={setUserId} style={{ marginLeft: 16}}/>}
                    <SelectBox list={studyRoleList} label="Study roles" minWidth="250px" value={studyRole} onChange={setStudyRole} style={{ marginLeft: 16}}/>
                </Row>
                <Center>
                    <Button color='none' onClick={() => onIsOpenChange(false)}>Cancel</Button>
                    <Button onClick={handleAddUser} disabled={isButtonDisabled}>Save</Button>
                </Center>
                
            </Modal>
            {error != null && <SnackBar message={error?.message.split(":")[1]}/>}
        </div>
    )
}

export default AddUserModal;

const Row = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
`;
