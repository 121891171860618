import React, { useState } from 'react';
import { RouteComponentProps, useParams, useNavigate } from '@reach/router';
import styled from '@emotion/styled';
import logo from '../icons/logo.png';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { verifyUserVariables, verifyUser, userByTokenVariables, userByToken } from '../graphql/types';
import { vefiryUserMutation } from '../graphql/mutations';
import TextInput from '../components/TextInput';
import Button from '../components/Button';
import SnackBar from '../components/SnackBar';
import { userByTokenQuery } from '../graphql/queries';
import { getGraphqlErrorMessage, passwordRegex } from '../helpers';

const VerifyUser = (_: RouteComponentProps) => {
    const [firstPassword, setFirstPassword] = useState("");
    const [secondPassword, setSecondPassword] = useState("");
    const [localError, setError] = useState<null | string>(null);
    const { token } = useParams();
    const navigate = useNavigate();

    const { data, error, loading } = useQuery<userByToken, userByTokenVariables>(userByTokenQuery, {
        variables: {
            token
        }
    })
    const [veryfy, { error: resetError }] = useMutation<verifyUser, verifyUserVariables>(vefiryUserMutation);

    const handleClick = async() => {
        if(firstPassword !== secondPassword){
            setError("Passwords must be same")
        } else if(!passwordRegex.test(firstPassword)){
            setError("Password has to contain at least 1 uppercase character, 1 lowercase character, 1 number and 1 special character. Length has to be at least 10 characters")
        } else if(data?.userByToken){
            try{
                const response = await veryfy({
                    variables: {
                        password: firstPassword,
                        userId: data.userByToken.id
                    }
                })
                if(response) {
                    navigate("/login", { state: { isAfterVefiry: true }})
                }
            } catch(e){}
        }
    }

    if(loading){
        return <div></div>
    }

    if(error){
        return(
            <Center>
                 <img width={120} alt='clinpas logo' src={logo} />
                 <Title>{getGraphqlErrorMessage(error)}</Title>
            </Center>
        )
    }
    
    const isDisabled = !(firstPassword.length && setSecondPassword.length);
    return(
        <VerifyContainer>
       <Center>
            <img width={120} alt='clinpas logo' src={logo} />
           <div style={{ width: 300}}>
                <Title>Set up password for your account <Bold>{data?.userByToken.username}</Bold></Title>
                <TextInput onChange={setFirstPassword} value={firstPassword} label="Password" style={{ marginTop: 16 }} type="password"/>
                <TextInput onChange={setSecondPassword} value={secondPassword} label="Repeat password" style={{ marginTop: 32 }} type="password"/>
                <Button style={{ marginTop: 16, width: '100%'}} disabled={isDisabled} onClick={handleClick}>Save</Button>
            </div>
            {localError != null && <SnackBar message={localError} resetError={setError}/>}
            {resetError != null && <SnackBar message={getGraphqlErrorMessage(resetError)} />}
       </Center>
       </VerifyContainer>
    )
};

export default VerifyUser;

const Center = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Bold = styled.div`
    font-weight: 600;
`;

const Title = styled.div`
    font-size: 16px;
    margin-top: 32px;
    text-align: center;
`;

const VerifyContainer = styled.div`
width: 100%;
height: 100vh;
background-color: #fafafa;
`;