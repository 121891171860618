interface Credentials {
  username: string;
  password: string;
}

export async function login(credentials: Credentials) {
  const res = await fetch("/api/auth/session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  });
  // This call sets httponly cookie there's nothing we can do here just check http status code, it returns 401 if credentials are wrong
  if (res.status === 401) {
    const data = await res.json();
    throw new Error(data.error);
  }
  if (!res.ok) {
    throw new Error("Server error");
  }
  return true;
}

export async function logout() {
  const res = await fetch("/api/auth/session", {
    method: "DELETE",
  });
  if (!res.ok) {
    throw new Error("logout-error");
  }
  return true;
}
