import React, { useState } from 'react';
import { StudyDetail_study_compensations, UpdateStudyCompensationStateVariables, UpdateStudyCompensationState } from '../../graphql/types';
import EditableList from '../../components/EditableList';
import AddCompensationModal from './AddCompensationModal';
import { useMutation } from '@apollo/react-hooks';
import { updateStudyCompensationStateMutation } from '../../graphql/mutations';
import { studyCompensationsQuery } from '../../graphql/queries';
import { useParams } from '@reach/router';
import ChangeCompensationNameModal from './ChangeCompensationNameModal';

interface Props {
    studyCompensations: StudyDetail_study_compensations[];
}

const CompensationPick = ({ studyCompensations }: Props) => { 
    const { studyId } = useParams()
    const [isModalOpen, setIsModalOpen] = useState(false);   
    const [editingCompensation, setEditingCompensation] = useState<null | number>(null);

    const [updateCompensationState] = useMutation<UpdateStudyCompensationState, UpdateStudyCompensationStateVariables>(updateStudyCompensationStateMutation)

    const handleUpdateCompensationState = (compensationId: any) => {
        const compensationToUpdate = studyCompensations.find(c => c.id === compensationId);
        updateCompensationState({
            variables: {
                compensationId,
                isActive: compensationToUpdate?.deletedAt !== null
            },
            refetchQueries: [{
                query: studyCompensationsQuery,
                variables: {
                    studyId: Number(studyId)
                }
            }]
        })
    }

    const handleChangeCompName = (value: any) => {
        setEditingCompensation(value);
    }

    const compensations = studyCompensations.map(c => ({ 
        label: c.name, 
        value: c.id, 
        subLabel: c.price ? `${c.price} ${c.currency.toUpperCase()}` : null,
        isNotActive: c.deletedAt != null 
    }))

    const updatedCompensation = studyCompensations.find(c => c.id === editingCompensation)
    return (
        <>
           {compensations && 
                <EditableList 
                    title="Compensation & Expenses" 
                    onAddClick={() => setIsModalOpen(true)} 
                    onItemClick={handleChangeCompName}
                    onItemChange={handleUpdateCompensationState}
                    list={compensations} 
                    itemStyle={{ marginBottom: 8 }} 
                    addButtonText="Add Item"
                />
            }
             <AddCompensationModal isOpen={isModalOpen} onIsOpenChange={setIsModalOpen} />
             {updatedCompensation && <ChangeCompensationNameModal 
                isOpen={!!editingCompensation} 
                onIsOpenChange={() => setEditingCompensation(null)} 
                compensationId={updatedCompensation.id}
                compensationName={updatedCompensation.name}
                />
            }
        </>
    )
}

export default CompensationPick;