import React, { useState, useEffect } from 'react';
import TopBar from '../../components/TopBar';
import HeaderComp from '../../components/Header';
import { Container } from '../../libs/styles';
import { RouteComponentProps, useParams } from '@reach/router';
import styled from '@emotion/styled';
import { approvedPayments, approvedPaymentsVariables, synchronizePaymentsVariables, makePayments as MakePaymentsMutationType, makePaymentsVariables, synchronizePayments, selectedPaymentsVariables, selectedPayments, StudyCodeQuery, StudyCodeQueryVariables, UpdatePaymentStatusVariables, UpdatePaymentStatus, PaymentStatus } from '../../graphql/types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { approvedPaymentsQuery, selectedPaymentsQuery, studyCodeQuery } from '../../graphql/queries';
import Button from '../../components/Button';
import { makePaymentsMutation, synchronizePaymentsMutation, updatePaymentStatusMutation } from '../../graphql/mutations';
import PaymentList from './PaymentList';
import { css } from '@emotion/core';
import { useCounter } from '../../hooks';
import { timeout } from '../../helpers';
import * as R from 'ramda';


const BankInfo = (_ :RouteComponentProps) => {
    const { studyId } = useParams();
    const [ids, setIds] = useState<number[]>([]);
    const { data, refetch } = useQuery<approvedPayments, approvedPaymentsVariables>(approvedPaymentsQuery, { 
        variables: { 
            studyId: studyId ? parseInt(studyId, 10) : undefined 
        },
        fetchPolicy: 'cache-and-network'
    });
    
    const {data: studyCodeData} = useQuery<StudyCodeQuery, StudyCodeQueryVariables>(studyCodeQuery, {variables: {id: parseInt(studyId, 10)}, skip: studyId === 'all' });
    const [makePayments, { data: makePaymentsData }] = useMutation<MakePaymentsMutationType, makePaymentsVariables>(makePaymentsMutation);
    const [synchronize, { data: synchronizeData }] = useMutation<synchronizePayments, synchronizePaymentsVariables>(synchronizePaymentsMutation);
    const [updatePaymentStatus, { data: updatePaymentStatusData }] = useMutation<UpdatePaymentStatus, UpdatePaymentStatusVariables>(updatePaymentStatusMutation);
    const { data: newData, refetch: selectedRefetch } = useQuery<selectedPayments, selectedPaymentsVariables>(selectedPaymentsQuery, {
        variables: {
            ids
        },
        skip: ids.length < 1
    })

    const [seconds, setCounter] = useCounter();

    const handleImport = () => {
        setCounter(30);
        makePayments({
            variables: {
                studyId: Number(studyId)
            }
        });
    };

    const handleSynchronize = () => {
        setCounter(30);
        synchronize({
            variables: {
                studyId: Number(studyId)
            }
        });
    };

    const handlePaid = (paymentId: number) => {
        if (window.confirm('Do you really want set this payment as paid? ')) {
            updatePaymentStatus({
                variables: {
                    status: PaymentStatus.PAID,
                    studyId: Number(studyId),
                    paymentId
                }
            })
        }
    }

    const handleCancel = (paymentId: number) => {
        if (window.confirm('Do you really want to cancel this payment?')) {
            updatePaymentStatus({
                variables: {
                    status: PaymentStatus.CANCELED,
                    studyId: Number(studyId),
                    paymentId
                }
            })
        }
    }

    useEffect(() => {
        if(updatePaymentStatusData?.updatePaymentStatus){
            setIds([updatePaymentStatusData.updatePaymentStatus.id])
            refetch({
                studyId: studyId ? parseInt(studyId, 10) : undefined 
            });
        }
    }, [updatePaymentStatusData, studyId, refetch])

    useEffect(() => {
        if(newData){
            setCounter(30);
        }
    }, [newData, setCounter])

    useEffect(() => {
        if(makePaymentsData?.makePayments){
            const ids = makePaymentsData.makePayments.payments.map(p => p.id);
            refetch({
                studyId: studyId ? parseInt(studyId, 10) : undefined 
            });
            setIds(ids);
            if(makePaymentsData.makePayments.transactionId){
                const makePaymentsAgain = async () => {
                    await timeout(30000);
                    selectedRefetch({
                        ids
                    })
                }
                makePaymentsAgain();
            }
        }
    }, [makePaymentsData, refetch, studyId, selectedRefetch])

    useEffect(() => {
        if(synchronizeData?.synchronizePayments){
            const synchronizedIds = synchronizeData?.synchronizePayments.map(p => p.id);
            refetch({
                studyId: studyId ? parseInt(studyId, 10) : undefined 
            })
            if(R.equals(synchronizedIds, ids)){
                selectedRefetch({
                    ids
                })
            } else {
                setIds(synchronizedIds)
            }
        }
    }, [synchronizeData, refetch, studyId, selectedRefetch, ids]);

    const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

    const navigationData = [
        { text: 'Fio Bank', path: '/bank_info/all/'},
        studyId !== 'all' ? { text: `Study ${studyCodeData?.study.code}`, path: `/bank_info/${studyId}` } : null,
        {text: 'Bank Detail'},
    ];

    let toPay = 0;
    data?.approvedPayments.forEach((p => {
        toPay += p.price;
    }))

    return(
        <>
            <TopBar/>
            <HeaderComp data={navigationData}/>
            <Container>
                <Row css={css`justify-content: space-between; background-color: white; margin-bottom: 16px;`}>
                    <Title>FIO Bank</Title>
                    <Row>
                    {studyId === 'all' &&  <HeaderBox>
                    Select Study To Make An Action
                    </HeaderBox>}
                    {isProduction && studyId !== 'all' && <HeaderBox>
                        <Button disabled={seconds > 0} onClick={handleSynchronize}>{`Pair payments ${seconds > 0 ? `(${seconds})` : ''}`}</Button>
                    </HeaderBox>}
                    {studyId !== 'all' && <HeaderBox>
                         <Button disabled={seconds > 0} onClick={handleImport}>{isProduction ? `Generate payments ${seconds > 0 ? `(${seconds})` : ''} `: `Make Payments ${seconds > 0 ? `(${seconds})` : ''} `}</Button>
                    </HeaderBox>}
                    </Row>
                    
                </Row>
                <PaymentList title={`Results (${newData?.selectedPayments.length || 0})`} payments={newData?.selectedPayments} showSentAt loading={seconds}/>
                <PaymentList title={`Unpaired Payments (${data?.approvedPayments.length || 0})`} subTitle={`To pay - ${toPay} CZK`} payments={data?.approvedPayments} onPay={handlePaid} onCancel={handleCancel} showSentAt/>
            </Container>
        </>
    )
}

export default BankInfo;

const Title = styled.h3`
    font-weight: 500;
    margin-left: 32px;
`;

const HeaderBox = styled.div`
    padding: 2em 2em;
    display: flex;
    flex-direction: column;
    font-weight: 500;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
`