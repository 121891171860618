import React from 'react';
import { RouteComponentProps, Redirect, useLocation } from '@reach/router';
import { UserRole, me as MeQueryType } from '../graphql/types';
import { useQuery } from '@apollo/react-hooks';
import { meQuery } from '../graphql/queries';
import { parse } from 'query-string';

interface Props extends RouteComponentProps {
  onlyAdmin?: boolean;
  roles?: UserRole[];
  Component: (props: any) => JSX.Element | null;
}


const ProtectedRoute = ({ Component, roles = [], ...props }: Props) => {
  const location = useLocation();
  const { redirect } = parse(location.search)
  const { data, loading } = useQuery<MeQueryType>(meQuery, {
    fetchPolicy: 'cache-first'
  });

  const forClient = roles.includes(UserRole.CLIENT_USER);
  const forUser = roles.includes(UserRole.CENTER_USER)

  const me = data && data.me;

  if (loading) {
    return <div />
  }

  if (!me) {
    return <Redirect from="/" to="/login" noThrow state={{ redirectUrl: redirect ? location.pathname : undefined }}/>;
  }

  if (!forUser && me.role === UserRole.CENTER_USER) {
    return <Redirect to="/notFound" noThrow />;
  }

  if(!forClient && me.role === UserRole.CLIENT_USER){
    return <Redirect to="/notFound" noThrow />;
  }

  if (me.role === UserRole.CENTER_USER && location.pathname === "/") {
    return <Redirect to={`/site/${me.centrumId}`} from="/" />;
  }

  return <Component me={me} {...props} />
}

export default ProtectedRoute;
