import moment from "moment";

export const emailRegex = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-.,=_(){};|:/\\`~^[\]+ ])(?=.{10,})/g;

export const getGraphqlErrorMessage = (error: any) => error?.message.split(":")[1];

export const dateTemplate = 'DD-MM-YYYY';

export const getFormatedDate = (date: string | null) => {
        if(date){
                const result = moment(Number(date)).format(dateTemplate)
                return result;
        }
        return "";
}

export const timeout = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
      }