import React, { useState, useEffect } from 'react';
import TopBar from '../../components/TopBar';
import { Container } from '../../libs/styles';
import { RouteComponentProps, useParams, useNavigate } from '@reach/router';
import styled from '@emotion/styled';
import TextInput from '../../components/TextInput';
import EditableList from '../../components/EditableList';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { detailCentrumsQuery } from '../../graphql/queries';
import { detailCentrums, detailCentrumsVariables } from '../../graphql/types';
import AddUserModal, { User } from './AddUserModal';
import Button from '../../components/Button';
import { updateCenterMutation } from '../../graphql/mutations';
import * as R from 'ramda'
import InfoBar from '../../components/InfoBar';
import { css } from '@emotion/core';
import Header from '../../components/Header';

interface UserPayload {
    user: User;
    id: number;
}

const UpdateCentrums = (_: RouteComponentProps) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const { centrumId } = useParams();
    const [updateCenter, { loading: updateCenterLoading, data: updateCenterData }] = useMutation<User[]>(updateCenterMutation);
    const [info, setInfo] = useState<string | null>(null);

    const { data, loading } = useQuery<detailCentrums, detailCentrumsVariables>(detailCentrumsQuery, {
        variables: {
            centrumId: centrumId ? parseInt(centrumId, 10) : 0
        },
        skip: centrumId == null
    });

    const [localUsersList, setLocalUsersList] = useState<UserPayload[]>([])

    useEffect(() => {
        if (!loading && (name !== (data?.centrums[0].name || "") || (data != null && (!R.equals(data?.centrums[0].users.map(u => u.username), localUsersList.map(localUser => localUser.user.username)))))) {
            setInfo('It looks like you have been editing something. If you leave before saving, your changes will be lost.');
        }
        else {
            setInfo(null);
        }
    }, [localUsersList, data, name, loading])

    const handleAddUser = (user: User) => {
        if (localUsersList != null) {
            const userId = (localUsersList != null ? ((localUsersList.length) + 1) : 0);
            setLocalUsersList(prevState => ([...prevState, { user: user, id: userId }]));
        }
    }

    window.addEventListener('beforeunload', (e) => {
        e.preventDefault();
        if (info) {
            e.returnValue = 'You have unsaved changes';
        }

    });


    const handleRemoveUser = (value: number | string | boolean) => {
        setLocalUsersList(prevState => prevState.filter(u => u.id !== value));
    }

    const handleSaveCenter = async () => {
        const users = localUsersList.map(user => user.user)
        await updateCenter({
            variables: {
                centerInput: {
                    name,
                    users: users,
                    centerId: centrumId ? parseInt(centrumId, 10) : undefined
                }
            },
            refetchQueries: [
                { query: detailCentrumsQuery, variables: {
                    centrumId: centrumId ? parseInt(centrumId, 10) : 0
                }}
            ]
        })
        navigate(-1);
    }

    const centrum = data?.centrums.length ? data.centrums[0] : null;

    const getPaymentStatus = (bankAcount: string | null) => {
        if (bankAcount == null) {
            return 'Postal order';
        }
        else {
            return 'Bank transfer';
        }
    }
    const studyList = centrum && centrum.studies.map(c => ({ label: `(${c.siteNumbers}) ${c.code}`, value: c.id }))
    const subjectList = centrum && centrum.subjects.map(c => ({ label: `${c.code} - ${getPaymentStatus(c.bankAccount)}`, value: c.id }))

    useEffect(() => {
        if (data && data.centrums[0] && data.centrums[0].name && centrumId != null) {
            setName(data.centrums[0].name);
        }
    }, [data, centrumId])

    useEffect(() => {
        if (data != null) {
            setLocalUsersList(data?.centrums[0]?.users.map((user, index) =>
                ({
                    user: {
                        username: user.username,
                        email: user.contact.email,
                        firstname: user.contact.firstName,
                        lastname: user.contact.lastName,
                        phone: user.contact.phone
                    },
                    id: index + 1
                })
            ))
        }
    }, [data])
    const navigationData = [
        { text: 'Sites', path: '/sites' },
        centrumId ? { text: `Site ${name}` } : null,
        { text: centrumId ? 'Edit Site' : 'New Site' },
    ];

    // {text: 'Sites', path: '/sites'}, {text: centrumId ? `Edit ${data?.centrums[0].name}` : 'New Site'}]

    return (
        <>
            <TopBar unsaved={!!info} sites />
            <Header warning={!!info} hidePlusButton data={navigationData} />
            <Container css={css`padding-bottom: 3em;`}>
                <Item>
                    <TextInput label="Site name" value={name} onChange={setName} style={{ width: '100%' }} />
                </Item>
                <Item>
                    <EditableList
                        title="Users"
                        list={localUsersList.map(user => { return { label: user.user.username, value: user.id } }).reverse()}
                        onAddClick={() => setModalOpen(true)}
                        addButtonText="Add User"
                        onRemove={handleRemoveUser}
                        itemStyle={{ marginBottom: 8, width: '100%' }}
                    />
                </Item>
                {studyList && <Item>
                    <EditableList title="Studies" list={studyList} itemStyle={{ marginBottom: 8, width: '100%' }} />
                </Item>}
                {subjectList && <Item>
                    <EditableList title="Subjects" list={subjectList} itemStyle={{ marginBottom: 8, width: '100%' }} />
                </Item>}
                <Button onClick={handleSaveCenter} disabled={name === "" || updateCenterLoading || !!updateCenterData}>Save</Button>

                <AddUserModal isOpen={isModalOpen} onIsOpenChange={setModalOpen} onSave={handleAddUser} users={localUsersList.map(i => i.user)} />
            </Container>
            {!loading && info && <InfoBar buttonText='save' buttonClick={handleSaveCenter} type='primary' message={info} disabled={updateCenterLoading || !!updateCenterData}/>}
        </>
    )
}

export default UpdateCentrums;

const Item = styled.div`
    background: white;
    height: 100%;
    padding: 36px;
    margin-bottom: 16px;
    max-width: 417px;
    display: flex;
`;
