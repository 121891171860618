import React, { useMemo, useRef, useEffect, useCallback, useState } from 'react';
import { useNavigate, useParams, useLocation } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { studySubjectQuery, allSubjectsQuery, centrumDataQuery } from '../../graphql/queries';
import { Container } from '../../libs/styles';
import TopBar from '../../components/TopBar';
import StudyHeader from '../StudyDetail/StudyHeader';
import styled from '@emotion/styled';
import Button from '../../components/Button';
import SelectBox from '../../components/SelectBox';
import { css } from '@emotion/core';
import { StudySubject, StudySubjectVariables, subjects as SubjectsQueryType, subjectsVariables as subjectVariablesType, CompensationStatus, CentrumDataQuery } from '../../graphql/types';
import ScrollContainer from 'react-indiana-drag-scroll';
import { usePrevious } from '../../hooks';
import arrow from '../../icons/arrow.svg';
import { colors } from '../../libs/colors';
import { isIE, isEdge } from 'react-device-detect';
import AddUnscheduledVisitModal from './AddUnscheduledVisitModal';
import Header from '../../components/Header';
import SubjectDialogs from './SubjectDialogs';
import SubjectVisit from './SubjectVisit';


const SubjectDetail = () => {
    const params = useParams();
    const location = useLocation();
    const studyId = params.studyId != null ? parseInt(params.studyId, 10) : 1;
    const subjectId = params.subjectId != null ? parseInt(params.subjectId, 10) : 1;
    const sliderRef = useRef<ScrollContainer>(null);
    const [openAddUnscheduledModal, setOpenAddUnscheduledModal] = useState(false);

    const { data: centrumData } = useQuery<CentrumDataQuery>(centrumDataQuery, {
        variables: {
            centrumId: Number(params.centrumId),
            studyId: Number(params.studyId)
        }
        , skip: !!!params.centrumId,
    });

    const inputRef = useRef<HTMLInputElement | null>(null);

    const variables = useMemo(() => ({
        studyId,
        subjectId
    }), [studyId, subjectId])

    const { data, loading } = useQuery<StudySubject, StudySubjectVariables>(studySubjectQuery, {
        variables,
        fetchPolicy: 'cache-and-network'
    });

    const subjectsVariables = {
        studyId,
        centrumId: params.centrumId ? parseInt(params.centrumId, 10) : null
    }

    const { data: subjects, refetch: refetchSubjects } = useQuery<SubjectsQueryType, subjectVariablesType>(allSubjectsQuery, {
        variables: subjectsVariables
    });

    const navigate = useNavigate();

    const handleBack = () => {
        if (params.centrumId) {
            navigate(`/site/${params.centrumId}/study/${studyId}`)
        } else {
            navigate(`/study/${studyId}`);
        }
    }

    const handleChooseSubject = (value: string) => {
        const urlArr = location.pathname.split("/");
        urlArr[urlArr.length - 1] = value;
        navigate(urlArr.join("/"))
    }

    const handleFocusVisit = (visitIndex: number) => {
        makeScroll((visitIndex - 1) * 342);
    }

    const handleSlideLeft = () => {
        makeScroll(0);
    }

    const handleSlideRight = () => {
        makeScroll(subjectVisits ? subjectVisits.length * 342 : 0)
    }

    const handleScrollClick = useCallback(event => {
        if (event.target.tagName === "INPUT") {
            inputRef.current = event.target;
            event.target.focus();
        } else {
            if (inputRef.current) {
                inputRef.current.blur();
                inputRef.current = null;
            }
        }
    }, []);

    const makeScroll = (leftIndex: number) => {
        const scrollElement = sliderRef.current?.getElement();
        if (scrollElement) {
            if (scrollElement.scrollTo != null) {
                scrollElement && scrollElement.scrollTo({ left: leftIndex, top: 0, behavior: 'smooth' });
            }
            else {
                setTimeout(() => {
                    scrollElement.scrollTop = 0;
                    scrollElement.scrollLeft = leftIndex;
                }, 500);
            }
        }
    }

    const subject = data?.study.subjects.length ? data?.study.subjects[0] : null;
    const study = data?.study;
    const subjectVisits = subject?.subjectVisits;
    const flowChartSubjectVisits = subjectVisits?.filter(v => v.visit.flowChartId != null);
    const lastSubjectVisit = flowChartSubjectVisits && flowChartSubjectVisits.length ? flowChartSubjectVisits[flowChartSubjectVisits.length - 1] : null;
    const cycleEnd = lastSubjectVisit?.visit.flowChart?.cycleEndsAt;
    const cycleStart = lastSubjectVisit?.visit.flowChart?.cycleStartsAt;
    const lastVisitOrderNumber = lastSubjectVisit?.visit.orderNumber;
    const visitsWithDate = subjectVisits?.filter(v => v.date != null);
    const lastDate = visitsWithDate && visitsWithDate[visitsWithDate.length - 1];
    const isEntryDialogVissible = subject?.status === "IN_SCREENING" && subjectVisits && subjectVisits.length > 0 && subjectVisits[subjectVisits.length - 1].date != null;
    const isCycleDialogVissible = cycleEnd === lastVisitOrderNumber && lastSubjectVisit?.date != null;
    const showEarlyTerminated = subject?.status === "ONGOING";
    const selectBoxItems = subjects?.subjects.map(s => ({ label: s.code, key: s.id.toString(), value: s.id }));

    const flowChartHasAllowedExtenses = subject?.subjectVisits.find(visit => visit.visit.flowChartId != null)?.visit.flowChart?.allowExpenses;

    const unsolvedCompensations = visitsWithDate?.find(v => v.subjectVisitCompensations.length && v.subjectVisitCompensations.length && v.subjectVisitCompensations.filter(c => c.status === CompensationStatus.PLANNED).length > 0);
    let lastUnlockedIndex = subjectVisits && subjectVisits.map(s => s.id).indexOf(unsolvedCompensations
        ? unsolvedCompensations.id
        : (lastDate?.id || 1)
    );
    if (unsolvedCompensations == null) {
        lastUnlockedIndex = lastUnlockedIndex ? lastUnlockedIndex + 1 : 1;
    }
    const isOneCycleFlow = cycleEnd === lastVisitOrderNumber && cycleStart === lastVisitOrderNumber;
    const isUnsupportedBrowser = isIE || isEdge;
    const prevSubjectVisitLength = usePrevious(subjectVisits && subjectVisits.length);

    useEffect(() => {
        if (subjectVisits && subjectVisits.length !== prevSubjectVisitLength) {
            subjectVisits.find((visit, index) => {
                if (visit.date != null) {
                    const plannedCompensation = visit.subjectVisitCompensations.find(c => c.status === CompensationStatus.PLANNED);
                    if (plannedCompensation) {
                        makeScroll(subjectVisits ? (index - 1) * 342 : 0);
                        return true;
                    }
                }
                else if (visit.date === null) {
                    makeScroll(subjectVisits ? (index - 1) * 342 : 0)
                    return true;
                }
                return false;
            })
        }
    }, [subjectVisits, prevSubjectVisitLength])

    let navigationData = [];
    if(params.centrumId){
        navigationData.push({ text: `Site ${centrumData?.centrums[0].code}`, path: `/site/${params.centrumId}` });
    }
    navigationData = [ 
        ...navigationData, 
        { text: `Study ${data?.study.code ?? ''}`, path: `/${params.centrumId ? `site/${params.centrumId}/` : '' }study/${params.studyId}`},
        { text: `Subject ${subject?.code}` },
        { text: 'Subject Detail' }
    ];

    return (
        <>
            <TopBar />
                <Header 
                data={navigationData}
                    optionProps={{ earlyTerminated: showEarlyTerminated, subjectDetail: true }} 
                />
            <Container>
                <StudyHeader clientName={study?.client.name} code={study?.code} protocolNumber={study?.protocolNumber} eudraCtNumber={study?.eudraCtNumber} sponsor={study?.sponsor}/>
                <SubjectNavigationBox>
                    <Flex css={css`justify-content: space-between; width: 100%;`}>
                        <div css={css`display: flex;`}>
                            <div css={css`margin-right: 16px;`}>
                                {selectBoxItems && <SelectBox list={selectBoxItems} value={subjectId} label="Subject" onChange={handleChooseSubject} onOpen={() => refetchSubjects(subjectsVariables)}/>}
                            </div>
                            <Button onClick={handleBack}>See All Subjects</Button>
                        </div>
                        <div css={css`display: flex; align-items: center; margin-right: 2em;`}>
                            <StatusText>{data?.study.subjects[0].status}</StatusText>
                        </div>       
                    </Flex>
                </SubjectNavigationBox>
                <SubjectDialogs isEntryDialogVissible={!!isEntryDialogVissible} isCycleDialogVissible={isCycleDialogVissible} isOneCycleFlow={isOneCycleFlow} onFocusVisit={handleFocusVisit} subjectVisits={subjectVisits}/>
                <ScrollRoot>
                    <ScrollContainer ref={sliderRef} style={scrollStyle} hideScrollbars={false} vertical={false} className="customScroll" onClick={handleScrollClick}>
                        <Inner>
                            {!loading && subjectVisits && subjectVisits.map((subjectVisit, index) => <SubjectVisit lastVisit={unsolvedCompensations ? unsolvedCompensations.orderNumber : lastDate?.orderNumber ? lastDate?.orderNumber + 1 : 1} key={`visit-${subjectVisit.id}-${subjectId}`} isPostal={subject?.bankAccount == null} number={index + 1} allowedExtenses={flowChartHasAllowedExtenses} data={subjectVisit} isLocked={index > (lastUnlockedIndex || 0)} />)}
                        </Inner>
                    </ScrollContainer>
                    <WhiteLine />
                    <ArrowButton isUnsupportedBrowser={isUnsupportedBrowser} left onClick={handleSlideLeft}>
                        <img alt='arrow' src={arrow} />
                    </ArrowButton>
                    <ArrowButton isUnsupportedBrowser={isUnsupportedBrowser} onClick={handleSlideRight}>
                        <img alt='arrow' src={arrow} />
                    </ArrowButton>
                    {!isUnsupportedBrowser && <Line />}
                </ScrollRoot>
                <AddVisitButtonBox>
                    <Button onClick={() => setOpenAddUnscheduledModal(true)}>
                        Add Unscheduled Visit
                     </Button>
                </AddVisitButtonBox>
                {subjectVisits && <AddUnscheduledVisitModal flowChartId={subjectVisits[0].visit.flowChartId} orderNumbers={subjectVisits?.map(subjectVisit => subjectVisit.orderNumber)} isOpen={openAddUnscheduledModal} studyId={studyId} subjectId={subjectId} setIsOpen={setOpenAddUnscheduledModal} />}
            </Container>

        </>
    )
}

export default SubjectDetail;

const WhiteLine = styled.div`
    background: white;
    width: 100%;
    height: 36px;
    position: absolute;
`

const ScrollRoot = styled.div`
    position: relative;
`;

const ArrowButton = styled.button<{ isUnsupportedBrowser: boolean, left?: boolean }>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.left ? `
    left: ${props.isUnsupportedBrowser ? 0 : '16px'};
    transform: rotate(180deg);
    ` : `
    right: ${props.isUnsupportedBrowser ? 0 : '16px'};
    ` }
    bottom: -10px;
    width: 40px;
    height: 40px;
    border: none;
    margin: 0;
    padding: 0;
    border-radius: 2px;
    outline: none;
    background-color: ${colors.primary};
`;

const Line = styled.div`
    height: 1px;
    position: absolute;
    left: 72px;
    right: 72px;
    bottom: 10px;
    background: #d5a63f;
`

const Inner = styled.div`
    padding: 16px 8px 16px 8px;
    display: flex;
    align-items: center;
`;

const Flex = styled.div`
    display: flex;
`;

const SubjectNavigationBox = styled.div`
display: flex;
justify-content: space-between;
padding: 1em;
background-color: white;
`;

const scrollStyle = {
    marginTop: '1em',
    backgroundColor: 'white',
    borderRadius: '2px',
    display: 'flex',
    overflowX: "scroll" as "scroll",
    overflowY: "hidden" as "hidden",
    whiteSpace: 'nowrap' as "nowrap",
    position: "relative" as "relative"
}

const AddVisitButtonBox = styled.div`
display: flex; 
padding: 1em 1em;
margin-top: 32px;
justify-content: flex-end;
`;


const StatusText = styled.span`
font-weight: 500;
    font-size: 16px;
    margin-left: 0.5em;
`;
